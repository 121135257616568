import Layer from 'views/layers/Layer';
import ImageManager from 'managers/ImageManager';
import PictureBox from 'views/canvasBoxes/PictureBox';
import MouseManager from 'managers/MouseManager';

class GameLayer extends Layer {
  constructor(showMenuCallback, rightClickHandler, leftClickHandler, gameObjectDataArray) {
    super();
    this._gameObjects = [];
    this._showMenuCallback = showMenuCallback;
    this._rightClickHandler = rightClickHandler;
    this._leftClickHandler = leftClickHandler;
    this._gameObjects = this._makeGameObjects(gameObjectDataArray);
    this._makeBlackScreen();
    this.hide();
  }
  _makeGameObjects(gameObjectDataArray) {
    let gameObjects = [];
    gameObjectDataArray.forEach((objectData) => {
      let gObj = new PictureBox();
      this._layer.addChild(gObj);
      gameObjects.push(gObj);
      objectData.setPictureBox(gObj);
    });
    return gameObjects;
  }
  _makeBlackScreen() {
    let blackScreenStyle = {
      width: '100%',
      height: '100%',
      zIndex: '100',
      backgroundColor: 'black',
      position: 'absolute',
    };
    this._blackScreen = document.createElement('div');
    for (let prop in blackScreenStyle) {
      this._blackScreen.style[prop] = blackScreenStyle[prop];
    }
  }
  hide() {
    this._layer.getElement().appendChild(this._blackScreen);
  }
  unhide() {
    this._blackScreen.parentNode.removeChild(this._blackScreen);
  }
  handleMouseMove(data) {
    if (data.position.y < 15) {
      this._showMenuCallback();
    }
  }
  destroy() {
    this._gameObjects.forEach((gObj) => {
      gObj.destroy();
    });
    this._removeLayerDiv();
  }
}

export default GameLayer;

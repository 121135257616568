
let PolygonManager = {
  pointIsInPolygon: function(point, polygonPoints) {
    let windingNumber = 0;
    let lastPoint = polygonPoints[polygonPoints.length - 1];
    for (let i = 0; i < polygonPoints.length; i++) {
      let pt = polygonPoints[i];
      windingNumber += this._windingNumberChange(lastPoint.x - point.x, lastPoint.y - point.y, pt.x - point.x, pt.y - point.y);
      lastPoint = pt;
    }
    return windingNumber != 0;
  },
  _windingNumberChange: function(x1, y1, x2, y2) {
    if ((x2 >= 0 && x1 >= 0 )|| (x2 < 0 && x1 < 0)) {
      return 0;
    }
    let m = (y2 - y1) / (x2 - x1);
    if ((y1 - x1 * m) <= 0) {
      return 0;
    }
    return x2 > x1 ? 1 : -1;
  },

};

export default PolygonManager;

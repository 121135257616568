import Style from 'style/Style';
import colors from 'style/colors';


export default {
  labelText: {
    fontName: '300',
    padding: 0,
    centered: true,
    color: colors.WHITE,
  },
  labelBox: {
    backgroundColor: Style.makeBackgroundColor(colors.BLACK),
    padding: 2,
    borders: [
      Style.makeBoxBorder(Style.BOTTOM, 1, colors.BLACK),
    ],
  },
  bottomBox: {
    padding: 5,
  },
  mainColumn: {
    space: 0
  },
  outerBox: {
    backgroundColor: Style.makeBackgroundColor(colors.WHITE),
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
    ],
    shadow: Style.makeBoxShadow([0, 0, 0, 255], 1, 1),
  },
}

export default {
  src: '/images/icons/icons.png',
  spriteInfo: {
    blank: {
      width: 20,
      height: 20,
      x: -20,
      y: 0,
    },
    ok: {
      width: 20,
      height: 20,
      x: 0,
      y: 0,
    },
    walk: {
      width: 20,
      height: 20,
      x: 20,
      y: 0,
    },
    hand: {
      width: 20,
      height: 20,
      x: 40,
      y: 0,
    },
    eye: {
      width: 20,
      height: 20,
      x: 60,
      y: 0,
    },
    talk: {
      width: 20,
      height: 20,
      x: 80,
      y: 0,
    },
    inventory: {
      width: 20,
      height: 20,
      x: 100,
      y: 0,
    },
    settings: {
      width: 20,
      height: 20,
      x: 120,
      y: 0,
    },
    info: {
      width: 20,
      height: 20,
      x: 140,
      y: 0,
    },
    pointer: {
      width: 20,
      height: 20,
      x: 160,
      y: 0,
    },
  }
}

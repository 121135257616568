import LayoutManager from 'managers/LayoutManager';
import eventMixin from 'mixins/eventMixin';
import Destroyable from 'classes/Destroyable';

class Canvas extends Destroyable {
  constructor(width, height, imgElement) {
    super();

    eventMixin(this);

    this.width = 0;
    this.height = 0;
    this.x = 0;
    this.y = 0;

    this.wrapper = document.createElement('div');
    this.wrapper.style.position = 'absolute';
    this.wrapper.style.pointerEvents = 'none';
    this.wrapper.style.userSelect = 'none';
    this.wrapper.style.webkitUserSelect = 'none';
    this.wrapper.style.mozUserSelect = 'none';
    this.setPosition(this.x, this.y);

    this.canvas = document.createElement('canvas');
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.canvas.style.pointerEvents = 'none';
    this.canvas.style.position = 'absolute';
    this.canvas.style.userSelect = 'none';
    this.canvas.style.webkitUserSelect = 'none';
    this.canvas.style.mozUserSelect = 'none';
    this.canvas.style.imageRendering = 'pixelated';
    this.canvas.style.imageRendering = 'crisp-edges';
    this.canvas.style.imageRendering = "-moz-crisp-edges";

    this.setDimensions(width, height);

    this.ctx = this.canvas.getContext('2d');
    this.wrapper.appendChild(this.canvas);

    if (imgElement) {
      this.ctx.drawImage(imgElement, 0, 0, width, height);
      this.imageData = this.ctx.getImageData(0, 0, width, height);
      this.commitData();
    }
  }
  // this draws a color to a canvas's imagedata
  drawPoint(x, y, color, offsetX = 0, offsetY = 0) {
    let data = this.imageData.data;
    x += offsetX;
    y += offsetY;
    if (x > this.imageData.width - 1 || x < 0 || y > this.imageData.height - 1 || y < 0) {
      return;
    }
    let start = y * this.imageData.width * 4 + x * 4;
    data[start++] = color[0];
    data[start++] = color[1];
    data[start++] = color[2];
    data[start++] = color[3];
  }
  // this is for getting colors after it has been drawn
  getCommittedColorAtPoint(x, y, offsetX, offsetY) {
    return this._getColorAtPoint(this.currentData.data, x, y, offsetX, offsetY);
  }
  // this is for getting color while still drawing
  getWorkingColorAtPoint(x, y, offsetX, offsetY) {
    return this._getColorAtPoint(this.imageData.data, x, y, offsetX, offsetY);
  }
  _getColorAtPoint(data, x, y, offsetX = 0, offsetY = 0) {
    x += offsetX;
    y += offsetY;
    if (x > this.imageData.width -1 || x < 0 || y > this.imageData.height - 1 || y < 0) {
      return null;
    }
    let color = [0, 0, 0, 0];
    let start = y * this.imageData.width * 4 + x * 4;
    color[0] = data[start++];
    color[1] = data[start++];
    color[2] = data[start++];
    color[3] = data[start++];
    return color;
  }
  getAdjustedColorAtPoint(x, y, adjustment = 12, offsetX = 0, offsetY = 0) {
    let data = this.imageData.data;
    x += offsetX;
    y += offsetY;
    if (x > this.imageData.width -1 || x < 0 || y > this.imageData.height - 1 || y < 0) {
      return null;
    }
    let color = [0, 0, 0, 0];
    let start = y * this.imageData.width * 4 + x * 4;
    color[0] = Math.floor(data[start++] / adjustment) * adjustment;
    color[1] = Math.floor(data[start++] / adjustment) * adjustment;
    color[2] = Math.floor(data[start++] / adjustment) * adjustment;
    color[3] = Math.floor(data[start++] / adjustment) * adjustment;
    return color;
  }
  commitData() {
    this.ctx.putImageData(this.imageData, 0, 0);
    this.currentData = this.imageData;
    this.imageData = new ImageData(this.width, this.height);
  }
  getElement() {
    return this.wrapper;
  }
  getHTMLCanvas() {
    return this.canvas;
  }
  getDimensions() {
    return { w: this.canvas.width, h: this.canvas.height };
  }
  getPosition() {
    return { x: this.x, y: this.y };
  }
  getImageData() {
    return this.ctx.getImageData(0, 0, this.width, this.height);
  }
  getCommittedImageData() {
    return this.currentData;
  }
  copyCommittedDataToWorkingData() {
    this.imageData = this.ctx.getImageData(0, 0, this.width, this.height);
  }
  setDimensions(width, height) {
    let magnification = LayoutManager.getMagnification();
    if (width != this.width || height != this.height) {
      this.width = width;
      this.height = height;
      this.canvas.width = width;
      this.canvas.height = height;
      this.imageData = new ImageData(width, height);
    }
    this.wrapper.style.width = width * magnification + 'px';
    this.wrapper.style.height = height * magnification + 'px';
  }
  setPosition(x, y) {
    let magnification = LayoutManager.getMagnification();
    this.x = x;
    this.y = y;
    this.wrapper.style.left = x * magnification + 'px';
    this.wrapper.style.top = y * magnification + 'px';
  }
  setZIndex(zIndex) {
    this.wrapper.style.zIndex = zIndex;
  }
  addChild(childCanvas) {
    this.wrapper.appendChild(childCanvas.getElement());
  }
  addListener(type, callback) {
    this.wrapper.addEventListener(type, callback);
  }
  setClickable(bool) {
    this.wrapper.style.pointerEvents = bool ? 'all' : 'none';
  }
  eventHandler(detail) {
    if (detail.type == LayoutManager.MAGNIFY) {
      this.setDimensions(this.width, this.height);
      this.setPosition(this.x, this.y);
    }
  }
  destroy() {
    this.removeEventListener();
    if (this.wrapper.parentElement) {
      this.wrapper.parentElement.removeChild(this.wrapper);
    }
    this.wrapper.removeChild(this.canvas);
    this.canvas = null;
    this.wrapper = null;
    this.imageData = null;
  }
}

export default Canvas;

export default {
  src: '/images/screens/rockroom/fan.png',
  spriteInfo: {
    still: {
      width: 58,
      height: 29,
      x: 0,
      y: 0,
    },
  }
}

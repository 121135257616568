import ImageManager from 'managers/ImageManager';
import Layer from 'views/layers/Layer';
import Box from 'views/canvasBoxes/Box';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import Column from 'views/compositeBoxes/Column';
import Row from 'views/compositeBoxes/Row';
import Button from 'views/compositeBoxes/Button';
import PictureButton from 'views/compositeBoxes/PictureButton';
import ScrollBar from 'views/compositeBoxes/ScrollBar';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';
import InventoryManager from 'managers/InventoryManager';

class InventoryLayer extends Layer {
  constructor(inventoryItemData, menuButtonData, switchCursor, buttonClicked, itemClicked) {
    super();

    this.setRightClickHandler(switchCursor);

    let theme = Style.getTheme('inventoryBox');

    this._inventoryItemData = inventoryItemData;
    this._menuButtonData = menuButtonData;
    this._buttonClicked = buttonClicked;
    this._itemClicked = itemClicked;

    this._scrollAmount = 0;

    this._itemsInRow = theme.itemSet.itemsInRow;
    this._itemsInColumn = theme.itemSet.itemsInColumn;

    // array of PictureButton instances used in itemSet. Used so we can iterate through and destroy
    // in destroy() method
    this._pictureButtons = [];
    // Row instances filled with PitureButton instances
    this._itemRows = [];

    this._setupEmptyRows();
    this._itemColumn = new Column(this._itemRows, theme.itemSet.column);

    this._itemSet = new Box(this._itemColumn);
    this._itemSet.draw(theme.itemSet.box);

    let setDims = this._itemSet.getDimensions();

    this._scrollBar = new ScrollBar(
      (scrollAmount) => {
        if (scrollAmount > 0) {
          this._scrollDown();
        } else {
          this._scrollUp();
        }
      },
      setDims.h,
      theme.scrollBar.arrowImage,
      theme.scrollBar.topArrow,
      theme.scrollBar.bottomArrow,
      theme.scrollBar.stemBox,
      theme.scrollBar.column,
      theme.scrollBar.scrollBox
    );
    this._setAndScrollColumn = new Row([this._itemSet, this._scrollBar], theme.setAndScrollRow)
    this._setAndScrollBox = new Box(this._setAndScrollColumn);
    this._setAndScrollBox.draw(theme.setAndScrollBox);

    this._buttons = this._makeButtons();
    this._buttonRow = new Row(this._buttons, theme.buttons.buttonRow);

    this._mainColumn = new Column([this._setAndScrollBox, this._buttonRow], theme.mainColumn);

    this._base = new Box(this._mainColumn);
    this._base.draw(theme.base);

    this._focusIndex = 0;
    this._focusables = [...this._buttons, ...this._pictureButtons];
    this._drawFocus();

    this.addChild(this._base);
    this.centerBox(this._base);

    this._drawInventoryItems();
  }
  _setupEmptyRows() {
    let theme = Style.getTheme('inventoryBox');
    for (let j = 0; j < this._itemsInColumn; j++) {
      let picButtonArray = [];
      for (let i = 0; i < this._itemsInRow; i++) {
        let imageStore =  ImageManager.getImage('inventory', 'blank');
        let picButton = new PictureButton(
          '' + j + i,
          () => {
            console.log('Item pressed');
          },
          imageStore,
          theme.items.image,
          theme.items.box,
          theme.items.imageActive,
          theme.items.boxActive,
          theme.items.imageDisabled,
          theme.items.boxDisabled,
          theme.items.boxFocused,
        );
        this._pictureButtons.push(picButton);
        picButtonArray.push(picButton);
      }
      this._itemRows.push(new Row(picButtonArray, theme.itemSet.row));
    }
  }
  updateInventoryItems(newInventoryItemData) {
    this._inventoryItemData = newInventoryItemData;
    this._drawInventoryItems();
  }
  _drawInventoryItems() {

    this._rowData = [];
    for (let i = 0; i < this._inventoryItemData.length; i+= this._itemsInRow) {
       this._rowData.push(this._inventoryItemData.slice(i, i + this._itemsInRow));
    }

    for (let j = 0; j < this._itemsInColumn; j++) {
      for (let i = 0; i < this._itemsInRow; i++) {
        let rowNumber = this._scrollAmount + j;
        let imageStore =  ImageManager.getImage('inventory', 'blank');
        let callBack = () => {};
        if (this._rowData.length > rowNumber && this._rowData[rowNumber].length > i) {
          let item = this._rowData[rowNumber][i];
          imageStore = ImageManager.getImage('inventory', item.name);
          callBack = () => {
            this._itemClicked(item);
          };
        }
        let pictureButton = this._pictureButtons[j * this._itemsInRow + i];
        pictureButton.setImage(imageStore);
        pictureButton.setOnClick(callBack);
        pictureButton.draw();
      }
    }
  }
  _scrollUp() {
    let theme = Style.getTheme('inventoryBox');

    if (this._scrollAmount > 0) {
      this._scrollAmount--;
      this._drawInventoryItems();
      return true;
    }
    return false;
  }
  _scrollDown() {
    let theme = Style.getTheme('inventoryBox');

    this._itemsInColumn = theme.itemSet.itemsInColumn;
    if (this._scrollAmount + this._itemsInColumn < this._rowData.length) {
      this._scrollAmount++;
      this._drawInventoryItems();
      return true;
    }
    return false;
  }
  destroy() {
    this.removeEventListener();
    this._buttons.forEach((button) => button.destroy());
    this._buttonRow.destroy();
    this._itemSet.destroy();
    this._scrollBar.destroy();
    this._mainColumn.destroy();
    this._base.destroy();
    this._removeLayerDiv();
  }
  setDepressedButton(name) {
    this._buttons.forEach((button) => {
      if (button.getName() == name) {
        button.setDepressed(true);
      } else {
        button.setDepressed(false);
      }
      button.draw();
    })
  }
  _makeButtons() {
    let theme = Style.getTheme('inventoryBox');

    let buttons = [];
    this._menuButtonData.forEach((name) => {
      let imageStore = ImageManager.getImage('menuButtons', name);
      let button = new PictureButton(
        name,
        () => this._buttonClicked(name),
        imageStore,
        theme.buttons.image,
        theme.buttons.box,
        theme.buttons.imageActive,
        theme.buttons.boxActive,
        theme.buttons.imageDisabled,
        theme.buttons.boxDisabled,
        theme.buttons.boxFocused,
      );
      buttons.push(button);
    });
    return buttons;
  }
  _drawFocus() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex) {
        focusable.setFocused(true);
      } else {
        focusable.setFocused(false);
      }
      focusable.draw();
    });
  }
  _clickFocusedButton() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex && focusable instanceof PictureButton) {
        focusable.click();
      }
    });
  }
  keyPressed(key) {
    if (key == 'Enter') {
      this._clickFocusedButton();
    }
    if (key == 'ArrowUp') {
      if (this._scrollUp()) {
        return;
      }
    }
    if (key == 'ArrowDown') {
      if (this._scrollDown()) {
        return;
      }
    }
    if (key == 'ArrowDown' || key == 'ArrowRight') {
      this._focusIndex++;
      if (this._focusIndex >= this._focusables.length) {
        // 0 is the input in this layer
        this._focusIndex = 0;
      }
      this._drawFocus();
    }
    if (key == 'ArrowUp' || key == 'ArrowLeft') {
      this._focusIndex--;
      if (this._focusIndex < 0) {
        this._focusIndex = this._focusables.length - 1;
      }
      this._drawFocus();
    }
  }
}

export default InventoryLayer;

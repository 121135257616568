export default {
  "bg": {
    "x": 0,
    "y": 0
  },
  "eyes": {
    "x": 30,
    "y": 22
  },
  "mouth": {
    "x": 23,
    "y": 50
  }
}
import Style from 'style/Style';
import ImageManager from 'managers/ImageManager';
import colors from 'style/colors';


// NOTE: THIS IS ALSO USED TO STYLE THE RESTORE LAYER

const BUTTON_FONT = "sarala";

export default {
  mainColumn: Style.makeColumnRowStyle('start', 1),
  base: {
    backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 3, colors.MAIN_COLOR, true, 5, true, 1, false),
    ],
    shadow: Style.makeBoxShadow(colors.SHADOW, 1, 1),
    padding: 5,
  },
  label: {
    fontName: 'main',
    maxWidth: 250,
    minWidth: 250,
    padding: 0,
    centered: false,
    color: colors.BLACK,
  },
  input: {
    text: {
      fontName: '999',
      padding: 0,
      centered: true,
      color: colors.BLACK,
    },
    highlightText: {
      fontName: '999',
      padding: 0,
      centered: true,
      backgroundColor: colors.BLACK,
      color: colors.CLEAR,
    },
    box: {
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    }
  },
  buttons: {
    column: Style.makeColumnRowStyle('center', 1),
    text: {
      fontName: BUTTON_FONT,
      maxWidth: 60,
      minWidth: 60,
      padding: 0,
      centered: true,
      color: colors.BLACK,
    },
    textHover: {
      fontName: BUTTON_FONT,
      maxWidth: 60,
      minWidth: 60,
      padding: 0,
      centered: true,
      color: colors.BLACK,
    },
    textActive: {
      fontName: BUTTON_FONT,
      maxWidth: 60,
      minWidth: 60,
      padding: 0,
      centered: true,
      color: colors.MAIN_COLOR,
    },
    box: {
      //backgroundColor: Style.makeBackgroundColor(colors.WHITE),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    },
    boxHover: {
      padding: 1,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES,2, colors.BLACK),
      ],
    },
    boxActive: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR),
      ],
    },
  },
  bottomRow: Style.makeColumnRowStyle('start', 1),
  selection: {
    width: 200,
    arrowImage: ImageManager.getImage('misc', 'selectionArrow'),
    topArrow: {
      padding: 1,
      minWidth: 200,
      centered: true,
      borders: [
        Style.makeBoxBorder(Style.BOTTOM, 1, colors.BLACK),
      ],
    },
    bottomArrow: {
      padding: 1,
      minWidth: 200,
      centered: true,
      flippedY: true,
      borders: [
        Style.makeBoxBorder(Style.TOP, 1, colors.BLACK),
      ],
    },
    listItem: {
      text: {
        fontName: '999',
        maxWidth: 200,
        padding: 0,
        color: colors.BLACK,
      },
      textBox: {
        padding: 1,
        minWidth: 200,
      },
      highlightText: {
        fontName: '999',
        padding: 0,
        color: colors.MAIN_COLOR,
      },
      highlightTextBox: {
        minWidth: 200,
        padding: 1,
        backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      },
      height: 9,
    },
    outerColumn: Style.makeColumnRowStyle('start', 0),
    outerBox: {
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    },
  }
}

import Layer from 'views/layers/Layer';
import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import Column from 'views/compositeBoxes/Column';
import Row from 'views/compositeBoxes/Row';
import Button from 'views/compositeBoxes/Button';
import Slider from 'views/compositeBoxes/Slider';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';

class SettingsLayer extends Layer {
  constructor(
    steps,
    detail,
    detailChanged,
    volume,
    volumeChanged,
    speed,
    speedChanged,
    pushedSave,
    pushedRestore,
    pushedRestart,
    pushedQuit,
    pushedAbout,
    pushedQuestionMark,
    close) {

    super();

    this._steps = steps;
    this._detailValue = detail;
    this._detailChanged = detailChanged;
    this._volumeValue = volume;
    this._volumeChanged = volumeChanged;
    this._speedValue = speed;
    this._speedChanged = speedChanged;
    this._pushedSave = pushedSave;
    this._pushedRestore = pushedRestore;
    this._pushedRestart = pushedRestart;
    this._pushedQuit = pushedQuit;
    this._pushedAbout = pushedAbout;
    this._pushedQuestionMark = pushedQuestionMark;
    this._close = close;

    let theme = Style.getTheme('settingsBox');

    let tempColumn = new EmptyBox(50, 100);
    let tempColumn2 = new EmptyBox(100, 100);

    this._miniButtonRow = null;
    this._buttons = this._makeButtons();

    this._leftColumn = new Column(this._buttons, theme.leftColumn);

    this._leftColumnBox = new Box(this._leftColumn);
    this._leftColumnBox.draw(theme.leftColumnBox);

    this._title = new TextBox('GAME PAUSED');
    this._title.draw(theme.title);

    this._scoreText = new TextBox('Score: 261 of 261');
    this._scoreText.draw(theme.scoreText);
    this._scoreBox = new Box(this._scoreText);
    this._scoreBox.draw(theme.scoreBox);

    this._makeSliders();

    this._rightColumn = new Column([this._title, this._sliderRow, this._scoreBox], theme.rightColumn);
    this._rightColumnBox = new Box(this._rightColumn);
    this._rightColumnBox.draw(theme.rightColumnBox);

    this._mainRow = new Row([this._leftColumnBox, this._rightColumnBox], theme.mainRow);

    this._base = new Box(this._mainRow);
    this._base.draw(theme.base);

    this._focusIndex = 0;
    this._focusables = [this._save, this._restore, this._restart, this._quit, this._about, this._info, this._play, this._detail, this._volume, this._speed];
    this._drawFocus();

    this.addChild(this._base);
    this.centerBox(this._base);
  }
  _makeSliders() {
    let theme = Style.getTheme('settingsBox');

    this._detailLabel = new TextBox('DETAIL');
    this._detailLabel.draw(theme.sliders.sliderLabel);

    this._detail = new Slider(
      this._steps,
      this._detailValue,
      this._detailChanged,
      theme.sliders.stem,
      theme.sliders.stemBox,
      theme.sliders.handleImage,
      theme.sliders.handleBox
    );

    this._detailColumn = new Column([this._detailLabel, this._detail], theme.sliders.sliderColumn);


    this._volumeLabel = new TextBox('VOLUME');
    this._volumeLabel.draw(theme.sliders.sliderLabel);

    this._volume = new Slider(
      this._steps,
      this._volumeValue,
      this._volumeChanged,
      theme.sliders.stem,
      theme.sliders.stemBox,
      theme.sliders.handleImage,
      theme.sliders.handleBox
    );

    this._volumeColumn = new Column([this._volumeLabel, this._volume], theme.sliders.sliderColumn);

    this._speedLabel = new TextBox('SPEED');
    this._speedLabel.draw(theme.sliders.sliderLabel);

    this._speed = new Slider(
      this._steps,
      this._speedValue,
      this._speedChanged,
      theme.sliders.stem,
      theme.sliders.stemBox,
      theme.sliders.handleImage,
      theme.sliders.handleBox
    );

    this._speedColumn = new Column([this._speedLabel, this._speed], theme.sliders.sliderColumn);
    this._sliderRow = new Row([this._detailColumn, this._volumeColumn, this._speedColumn], theme.sliders.sliderRow);

    this._sliders = [this._detail, this._volume, this._speed];
  }
  _makeButtons() {
    let theme = Style.getTheme('settingsBox');

    this._save = new Button(
      this._pushedSave,
      'SAVE',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    );
    this._restore = new Button(
      this._pushedRestore,
      'RESTORE',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )
    this._restart = new Button(
      this._pushedRestart,
      'RESTART',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )
    this._quit = new Button(
      this._pushedQuit,
      'QUIT',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )
    this._about = new Button(
      this._pushedAbout,
      'ABT',
      theme.buttons.miniText,
      theme.buttons.box,
      theme.buttons.miniTextHover,
      theme.buttons.boxHover,
      theme.buttons.miniTextActive,
      theme.buttons.boxActive
    );
    this._info = new Button(
      this._pushedQuestionMark,
      '?',
      theme.buttons.miniText,
      theme.buttons.box,
      theme.buttons.miniTextHover,
      theme.buttons.boxHover,
      theme.buttons.miniTextActive,
      theme.buttons.boxActive
    );
    this._miniButtonRow = new Row([this._about, this._info], theme.buttons.miniRow);
    this._play = new Button(
      this._close,
      'PLAY',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )

    return [this._save, this._restore, this._restart, this._quit, this._miniButtonRow, this._play];
  }
  _drawFocus() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex) {
        focusable.setFocused(true);
      } else {
        focusable.setFocused(false);
      }
      if (focusable.draw) {
        focusable.draw();
      }
    });
  }
  handleMouseMove(data) {
    this._volume.mouseMoved(data);
    this._speed.mouseMoved(data);
    this._detail.mouseMoved(data);
  }
  handleMouseUp() {
    this._volume.mouseUp();
    this._speed.mouseUp();
    this._detail.mouseUp();
  }
  _clickFocusedButton() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex && focusable instanceof Button) {
        focusable.click();
      }
    });
  }
  keyPressed(key) {
    if (key == 'Enter') {
      this._clickFocusedButton();
    }
    if (key == 'ArrowUp') {
      for (let i = 0; i < this._sliders.length; i++) {
        let slider = this._sliders[i];
        if (slider.isFocused()) {
          slider.slideUp();
          return;
        }
      }
    }
    if (key == 'ArrowDown') {
      for (let i = 0; i < this._sliders.length; i++) {
        let slider = this._sliders[i];
        if (slider.isFocused()) {
          slider.slideDown();
          return;
        }
      }
    }
    if (key == 'ArrowDown' || key == 'ArrowRight') {
      this._focusIndex++;
      if (this._focusIndex >= this._focusables.length) {
        // 0 is the input in this layer
        this._focusIndex = 0;
      }
      this._drawFocus();
    }
    if (key == 'ArrowUp' || key == 'ArrowLeft') {
      this._focusIndex--;
      if (this._focusIndex < 0) {
        this._focusIndex = this._focusables.length - 1;
      }
      this._drawFocus();
    }
  }
  destroy() {
    this.removeEventListener();

    this._buttons.forEach((button) => button.destroy());
    this._leftColumn.destroy();
    this._leftColumnBox.destroy();

    this._title.destroy();

    this._scoreText.destroy();
    this._scoreBox.destroy();

    this._sliders.forEach((slider) => slider.destroy());

    this._detailLabel.destroy();
    this._detailColumn.destroy();

    this._volumeLabel.destroy();
    this._volumeColumn.destroy();

    this._speedLabel.destroy();
    this._speedColumn.destroy();
    this._sliderRow.destroy();

    this._rightColumn.destroy();
    this._rightColumnBox.destroy();
    this._mainRow.destroy();

    this._base.destroy();
    this._removeLayerDiv();
  }
  becameTopLayer() {
    MouseManager.setCursor('pointer');
  }
}

export default SettingsLayer;

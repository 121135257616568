import eventMixin from 'mixins/eventMixin';
import StateManager from 'managers/StateManager';
import config from 'config';

let TimeManager = {
  init: function() {
  },
  getLoopTime: function() {
    let settingsState = StateManager.getState('settings');
    let totalSteps = settingsState.getSteps();
    let speedPercent = (totalSteps - 1 - settingsState.getSpeed()) / totalSteps;
    let { minLoopTime, maxLoopTime } = config.settings;
    let diff = maxLoopTime - minLoopTime;
    let loopTime = maxLoopTime - speedPercent * diff;
    return loopTime;
  },
  getTime(seconds) {
    return seconds * 1000;
  }
}

export default TimeManager;

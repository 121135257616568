import ScreenController from 'screens/ScreenController';
import Rockroom from 'screens/Rockroom';
import MenuController from 'controllers/layerControllers/MenuController';
import GameLayer from 'views/layers/GameLayer';
import MouseManager from 'managers/MouseManager';
import TimeManager from 'managers/TimeManager';
import ImageManager from 'managers/ImageManager';
import GameObject from 'classes/gameObjects/GameObject';
import FixtureObject from 'classes/gameObjects/FixtureObject';
import AliasedObject from 'classes/gameObjects/AliasedObject';
import StateManager from 'managers/StateManager';
import Actions from 'classes/Actions';
import Speech from 'classes/Speech';

import outerRockroomData from 'screenData/outerRockroom';

class OuterRockroom extends ScreenController {
  _getDefaultEntry() {
    return 'doorEntry';
  }
  _getScreenData() {
    return outerRockroomData;
  }
  _getRequiredFaceImages() {
    return [];
  }
  _initializeNonFixtures() {
    this._addGameObject('background', new Background(() => this));
    this._addGameObject('door', new Door(() => this));
    this._addGameObject('character', new Character(() => this));
  }
  _loop() {
    if (!this._loaded) {
      return;
    }
    this._runActionQueues();
    this._setScales();
    if (this._shouldCheckExits()) {
      this._checkExits();
    }
    if (this._shouldCheckGeneralZones()) {
      this._checkGeneralZones();
    }
    this._draw();
  }
  _shouldCheckExits() {
    let selected = StateManager.getState('menu').getSelected();
    return selected != null;
  }
  _shouldCheckGeneralZones() {
    let selected = StateManager.getState('menu').getSelected();
    return selected != null;
  }
  _setInitialState() {
    if (this._state.enteredAt == 'doorEntry') {
      this.getObject('character').move(this.getPoint('doorEntry'));
      this.getObject('character').face('stop_l');
    }
    this._setActionQueue(
      "main",
      [
        Actions.endWait(),
      ]
    );
  }
  _handleExit(exitName) {
  }
  _makeLayer() {
    // switchCursor inherited from screencontroller, pushMenuController inherited from LayerController
    this._layer = new GameLayer(
      this._pushMenuController.bind(this),
      this._switchCursor.bind(this),
      this._leftClicked.bind(this),
      this._drawableObjectsArray,
    );
  }
  raiseLighting() {
    this._setActionQueue(
      'filter',
      [
        Actions.filter([-75, -75, 0]),
        Actions.sleep(.1),
        Actions.filter([-50, -50, 0]),
        Actions.sleep(.1),
        Actions.filter([-25, -25, 0]),
        Actions.sleep(.1),
        Actions.filter([0, 0, 0]),
      ]
    );
  }
  enterRockroom() {
    this._getParentController().changeScreen(Rockroom.id, 'exitA');
  }
}

OuterRockroom.id = "OuterRockroom";

export default OuterRockroom;

class Character extends GameObject {
  getRequiredImages() {
    return ['man'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 0,
        y: 0,
      },
      imageOffset: {
        x: .5,
        y: .953
      },
      name: 'character',
      basePoint: .953,
      isScalable: true,
      isDrawable: true,
      imageName: 'man',
      subImageName: 'stop_l',
    });
    this._hasChanged = true;
  }
  handleClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["You can do that later."]);
        break;
      }
      case 'eye': {
        this.showText(["That's yourself."]);
        break;
      }
      case 'talk': {
        this.showText(["You rehearse an amusing anecdote."]);
        break;
      }
    }
  }
  handleGeneralZoneEntry(name) {
    // switch (name) {
    //   case 'stopServer': {
    //     if (!StateManager.getState('rockroom').isAllowedInRocks()) {
    //       this._getParent().beginConfrontation();
    //     }
    //   }
    // }
  }
  handleGeneralZoneExit(name) {
    console.log('exited', name);
  }
}

class Background extends AliasedObject {
  handleClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().showText(["You feel nothing."]);
        break;
      }
      case 'eye': {
        this._getParent().showText(["You're out in the open. There is a building with the word 'Rocks' on it."]);
        break;
      }
      case 'talk': {
        this._getParent().showText(["It says nothing."]);
        break;
      }
    }
  }
}

class Door extends AliasedObject {
  handleClick(type) {
    switch (type) {
      case 'hand': {
        let character = this.getObject('character');
        character.clear();
        character.pushToActionQueue(Actions.walk(this.getPoint('doorEntry'), 'stop_r'));
        character.pushToActionQueue(Actions.sleep(.2));
        character.pushToActionQueue(Actions.method('enterRockroom', 'screen'));
        break;
      }
      case 'eye': {
        this.showText(["It's a door."]);
        break;
      }
      case 'talk': {
        this.showText(["It says nothing."]);
        break;
      }
    }
  }
}

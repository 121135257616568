export default {
  "bg": {
    "x": 0,
    "y": 0
  },
  "eyes": {
    "x": 25,
    "y": 29
  },
  "mouth": {
    "x": 30,
    "y": 53
  }
}
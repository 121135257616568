import ScreenController from 'screens/ScreenController';
import Office from 'screens/Office';
import MenuController from 'controllers/layerControllers/MenuController';
import GameLayer from 'views/layers/GameLayer';
import MouseManager from 'managers/MouseManager';
import TimeManager from 'managers/TimeManager';
import ImageManager from 'managers/ImageManager';
import GameObject from 'classes/gameObjects/GameObject';
import FixtureObject from 'classes/gameObjects/FixtureObject';
import AliasedObject from 'classes/gameObjects/AliasedObject';
import StateManager from 'managers/StateManager';
import Actions from 'classes/Actions';
import Speech from 'classes/Speech';

import lobbyData from 'screenData/lobby';

class Lobby extends ScreenController {
  _getDefaultEntry() {
    return 'frontDoor';
  }
  _getScreenData() {
    return lobbyData;
  }
  _getRequiredFaceImages() {
    return ['paulFace'];
  }
  _initializeNonFixtures() {
    this._addGameObject('background', new Background(() => this));
    this._addGameObject('staffroomDoor', new StaffroomDoor(() => this));
    this._addGameObject('officeDoor', new OfficeDoor(() => this));
    this._addGameObject('character', new Character(() => this));
    this._addGameObject('sittingPaul', new SittingPaul(() => this));
    this._addGameObject('paul', new Paul(() => this));
  }
  _loop() {
    if (!this._loaded) {
      return;
    }
    this._runActionQueues();
    this._setScales();
    if (this._shouldCheckExits()) {
      this._checkExits();
    }
    if (this._shouldCheckGeneralZones()) {
      this._checkGeneralZones();
    }
    this._draw();
  }
  _shouldCheckExits() {
    let selected = StateManager.getState('menu').getSelected();
    return selected != null;
  }
  _shouldCheckGeneralZones() {
    let selected = StateManager.getState('menu').getSelected();
    return selected != null;
  }
  _setInitialState() {
    if (this._state.enteredAt == 'frontDoor') {
      this.getObject('character').move({x: 43, y: 177});
      this.getObject('character').face('stop_ru');
      this.getObject('sittingPaul').show();
      this._setActionQueue(
        "main",
        [
          Actions.sleep(.5),
          Actions.pushToObjectQueue('character', Actions.walkFree(this.getPoint('insideFront'))),
          Actions.blocker('isAtPoint', 'character', [this.getPoint('insideFront')]),
          Actions.sleep(.5),
          Actions.text([
            `You step inside the office and see a man sitting outside your door.`,
            `Oh right, he has a job interview today.`,
            `You spend a few moments trying to remember what his resume said.`,
            `Better go say hi and get this over with.`,
          ]),
          Actions.sleep(.5),
          Actions.endWait(),
        ]
      );
    } else if (this._state.enteredAt == 'officeDoor') {
      this.getObject('character').move(this.getPoint('outsideOffice'));
      this.getObject('character').face('stop_ld');
      if (!StateManager.getState('paul').hasBegunInterview()) {
        this.getObject('sittingPaul').show();
      }
      this._setWaiting(false);
    }
  }
  _handleExit(exitName) {
  }
  _makeLayer() {
    // switchCursor inherited from screencontroller, pushMenuController inherited from LayerController
    this._layer = new GameLayer(
      this._pushMenuController.bind(this),
      this._switchCursor.bind(this),
      this._leftClicked.bind(this),
      this._drawableObjectsArray,
    );
  }
  beginEnteringOffice() {
    this.getObject('character').clear();
    this.getObject('character').pushToActionQueue(Actions.walk(this.getPoint('outsideOffice'), 'stop_ru', Actions.callback('screen', 'enterOffice')));
  }
  enterOffice() {
    this._getParentController().changeScreen(Office.id, 'doorEntry');
  }
  beginConversation() {
    this.getObject('character').clear();
    this.getObject('character').pushToActionQueue(Actions.walk(this.getPoint('convoPoint'), 'stop_ru', Actions.callback('screen', 'converse')));
  }
  converse() {
    this._setActionQueue(
      "main",
      [
        Actions.wait(),
        Actions.sleep(.25),
        Actions.converse(['paul.start']),
        Actions.sleep(.5),
        Actions.method('checkIfInterviewBegan', 'screen')
      ]
    );
  }
  checkIfInterviewBegan() {
    if (StateManager.getState("paul").hasBegunInterview()) {
      this._setActionQueue(
        "main",
        [
          Actions.pushToObjectQueue('character', Actions.walk(this.getPoint('outsideOffice'), 'stop_ru', Actions.callback('screen', 'enterOffice'))),
        ]
      );
    } else {
      this._setActionQueue(
        "main",
        [
          Actions.endWait(),
        ]
      )
    }
  }
}

Lobby.id = "Lobby";

export default Lobby;

class Character extends GameObject {
  getRequiredImages() {
    return ['interviewer'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 0,
        y: 0,
      },
      imageOffset: {
        x: .5,
        y: .953
      },
      name: 'character',
      basePoint: .953,
      isScalable: true,
      isDrawable: true,
      imageName: 'interviewer',
      subImageName: 'stop_l',
      isSitting: false,
    });
    this._hasChanged = true;
  }
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["You can do that later."]);
        return true;
        break;
      }
      case 'eye': {
        this.showText(["That's you."]);
        return true;
        break;
      }
      case 'talk': {
        this.showText(["You rehearse an amusing anecdote."]);
        return true;
        break;
      }
    }
    return false;
  }
  handleInventoryClick(item) {
    switch (item) {
      case 'money': {
        this.showText(["You rub the money on yourself for good luck."]);
        return true;
        break;
      }
    }
    return false;
  }
  isSitting() {
    return this._state.isSitting;
  }
  handleGeneralZoneEntry(name) {
    // switch (name) {
    //   case 'stopServer': {
    //     if (!StateManager.getState('rockroom').isAllowedInRocks()) {
    //       this._getParent().beginConfrontation();
    //     }
    //   }
    // }
  }
  handleGeneralZoneExit(name) {
    console.log('exited', name);
  }
  show() {
    this.pushToActionQueue(Actions.move({x: 195, y: 145 }));
    this._state.isSitting = true;
  }
  hide() {
    this.pushToActionQueue(Actions.move({x: -1000, y: -1000 }));
  }
}

class Paul extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().showText(["Don't touch the applicants."]);
        return true;
        break;
      }
      case 'eye': {
        this._getParent().showText(["That's the applicant, Paul."]);
        return true;
        break;
      }
      case 'talk': {
        this._getParent().beginConversation();
        return true;
        break;
      }
    }
    return false;
  }
}


class SittingPaul extends GameObject {
  getRequiredImages() {
    return ['sittingPaul'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: -1000,
        y: -1000,
      },
      name: 'sittingPaul',
      basePoint: .846,
      isScalable: false,
      isDrawable: true,
      imageName: 'sittingPaul',
      subImageName: 'sitting',
    });
    this._hasChanged = true;
  }
  getDefaultAlias() {
    return 'paul';
  }
  show() {
    this.pushToActionQueue(Actions.move({x: 207, y: 70 }))
  }
  hide() {
    this.pushToActionQueue(Actions.move({x: -1000, y: -1000 }));
  }
}

class Background extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().showText(["The lobby feels fine."]);
        return true;
        break;
      }
      case 'eye': {
        this._getParent().showText(["This is the lobby."]);
        return true;
        break;
      }
      case 'talk': {
        this._getParent().showText(["The lobby says nothing."]);
        return true;
        break;
      }
    }
  }
}

class StaffroomDoor extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().showText(["We should get this interview done before we have lunch."]);
        return true;
        break;
      }
      case 'eye': {
        this._getParent().showText(["That's the door to the staff room."]);
        return true;
        break;
      }
      case 'talk': {
        this._getParent().showText(["It says nothing."]);
        return true;
        break;
      }
    }
  }
}

class OfficeDoor extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().beginEnteringOffice();
        return true;
        break;
      }
      case 'eye': {
        this.showText(["That's the door to your office."]);
        return true;
        break;
      }
      case 'talk': {
        this.showText(["It says nothing."]);
        return true;
        break;
      }
    }
  }
}

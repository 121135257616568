import LayerController from 'controllers/layerControllers/LayerController';
import ScreenController from 'screens/ScreenController';
import SettingsLayer from 'views/layers/SettingsLayer';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';
import SaveManager from 'managers/SaveManager';

class SettingsMenuController extends LayerController {
  constructor() {
    super();
  }
  _makeLayer() {
    let settingsState = StateManager.getState('settings');
    let steps = settingsState.getSteps();
    let detail = settingsState.getDetail();
    let volume = settingsState.getVolume();
    let speed = settingsState.getSpeed();

    let detailChanged = (step) => settingsState.setDetail(step);
    let volumeChanged = (step) => settingsState.setVolume(step);
    let speedChanged = (step) => settingsState.setSpeed(step);
    let pushedSave = () => this.pushController(LayerController.SAVE);
    let pushedRestore = () => this.pushController(LayerController.RESTORE);
    let pushedRestart = () => SaveManager.restartGame();
    let pushedQuit = () => this.showText(['You can quit by leaving the page.']);
    let pushedAbout = () => this.showText([`INTERVIEW SIMULATOR, COPYRIGHT 2019 GORAH`]);
    let pushedQuestionMark = () => this.showText([`This button does not do anything.`]);
    let close = () => this.popThisController();

    let layer = new SettingsLayer(
      steps,
      detail,
      detailChanged,
      volume,
      volumeChanged,
      speed,
      speedChanged,
      pushedSave,
      pushedRestore,
      pushedRestart,
      pushedQuit,
      pushedAbout,
      pushedQuestionMark,
      close
    );

    this._layer = layer;
  }
  handleMouseMove(data) {
    this._layer.handleMouseMove(data);
  }
  handleMouseUp(data) {
    this._layer.handleMouseUp(data);
  }
  loop() {
    //
  }
  _keyPressed(key) {
    this._layer.keyPressed(key);
  }
  becameActive() {
    MouseManager.setCursor('pointer');
  }
}

export default SettingsMenuController;

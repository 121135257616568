class ConversationLine {
  constructor(getActions, shouldShow = () => true, changeState = () => {}) {
    if (!getActions) {
      throw Error("All ConversationLines must be supplied a getActions function");
    }
    if (typeof shouldShow != 'function') {
      throw Error("shouldShow should be a function");
    }
    if (typeof changeState != 'function') {
      throw Error("changeState should be a function");
    }
    this.getActions = getActions;
    this.shouldShow = shouldShow;
    this.changeState = changeState;
  }
}

export default ConversationLine;

require('../scss/app.scss');

import LayoutManager from 'managers/LayoutManager';
import MouseManager from 'managers/MouseManager';
import KeyManager from 'managers/KeyManager';
import TimeManager from 'managers/TimeManager';
import FontManager from 'managers/FontManager';
import ImageManager from 'managers/ImageManager';
import StateManager from 'managers/StateManager';
import SaveManager from 'managers/SaveManager';
import GameController from 'controllers/GameController';
import config from 'config';

let app = {
  init: function() {
    this._loadAssets()
    .then(() => {
      StateManager.init();
      LayoutManager.init();
      MouseManager.init();
      KeyManager.init();
      TimeManager.init();
      LayoutManager.setMagnification(2);
      SaveManager.init();

      this._gameController = new GameController();
    });
  },
  _loadAssets: function() {
    return Promise.all([
      FontManager.loadFonts(),
      ImageManager.loadStartupImages(),
    ]);
  }
}

app.init();

import Box from 'views/canvasBoxes/Box';
import PictureBox from 'views/canvasBoxes/PictureBox';
import CompositeBox from 'views/compositeBoxes/CompositeBox';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import focusableMixin from 'mixins/focusableMixin';

class Slider extends CompositeBox {
  constructor(steps, currentStep, onSlide, stemOptions, stemBoxOptions, handleImage, handleBoxOptions) {
    super();
    focusableMixin(this);
    this._steps = steps;
    this._currentStep = currentStep;
    this._stepPositions = [];

    this._isDragging = false;
    this._currentMousePosition = {x: 0, y: 0};
    this._onSlide = onSlide;

    this._stemOptions = stemOptions;
    this._stemBoxOptions = stemBoxOptions;
    this._handleImage = handleImage;
    this._handleBoxOptions = handleBoxOptions;

    this._handlePic = new PictureBox();
    this._handlePic.drawImage(this._handleImage);
    this._handleBox = new Box(this._handlePic);
    this._handleBox.draw(this._handleBoxOptions);
    this._handleBox.setClickable(true);

    this._stem = new EmptyBox(stemOptions.w, stemOptions.h);
    this._stemBox = new Box(this._stem);
    this._stemBox.draw(stemBoxOptions);

    let handleDims = this._handleBox.getDimensions();
    let stemDims = this._stemBox.getDimensions();

    this._base = new EmptyBox(handleDims.w, stemDims.h);
    this._base.setContent(this._stemBox);
    this._base.setContent(this._handleBox);

    this._stepPositions = this._calculateStepPositions(this._steps, this._base.getDimensions().h);
    this._addListeners();

    this._stemBox.setPosition(Math.round((handleDims.w - stemDims.w) / 2), 0);

    this._placeHandle();

  }
  _calculateStepPositions(steps, height) {
    let handleDims = this._handleBox.getDimensions();
    let positions = [];
    let interval = Math.round((height) / (steps));
    for ( let i = 0; i < steps; i ++) {
      positions.push({ x: 0, y: i * interval})
    };
    return positions;
  }
  _addListeners() {
    this._handleBox.addListener('mousedown', () => {
      this._currentMousePosition = {
        ...this._stepPositions[this._currentStep]
      };
      this._isDragging = true;
    });
  }
  mouseMoved(data) {
    if (this._isDragging) {
      this._currentMousePosition.y += data.difference.y;
      let currStep = 0;
      this._stepPositions.forEach((pos, index) => {
        if (this._currentMousePosition.y > pos.y) {
          currStep = index;
        }
      });
      this._setCurrentStep(currStep);
      this._placeHandle();
    }
  }
  slideDown() {
    if (this._currentStep == this._stepPositions.length - 1) {
      return;
    }
    this._setCurrentStep(this._currentStep + 1);
    this._placeHandle();
  }
  slideUp() {
    if (this._currentStep == 0) {
      return;
    }
    this._setCurrentStep(this._currentStep - 1);
    this._placeHandle();
  }
  _setCurrentStep(currentStep) {
    this._currentStep = currentStep;
    this._onSlide(currentStep);
  }
  mouseUp() {
    this._isDragging = false;
  }
  _placeHandle() {
    let pos = this._stepPositions[this._currentStep];
    this._handleBox.setPosition(pos.x, pos.y);
  }
  destroy() {
    this._handlePic.destroy();
    this._handleBox.destroy();

    this._stem.destroy();
    this._stemBox.destroy();

    this._base.destroy();
  }
}

export default Slider;

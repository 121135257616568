import Destroyable from 'classes/Destroyable';
import LayoutManager from 'managers/LayoutManager';

class LayerController extends Destroyable {
  constructor() {
    super();
    this._layerControllerStack = [];
  }
  setParentControllerGetter(parentControllerGetter) {
    this._getParentController = parentControllerGetter;
  }
  // only topmost layer controller should have its loop called
  loop() {
    if (this._layerControllerStack.length > 0) {
      this._layerControllerStack[this._layerControllerStack.length - 1].loop();
    } else {
      this._loop();
    }
  }
  _loop() {
    // should be overriden if needed
  }
  keyPressed(key) {
    if (this._layerControllerStack.length > 0) {
      this._layerControllerStack[this._layerControllerStack.length - 1].keyPressed(key);
    } else {
      this._keyPressed(key);
    }
  }
  _keyPressed(key) {
    // should be overridden by subclasses. For when screen controller needs to handle key presses
  }
  handleMouseMove(position) {
    if (this._layerControllerStack.length > 0) {
      this._layerControllerStack[this._layerControllerStack.length - 1].handleMouseMove(position);
    } else {
      this._handleMouseMove(position);
    }
  }
  _handleMouseMove(position) {
    // should be overwritten if needed
  }
  handleMouseUp(position) {
    if (this._layerControllerStack.length > 0) {
      this._layerControllerStack[this._layerControllerStack.length - 1].handleMouseUp(position);
    } else {
      this._handleMouseUp(position);
    }
  }
  _handleMouseUp(position) {
    // should be overwritten if a layer needs to
  }
  replaceLayerWith(controllerName) {
    this.popController();
    this.pushController(controllerName);
  }
  // just routes direction to other method
  pushController(controllerName) {
    switch (controllerName) {
      case LayerController.MENU: {
        this._pushMenuController();
        break;
      }
      case LayerController.INVENTORY: {
        this._pushInventoryController();
        break;
      }
      case LayerController.SETTINGS: {
        this._pushSettingsMenuController();
        break;
      }
      case LayerController.SAVE: {
        this._pushSaveMenuController();
        break;
      }
      case LayerController.RESTORE: {
        this._pushRestoreMenuController();
        break;
      }
    }
  }
  // actually pushes the controller
  _pushController(controller) {
    this._layerControllerStack.push(controller);
    controller.setParentControllerGetter(() => this);
    controller.pushLayer();
    controller.becameActive();
  }
  popController() {
    let controller = this._layerControllerStack.pop();
    controller.popLayer();
    controller.destroy();
    if (this._layerControllerStack.length > 0) {
      this._layerControllerStack[this._layerControllerStack.length - 1].becameActive();
    } else {
      this.becameActive();
    }
  }
  popThisController() {
    this._getParentController().popController();
  }
  showDialogue(character, faceName, textArray) {
    let FaceTextController = require('controllers/layerControllers/FaceTextController').default;
    this._pushController(new FaceTextController(character, faceName, textArray));
  }
  showConversation(conversationStack) {
    let ConversationController = require('controllers/layerControllers/ConversationController').default;
    this._pushController(new ConversationController(conversationStack));
  }
  showText(textArray) {
    let TextController = require('controllers/layerControllers/TextController').default;
    this._pushController(new TextController(textArray));
  }
  _pushMenuController() {
    let MenuController = require('controllers/layerControllers/MenuController').default;
    this._pushController(new MenuController());
  }
  _pushInventoryController() {
    let InventoryMenuController = require('controllers/layerControllers/InventoryMenuController').default;
    this._pushController(new InventoryMenuController());
  }
  _pushSettingsMenuController() {
    let SettingsMenuController = require('controllers/layerControllers/SettingsMenuController').default;
    this._pushController(new SettingsMenuController());
  }
  _pushSaveMenuController() {
    let SaveMenuController = require('controllers/layerControllers/SaveMenuController').default;
    this._pushController(new SaveMenuController());
  }
  _pushRestoreMenuController() {
    let RestoreMenuController = require('controllers/layerControllers/RestoreMenuController').default;
    this._pushController(new RestoreMenuController());
  }
  replaceController(controllerName) {
    this.popController();
    this.pushController(controllerName);
  }
  pushLayer() {
    LayoutManager.pushLayer(this._getLayer());
  }
  popLayer() {
    LayoutManager.popLayer();
  }
  _getLayer() {
    if (this._layer == null) {
      this._makeLayer();
    }
    return this._layer;
  }
  _makeLayer() {
    throw Error('Subclasses of LayerController should override the _makeLayer() method.');
  }
  becameActive() {
    throw Error('Subclasses of LayerController should override the becameActive() method.');
  }
  destroy() {
    while (this._layerControllerStack.length > 0) {
      this.popController();
    }
    this._layer.destroy();
  }
}

LayerController.MENU = 'menu_layer';
LayerController.INVENTORY= 'inventory_layer';
LayerController.SETTINGS = 'settings_layer';
LayerController.SAVE = 'save_layer';
LayerController.RESTORE = 'restore_layer';
LayerController.FACE_TEXT = 'facetext_layer';

export default LayerController;

import characters from 'sprites/characters';
import general from 'sprites/general';
import screenObjects from 'sprites/screenObjects';
import faces from 'sprites/faces';

export default {
  ...characters,
  ...general,
  ...screenObjects,
  ...faces
}

export default {
  "name": "outerRockroom",
  "points": {
    "doorEntry": {
      "x": 230,
      "y": 166
    }
  },
  "fixtures": {
    "outerRockroom_fixture_background": {
      "aliasFor": "background",
      "alphaKey": 255,
      "width": 320,
      "height": 200,
      "name": "outerRockroom_fixture_background",
      "position": {
        "x": 0,
        "y": 0
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "basePoint": 0,
      "imageName": "outerRockroom",
      "subImageName": "outerRockroom_fixture_background"
    },
    "outerRockroom_fixture_0": {
      "aliasFor": "background",
      "basePoint": 0.46153846153846156,
      "width": 116,
      "height": 143,
      "x": 204,
      "y": 57,
      "alphaKey": 254,
      "name": "outerRockroom_fixture_0",
      "position": {
        "x": 204,
        "y": 57
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "outerRockroom",
      "subImageName": "outerRockroom_fixture_0"
    },
    "outerRockroom_fixture_1": {
      "aliasFor": "door",
      "basePoint": 0.6129032258064516,
      "width": 19,
      "height": 62,
      "x": 229,
      "y": 117,
      "alphaKey": 253,
      "name": "outerRockroom_fixture_1",
      "position": {
        "x": 229,
        "y": 117
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "outerRockroom",
      "subImageName": "outerRockroom_fixture_1"
    }
  },
  "zones": {
    "walkAreas": [
      {
        "name": "walk1",
        "points": [
          {
            "x": 2,
            "y": 85
          },
          {
            "x": 317,
            "y": 87
          },
          {
            "x": 317,
            "y": 120
          },
          {
            "x": 198,
            "y": 121
          },
          {
            "x": 199,
            "y": 125
          },
          {
            "x": 254,
            "y": 197
          },
          {
            "x": 3,
            "y": 193
          }
        ],
        "type": "walk",
        "extraData": ""
      }
    ],
    "exits": {},
    "general": {},
    "depth": [
      {
        "name": "1",
        "points": [
          {
            "x": 262,
            "y": 178
          },
          {
            "x": 0,
            "y": 177
          },
          {
            "x": 0,
            "y": 198
          },
          {
            "x": 289,
            "y": 199
          }
        ],
        "type": "depth",
        "extraData": ""
      },
      {
        "name": ".8",
        "points": [
          {
            "x": 223,
            "y": 142
          },
          {
            "x": 262,
            "y": 177
          },
          {
            "x": 0,
            "y": 177
          },
          {
            "x": 0,
            "y": 144
          }
        ],
        "type": "depth",
        "extraData": ""
      },
      {
        "name": ".7",
        "points": [
          {
            "x": 203,
            "y": 121
          },
          {
            "x": 224,
            "y": 142
          },
          {
            "x": 0,
            "y": 143
          },
          {
            "x": 0,
            "y": 121
          }
        ],
        "type": "depth",
        "extraData": ""
      },
      {
        "name": ".6",
        "points": [
          {
            "x": 0,
            "y": 103
          },
          {
            "x": 318,
            "y": 102
          },
          {
            "x": 318,
            "y": 119
          },
          {
            "x": 0,
            "y": 121
          }
        ],
        "type": "depth",
        "extraData": ""
      },
      {
        "name": ".4",
        "points": [
          {
            "x": 0,
            "y": 84
          },
          {
            "x": 319,
            "y": 84
          },
          {
            "x": 319,
            "y": 102
          },
          {
            "x": 1,
            "y": 102
          }
        ],
        "type": "depth",
        "extraData": ""
      }
    ]
  }
}
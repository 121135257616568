import config from 'config';

let { colors } = config;

let Style =  {
  ALL_SIDES: 0,
  TOP: 1,
  RIGHT: 2,
  BOTTOM: 3,
  LEFT: 4,
  darken: function(color, value) {
    return [
      color[0] - value,
      color[1] - value,
      color[2] - value,
      color[3]
    ];
  },
  lighten: function(color, value) {
    return [
      color[0] + value,
      color[1] + value,
      color[2] + value,
      color[3]
    ];
  },
  // if 2 colors are given, it will dither them
  makeImageFilter: function(color1, color2) {
    return {
      color1,
      color2
    }
  },
  makeBackgroundImage(canvasImage) {
    return {
      canvas: canvasImage,
    }
  },
  makeBoxBorder: function(sides, width = 1, color = colors.WHITE, textured = false, textureIntensity = 1, shaded = false, shadeIntensity = 10, invertedShade = false) {
    let border = {
      sides,
      width,
      textured,
      top: textured ? this._getTexturedColor(color, textureIntensity) : color,
      right: textured ? this._getTexturedColor(color, textureIntensity) : color,
      bottom: textured ? this._getTexturedColor(color, textureIntensity) : color,
      left: textured ? this._getTexturedColor(color, textureIntensity) : color,
    };
    if (shaded) {
      if (!invertedShade) {
        border.top = textured ? this._getTexturedColor(this._getShadedColor(color, shadeIntensity, 40), textureIntensity) : this._getShadedColor(color, shadeIntensity, 40);
        border.right = textured ? this._getTexturedColor(this._getShadedColor(color, shadeIntensity, -40), textureIntensity) : this._getShadedColor(color, shadeIntensity, -40);
        border.bottom = textured ? this._getTexturedColor(this._getShadedColor(color, shadeIntensity, -60), textureIntensity) : this._getShadedColor(color, shadeIntensity, -60);
        border.left = textured ? this._getTexturedColor(this._getShadedColor(color, shadeIntensity, 10), textureIntensity) : this._getShadedColor(color, shadeIntensity, 10);
      } else {
        border.bottom = textured ? this._getTexturedColor(this._getShadedColor(color,  shadeIntensity,40), textureIntensity) : this._getShadedColor(color, shadeIntensity, 40);
        border.left = textured ? this._getTexturedColor(this._getShadedColor(color, shadeIntensity, -40), textureIntensity) : this._getShadedColor(color, shadeIntensity, -40);
        border.top = textured ? this._getTexturedColor(this._getShadedColor(color, shadeIntensity, -60), textureIntensity) : this._getShadedColor(color, shadeIntensity, -60);
        border.right = textured ? this._getTexturedColor(this._getShadedColor(color, shadeIntensity, 10), textureIntensity) : this._getShadedColor(color, shadeIntensity, 10);
      }
    }
    return border;
  },
  makeBoxShadow: function(color = colors.BLACK, width = 1, height = 1, ) {
    return {
      width,
      height,
      color
    };
  },
  makeTextOutline: function(color = colors.BLACK) {
    return {
      color
    }
  },
  makeTextShadow: function(color = colors.BLACK) {
    return {
      color
    }
  },
  makeBackgroundColor: function(color = color.white, textured = false, textureIntensity = 1) {
    if (!textured) {
      return {
        color,
        textured
      };
    }
    return {
      textured,
      color: this._getTexturedColor(color, textureIntensity)
    };
  },
  makeColumnRowStyle: function(alignment = 'center', space = 0) {
    return {
      alignment,
      space
    }
  },
  _getTexturedColor: function(color, intensity) {
    return {
      colors: [
        color,
        [(color[0] + intensity), (color[1] + intensity), (color[2] + intensity), color[3]],
        [(color[0] - intensity), (color[1] - intensity), (color[2] - intensity), color[3]],
      ],
      _randomNum: function(seed) {
        var x = Math.sin(seed) * 10000;
        return x - Math.floor(x);
      },
      getRandom: function(num) {
        return this.colors[Math.floor(this._randomNum(num) * 3)];
      }
    };
  },
  _getShadedColor: function(color, intensity, shadeValue) {
    return [color[0] + Math.round(intensity * shadeValue), color[1] + Math.round(intensity * shadeValue), color[2] + Math.round(intensity * shadeValue), color[3]];
  },
  getTheme: function(name) {
    let themes = require('style/themes');
    return themes[name];
  },
  init: function() {

  },
};

Style.init();

export default Style;

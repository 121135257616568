import menu from './menu.js';
import settings from './settings.js'
import inventory from './inventory.js'
import rockroom from './rockroom.js';
import paul from './paul.js';

export default {
  menu,
  settings,
  inventory,
  rockroom,
  paul
}

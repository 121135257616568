import ScreenController from 'screens/ScreenController';
import MenuController from 'controllers/layerControllers/MenuController';
import TestLayer from 'views/layers/TestLayer';
import MouseManager from 'managers/MouseManager';
import TimeManager from 'managers/TimeManager';
import StateManager from 'managers/StateManager';
import FetchManager from 'managers/FetchManager';
import ImageManager from 'managers/ImageManager';
import LayoutManager from 'managers/LayoutManager';
import Style from 'style/Style';
import config from 'config';

import PictureBox from 'views/canvasBoxes/PictureBox';
import Canvas from 'views/Canvas';
import ImageStore from 'classes/ImageStore';

class TestScreen extends ScreenController {
  constructor() {
    super(null, null, true);

    this._screenName = window.prompt('Please enter name of screen being tested');

    if (this._screenName) {
      FetchManager.getWorkingRoomJSON(this._screenName)
      .then((json) => {
        if (Object.keys(json).length != 0) {
          this._storedData = json;
        } else {
          this._storedData = null;
        }
        this._bgCanvas = null;

        this._pointData = [];
        this._fixtureData = {};
        this._zoneData = [];

        this._selectingPoint = false;
        this._selectingZonePoints = false;
        this._zoneCanvas = null;
        this._zoneCtx = null;

        this._path = '/art/screens/' + this._screenName + '/' + this._screenName;
        this._jsonPath = this._path + '.json';
        this._imagePath = this._path + '.png';
        let promises = [];
        Promise.all([
          FetchManager.fetchJSON(this._jsonPath),
          ImageManager.loadImage(this._imagePath),
        ]).then((result) => {
          let [json, canvas] = result;
          this.pushLayer();
          this._createDefaultFixtureData();
          this._createDefaultPointData();
          this._createDefaultZoneData();
          this._processImage(json, canvas);
          this._createZoneCanvas();
          this._createForm();
          this._fillPointList();
          this._fillZoneList();
          this._addPointClickListener();
          this._fillFixtureList();

          this.becameActive();
          setInterval(() => {
            this._storeData();
          }, 2000);
        });
      })

    }
  }
  loop() {
    return;
  }
  _getDefaultEntry() {
  }
  _getScreenData() {
    return {};
  }
  _createZoneCanvas() {
    let magnification = LayoutManager.getMagnification();
    this._zoneCanvas = document.createElement('canvas');
    this._zoneCanvas.width = config.layout.screenWidth;
    this._zoneCanvas.height =  config.layout.screenHeight;
    this._zoneCanvas.style.position = 'absolute';
    this._zoneCanvas.style.imageRendering = 'pixelated';
    this._zoneCanvas.style.width = this._zoneCanvas.width * magnification + 'px';
    this._zoneCanvas.style.height = this._zoneCanvas.height * magnification + 'px';
    this._zoneCanvas.style.zIndex = '100';
    this._zoneCtx = this._zoneCanvas.getContext('2d');
    this._layer.getElement().appendChild(this._zoneCanvas);
  }
  _createDefaultFixtureData() {
    this._fixtureData = {};
  }
  _createDefaultPointData() {
    if (this._storedData) {
      this._pointData = this._storedData._pointData;
      return;
    }
    for (let i = 0; i < 20; i++) {
      let point = {
        name: i,
        coordinates: '',
      };
      this._pointData.push(point);
    }
  }
  _createDefaultZoneData() {
    if (this._storedData) {
      this._zoneData = this._storedData._zoneData;
      return;
    }
    for (let i = 0; i < 20; i++) {
      let zone = {
        name: i,
        points: '',
        type: 'general',
        extraData: '',
      };
      this._zoneData.push(zone);
    }
  }
  _createForm() {
    let formHTML = `<div class="pClickEditorBoxes">
                      <button id="saveJSON">Save</button>
                      <div class="pClickFixtureEditor">
                        <h2>fixtures</h2>
                        <div id="pClickFixtureList"></div>
                        <div class="pClickSelectedFixture">
                          <label>
                            Alias For
                            <input placeholder="name" type="text" id="FixtureAlias">
                          </label>
                          <label>
                            BasePoint
                            <input placeholder="basepoint" type="number" id="FixtureBasePoint">
                          </label>
                          <button id="FixtureBasePointButton">Set Base Point</button>
                        </div>
                      </div>
                      <div class="pClickPointEditor">
                        <h2>Points</h2>
                        <div id="pClickPointList"></div>
                        <div class="pClickSelectedPoint">
                          <label>
                            Name
                            <input placeholder="name" type="text" id="pointName">
                          </label>
                          <label>
                            Coordinates
                            <input placeholder="Coordinates" type="text" id="pointCoordinates">
                          </label>
                        </div>
                        <button id="pointButton">Set Point</button>
                      </div>
                      <div class="pClickZoneEditor">
                        <h2>Zones</h2>
                        <div id="pClickZoneList"></div>
                        <div class="pClickSelectedZone">
                          <label>
                            Name
                            <input placeholder="name" type="text" id="zoneName">
                          </label>
                          <label>
                            Points
                            <textarea placeholder="Points" id="zonePoints"></textarea>
                          </label>
                          <label>
                            Type
                            <select id="zoneType">
                              <option value="walk">Walk</option>
                              <option value="negativeWalk">NegativeWalk</option>
                              <option value="depth">Depth</option>
                              <option value="exit">Exit</option>
                              <option value="general">General</option>
                            </select>
                          </label>
                          <button id="zonePointsButton">Start setting zone points</button>
                        </div>
                      </div>
                    </div>`;


    this._form = document.getElementById('pClickEditorForm');
    this._form.innerHTML = formHTML;
    this._form.style.maxHeight = '600px';
    this._form.style.overflowY = 'auto';

    this._saveJSON = this._form.querySelector('#saveJSON');

    this._saveJSON.onclick = () => this._uploadJSON();

    this._fixtureList = this._form.querySelector('#pClickFixtureList');
    this._fixtureAlias = this._form.querySelector('#FixtureAlias');
    this._fixtureBasePoint = this._form.querySelector('#FixtureBasePoint');
    this._fixtureBasePointButton = this._form.querySelector('#FixtureBasePointButton');

    this._fixtureAlias.oninput = (e) => {
      this._fixtureData[this._selectedFixture].aliasFor = e.target.value;
      this._fixtureList.querySelector(`[data-id="${this._selectedFixture}"]`).innerHTML = e.target.value || this._selectedFixture;
      this._drawDataCanvas();
    };
    this._fixtureBasePoint.oninput = (e) => {
      this._fixtureData[this._selectedFixture].basePoint = e.target.value;
      this._drawDataCanvas();
    };
    this._fixtureBasePointButton.onmousedown = (e) => {
      if (this._selectingBasePoint) {
        this._fixtureBasePointButton.innerHTML = 'Set base point';
        this._selectingBasePoint = false;
      } else {
        this._fixtureBasePointButton.innerHTML = 'Finish setting base point';
        this._selectingBasePoint = true;
        this._drawDataCanvas();
      }
    };

    this._pointList = this._form.querySelector('#pClickPointList');
    this._pointName = this._form.querySelector('#pointName');
    this._pointCoordinates = this._form.querySelector('#pointCoordinates');
    this._pointButton = this._form.querySelector('#pointButton');


    this._pointName.oninput = (e) => {
      this._pointData[this._selectedPoint].name = e.target.value;
      this._pointList.querySelector(`[data-id="${this._selectedPoint}"]`).innerHTML = e.target.value || this._selectedPoint;
      this._drawDataCanvas();
    };
    this._pointCoordinates.oninput = (e) => {
      this._pointData[this._selectedPoint].coordinates = e.target.value;
      this._drawDataCanvas();
    };
    this._pointButton.onmousedown = (e) => {
      if (this._selectingPoint) {
        this._pointButton.innerHTML = 'Set points';
        this._selectingPoint = false;
      } else {
        this._pointButton.innerHTML = 'Finish setting point';
        this._selectingPoint = true;
        this._pointData[this._selectedPoint].coordinates = '';
        this._drawDataCanvas();
      }
    }

    this._zoneList = this._form.querySelector('#pClickZoneList');
    this._zoneName = this._form.querySelector('#zoneName');
    this._zonePoints = this._form.querySelector('#zonePoints');
    this._zoneType = this._form.querySelector('#zoneType');
    this._zoneButton = this._form.querySelector('#zonePointsButton');

    this._zoneName.oninput = (e) => {
      this._zoneData[this._selectedZone].name = e.target.value;
      this._zoneList.querySelector(`[data-id="${this._selectedZone}"]`).innerHTML = e.target.value || this._selectedZone;
      this._drawDataCanvas();
    };
    this._zonePoints.oninput = (e) => {
      this._zoneData[this._selectedZone].points = e.target.value;
      this._drawDataCanvas();
    };
    this._zoneType.oninput = (e) => {
      this._zoneData[this._selectedZone].type = e.target.value;
      this._drawDataCanvas();
    }
    this._zoneButton.onmousedown = (e) => {
      if (this.__selectingZonePoints) {
        this._zoneButton.innerHTML = 'Start setting zone points';
        this.__selectingZonePoints = false;
      } else {
        this._zoneButton.innerHTML = 'Finish setting zone points';
        this.__selectingZonePoints = true;
        this._zoneData[this._selectedZone].points = '';
        this._drawDataCanvas();
      }
    }
  }
  _storeData() {
    let data = JSON.stringify({
      _fixtureData: this._fixtureData,
      _pointData: this._pointData,
      _zoneData: this._zoneData
    });
    FetchManager.sendWorkingRoomJSON(this._screenName, data);
  }
  _fillPointList() {
    this._pointData.forEach((point, index) => {
      let pointButton = document.createElement('button');
      pointButton.setAttribute('data-id', index);
      this._pointList.appendChild(pointButton);
      pointButton.innerHTML = point.name;
      pointButton.onclick = () => {
        this._selectPoint(index);
      };
    });
    this._selectPoint(0);
  }
  _fillZoneList() {
    this._zoneData.forEach((zone, index) => {
      let zoneButton = document.createElement('button');
      zoneButton.setAttribute('data-id', index);
      this._zoneList.appendChild(zoneButton);
      zoneButton.innerHTML = zone.name;
      zoneButton.onclick = () => {
        this._selectZone(index);
      }
    });
    this._selectZone(0);
  }
  _fillFixtureList() {
    let keys = Object.keys(this._fixtureData);
    let firstKey = null;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (i == 0) {
        firstKey = key;
      }
      let obj = this._fixtureData[key];
      let objButton = document.createElement('button');
      objButton.setAttribute('data-id', key);
      this._fixtureList.appendChild(objButton);
      objButton.innerHTML = obj.aliasFor || i;
      objButton.onclick = () => {
        this._selectFixture(key);
      };
    }
    this._selectFixture(firstKey);
  }
  _addPointClickListener() {
    this._layer.addMousedownListener((e) => {
      let pos = MouseManager.getLogicalPosition(e);
      if (this.__selectingZonePoints) {
        let zone = this._zoneData[this._selectedZone];
        if (MouseManager.eventIsRightClick(e)) {
          let zonePoints = zone.points.split('\n');
          zonePoints.pop();
          zone.points = zonePoints.join('\n');
          this._zonePoints.value = zone.points;
          this._drawDataCanvas();
          return;
        }
        zone.points = `${zone.points}\n${pos.x},${pos.y}`.trim();
        this._zonePoints.value = zone.points;
        this._drawDataCanvas();
      } else if (this._selectingPoint) {
        let point = this._pointData[this._selectedPoint];
        if (MouseManager.eventIsRightClick(e)) {
          return;
        }
        point.coordinates = `${pos.x},${pos.y}`.trim();
        this._pointCoordinates.value = point.coordinates;
        this._drawDataCanvas();
      } else if (this._selectingBasePoint) {
        let obj = this._fixtureData[this._selectedFixture];
        if (MouseManager.eventIsRightClick(e)) {
          return;
        }
        obj.basePoint = pos.y - obj.y;
        this._fixtureBasePoint.value = obj.basePoint;
        this._drawDataCanvas();
      }
    })
  }
  _drawDataCanvas() {
    this._zoneCtx.clearRect(0, 0, this._zoneCanvas.width, this._zoneCanvas.height);
    this._drawPoints();
    this._drawZonePolys();
    this._drawfixtures();
  }
  _drawPoints() {
    this._pointData.forEach((point, index) => {
      let [x, y] = point.coordinates.split(',');
      this._zoneCtx.strokeStyle = 'white';
      this._zoneCtx.beginPath();
      this._zoneCtx.rect(x - 6, y - 6, 13, 13);
      this._zoneCtx.rect(x - 1, y - 1, 3, 3);
      this._zoneCtx.stroke();
      this._zoneCtx.strokeStyle = 'purple';
      this._zoneCtx.beginPath();
      this._zoneCtx.rect(x, y, 1, 1);
      this._zoneCtx.rect(x - 5, y - 5, 11, 11);
      this._zoneCtx.stroke();
      if (index == this._selectedPoint) {
        this._zoneCtx.font = "10px Arial";
        this._zoneCtx.fillText(point.name, x, y);
      }
    });
  }
  _drawZonePolys() {
    this._zoneData.forEach((zone, index) => {
      let color = zone.type == 'walk' ? 'blue' : (zone.type == 'negativeWalk' ? 'red' : (zone.type == 'depth' ? 'orange' : (zone.type == 'exit' ? 'yellow' : 'green')));
      let points = zone.points.split('\n').map((pointLine) => {
        let [x,y] = pointLine.split(',');
        return {x, y};
      });
      this._zoneCtx.strokeStyle = color;
      this._zoneCtx.beginPath();
      this._zoneCtx.moveTo(points[0].x, points[0].y);
      if (index == this._selectedZone) {
        this._zoneCtx.font = "10px Arial";
        this._zoneCtx.fillText(zone.name, points[0].x, points[0].y);
      }
      points.forEach((point) => {
        this._zoneCtx.lineTo(point.x, point.y);
        this._zoneCtx.moveTo(point.x, point.y);
      });
      this._zoneCtx.lineTo(points[0].x, points[0].y);
      this._zoneCtx.moveTo(points[0].x, points[0].y);
      this._zoneCtx.closePath();
      this._zoneCtx.stroke();
    });
  }
  _drawfixtures() {
    for (let key in this._fixtureData) {
      let obj = this._fixtureData[key];
      if (key == this._selectedFixture) {
        this._zoneCtx.font = "10px Arial";
        this._zoneCtx.fillText(obj.aliasFor, obj.x, obj.y);
      }
      this._setFixtureBasePointDivPosition(obj);
    }
  }
  _selectFixture(objKey) {
    console.log(objKey);
    this._selectedFixture = objKey;
    let obj = this._fixtureData[objKey];
    this._fixtureAlias.value = obj.aliasFor;
    this._fixtureBasePoint.value = obj.basePoint;
    this._drawDataCanvas();
  }
  _selectPoint(pointIndex) {
    this._selectedPoint = pointIndex;
    let point = this._pointData[pointIndex];
    this._pointName.value = point.name;
    this._pointCoordinates.value = point.coordinates;
    this._drawDataCanvas();
  }
  _selectZone(zoneIndex) {
    this._selectedZone = zoneIndex;
    let zone = this._zoneData[zoneIndex];
    this._zoneName.value = zone.name;
    this._zonePoints.value = zone.points;
    this._zoneType.value = zone.type;
    this._drawDataCanvas();
  }
  _makeColorKey(color) {
    return `${color[0]}.${color[1]}.${color[2]}`;
  }
  _buildFixtureInfoFromFrameTags(json, canvas) {
    // 2 is the frame that begins the Fixture color legend
    // for (let i = 2; i < json.frames.length; i++) {
    //   let colorFrame = json.frames[i].frame;
    //   let color = canvas.getCommittedColorAtPoint(colorFrame.x, colorFrame.y);
    //   let key = this._makeColorKey(color);
    //   let frameData = json.meta.frameTags[i];
    //   let [unprocessedName, basePoint] = frameData.name.split('~');
    //   let [name] = unprocessedName.split('_');
    //   this._fixtureData[key] = {
    //     name,
    //     basePoint: +basePoint,
    //   }
    // }
  }
  _createBackground(json, canvas) {
    let bgFrameIndex = json.meta.frameTags.find((frameTag) => frameTag.name == 'bg').from;
    let bgFrame = json.frames[bgFrameIndex].frame;
    let imageStore = new ImageStore(canvas, { width: bgFrame.w, height: bgFrame.h, x: bgFrame.x, y: bgFrame.y });
    let bgBox = new PictureBox();
    bgBox.drawImage(imageStore);
    this._layer.addChild(bgBox);
  }
  _processImage(json, canvas) {
    canvas.copyCommittedDataToWorkingData();
    this._createBackground(json, canvas);
    this._buildFixtureInfoFromFrameTags(json, canvas);

    let bgFrameIndex = json.meta.frameTags.find((frameTag) => frameTag.name == 'bg').from;
    let objectMaskIndex = json.meta.frameTags.find((frameTag) => frameTag.name == 'masks').from;
    let bgFrame = json.frames[bgFrameIndex].frame;
    let maskFrame = json.frames[objectMaskIndex].frame;

    let maskBounds = {};
    for (let j = 0; j < maskFrame.h; j++) {
      for (let i = 0; i < maskFrame.w; i++) {
        let color = canvas.getCommittedColorAtPoint(i, j, maskFrame.x, maskFrame.y);
        if (color[3] == 0) {
          continue;
        }
        let key = this._makeColorKey(color);
        if (!maskBounds[key]) {
          maskBounds[key] = {
            color: color,
            left: i,
            right: i,
            top: j,
            bottom: j
          };
        } else {
          this._adjustmaskBounds(maskBounds[key], i, j);
        }
      }
    }
    let idName = 0;
    // we output an image where the fixtures are identified by their alpha value.
    // This limits the number of fixtures in the image to 254 (because we can't use the value 255)
    // since we need it to identify the background
    let alphaKey = 254;
    for (let key in maskBounds) {
      let obj = maskBounds[key];
      let width = obj.right - obj.left + 1;
      let height = obj.bottom - obj.top + 1;
      let objCanvas = new Canvas(width, height);
      for (let j = obj.top; j < obj.top + height; j++) {
        for (let i = obj.left; i < obj.left + width; i++) {
          let color = canvas.getCommittedColorAtPoint(i, j, maskFrame.x, maskFrame.y);
          if (color && this._colorsEqual(color, obj.color)) {
            let actualColor = canvas.getCommittedColorAtPoint(i, j, bgFrame.x, bgFrame.y);
            objCanvas.drawPoint(i - obj.left, j - obj.top, actualColor);
            canvas.drawPoint(i + bgFrame.x, j + bgFrame.y, [actualColor[0], actualColor[1], actualColor[2], alphaKey]);
          }
        }
      }
      objCanvas.commitData();
      this._fixtureData[key] = {
        aliasFor: idName++,
        basePoint: 0,
        width,
        height,
        x: obj.left,
        y: obj.top,
        alphaKey: alphaKey--,
      };
      if (this._storedData && this._storedData._fixtureData[key]) {
        let stored = this._storedData._fixtureData[key];
        this._fixtureData[key].aliasFor = stored.name || stored.aliasFor;
        this._fixtureData[key].basePoint = stored.basePoint;
      }
      let imageStore = new ImageStore(objCanvas, { width, height, x: 0, y: 0 });
      let pictureBox = new PictureBox();
      pictureBox.drawImage(imageStore);
      pictureBox.setPosition(obj.left, obj.top);
      pictureBox.getElement().style.outline = '2px solid red';
      pictureBox.setClickable(true);
      let baseDiv = this._makeBaseDiv(this._fixtureData[key]);
      this._fixtureData[key].baseDiv = baseDiv;
      pictureBox.getElement().appendChild(baseDiv);
      this._layer.addChild(pictureBox);
    }
    canvas.commitData();

    this._bgCanvas = document.createElement('canvas');
    this._bgCanvas.width = canvas.width / json.frames.length;
    this._bgCanvas.height = canvas.height;
    let bgCtx = this._bgCanvas.getContext('2d');
    bgCtx.putImageData(canvas.getImageData(), -bgFrame.x, 0);
  }
  _makeBaseDiv(FixtureData) {
    let magnification = LayoutManager.getMagnification();
    let div = document.createElement('div');
    div.style.width = '100%';
    div.style.height = magnification + 'px';
    div.style.backgroundColor = 'blue';
    div.style.position = 'absolute';
    div.style.top = FixtureData.basePoint * magnification + 'px',
    div.style.left = '0px';
    return div;
  }
  _setFixtureBasePointDivPosition(FixtureData) {
    let magnification = LayoutManager.getMagnification();
    FixtureData.baseDiv.style.top = FixtureData.basePoint * magnification + 'px';
  }
  _colorsEqual(col1, col2) {
    return col1[0] == col2[0] && col1[1] == col2[1] && col1[2] == col2[2] && col1[3] == col2[3];
  }
  _adjustmaskBounds(bounds, x, y) {
    bounds.left = x < bounds.left ? x : bounds.left;
    bounds.right = x > bounds.right ? x : bounds.right;
    bounds.top = y < bounds.top ? y : bounds.top;
    bounds.bottom = y > bounds.bottom ? y : bounds.bottom;
  }
  _uploadJSON() {
    let json = {
      name: this._screenName,
      points: {},
      fixtures: {},
      zones: {
        walkAreas: [],
        exits: {},
        general: {},
        depth: [],
      },
    }

    json.fixtures[`${this._screenName}_fixture_background`] = {
      aliasFor: 'background',
      alphaKey: 255,
      width: this._zoneCanvas.width,
      height: this._zoneCanvas.height,
      name: `${this._screenName}_fixture_background`,
      position: { x: 0, y: 0 },
      imageOffset: { x: 0, y: 0 },
      basePoint: 0,
      imageName: this._screenName,
      subImageName: `${this._screenName}_fixture_background`,
    };

    let keyNum = 0;
    for (let key in this._fixtureData) {
      let obj = JSON.parse(JSON.stringify(this._fixtureData[key]));
      obj.aliasFor = obj.aliasFor;
      obj.name = `${this._screenName}_fixture_${keyNum++}`;
      obj.position = {
        x: obj.x,
        y: obj.y
      };
      obj.imageOffset = {
        x: 0,
        y: 0,
      };
      obj.basePoint = obj.basePoint / obj.height;
      obj.imageName = this._screenName,
      obj.subImageName = obj.name;
      delete obj.canvas;
      delete obj.baseDiv;
      json.fixtures[obj.name] = obj;
    }
    this._pointData.forEach((point) => {
      if (point.coordinates == '') {
        return;
      }
      let [x, y] = point.coordinates.split(',');
      json.points[point.name] = { x: +x, y: +y };
    })
    this._zoneData.forEach((zone) => {
      if (zone.points == '') {
        return;
      }
      let zCopy = JSON.parse(JSON.stringify(zone));
      zCopy.points = zone.points.split('\n').map((pointLine) => {
        let [x,y] = pointLine.split(',');
        return {x: +x, y: +y};
      });
      if (zone.type == 'walk' || zone.type == 'negativeWalk') {
        json.zones.walkAreas.push(zCopy);
      }
      if (zone.type == 'depth') {
        json.zones.depth.push(zCopy);
      }
      if (zone.type == 'exit') {
        json.zones.exits[zCopy.name] = zCopy;
      }
      if (zone.type == 'general') {
        json.zones.general[zCopy.name] = zCopy;
      }
    });
    let imageURL = this._bgCanvas.toDataURL();
    FetchManager.sendRoomJSON(this._screenName, json, imageURL);
    //this.downloadTextFile(this._screenName + '.js', stringified);
  }
  downloadTextFile(filename, text) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  _handleMouseMove(data) {
    document.title = `${data.position.x}, ${data.position.y}`
  }
  _loop() {
    this._runActionQueues();
  }
  _makeLayer() {
    this._layer = new TestLayer();
  }
  becameActive() {
    MouseManager.setCursor('pointer');
  }
}

TestScreen.id = "TestScreen";

export default TestScreen;

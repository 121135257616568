import Style from 'style/Style';
import colors from 'style/colors';
import config from 'config';

const INVENTORY_BORDER = Style.darken(colors.MAIN_COLOR, 50);
const SELECTED_BORDER = colors.GOLD;

export default {
  base: {
    backgroundColor: Style.makeBackgroundColor(colors.MAIN_COLOR, true, 5),
    padding: 1,
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1, true),
      Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1),
    ],
    centered: true,
    minWidth: config.layout.screenWidth
  },
  buttons: {
    buttonRow: Style.makeColumnRowStyle('center', 0),
    image: {
      filter: Style.makeImageFilter(colors.MAIN_COLOR)
    },
    inventoryImage: {
    },
    imageActive: {
      filter: Style.makeImageFilter(Style.darken(colors.MAIN_COLOR, 60))
    },
    inventoryImageActive: {
    },
    imageDisabled: {
      filter: Style.makeImageFilter([-175, -175, -175, 255], [-150, -150, -150, 255])
    },
    inventoryImageDisabled: {
      filter: Style.makeImageFilter([-175, -175, -175, 255], [-150, -150, -150, 255])
    },
    box: {
      backgroundColor: Style.makeBackgroundColor(colors.MAIN_COLOR, true, 5),
      //backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
      padding: 2,
      borders: [
        //Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 2, colors.MAIN_COLOR, true, 0, true, 1,),
      ],
      shadow: Style.makeBoxShadow(colors.SHADOW, 1 ,1)
    },
    boxActive: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 60), true, 5),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, true, 1,),
      ],
      shadow: Style.makeBoxShadow(colors.SHADOW, 1 ,1)
    },
    boxDisabled: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 150), true, 5),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1, false),
      ],
      shadow: Style.makeBoxShadow(colors.SHADOW, 1 ,1)
    },
    boxFocused: {
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
        Style.makeBoxBorder(Style.ALL_SIDES, 2, colors.CLEAR),
      ],
    },
    inventoryBox: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK, true, 5),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, INVENTORY_BORDER, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, INVENTORY_BORDER, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, INVENTORY_BORDER, true, 0, true, 1,),
      ],
      shadow: Style.makeBoxShadow(colors.SHADOW, 1 ,1)
    },
    inventoryBoxActive: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK, true, 5),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, true, 1,),
      ],
      shadow: Style.makeBoxShadow(colors.SHADOW, 1 ,1)
    },
    inventoryBoxDisabled: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK, true, 5),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, INVENTORY_BORDER, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, INVENTORY_BORDER, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, INVENTORY_BORDER, true, 0, true, 1,),
     ],
     shadow: Style.makeBoxShadow(colors.SHADOW, 1 ,1)
    },
  }
}

import LayerController from 'controllers/layerControllers/LayerController';
import ScreenController from 'screens/ScreenController';
import InventoryLayer from 'views/layers/InventoryLayer';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';

class InventoryMenuController extends LayerController {
  constructor() {
    super();
    this._cursor = 'pointer';
    this._buttons = [
      'pointer',
      'hand',
      'eye',
      'ok'
    ];
    this._makeItemHandlers();
  }
  _makeLayer() {
    let inventoryState = StateManager.getState('inventory');
    let inventoryItems = inventoryState.getItems();

    let layer = new InventoryLayer(
      inventoryItems,
      this._buttons,
      this._switchCursor.bind(this),
      this._actionButtonClicked.bind(this),
      this._itemClicked.bind(this),
    );
    layer.setDepressedButton(this._cursor);

    this._layer = layer;
  }
  _actionButtonClicked(name) {
    let inventoryState = StateManager.getState('inventory');

    if (name == 'ok') {
      if (this._buttons.indexOf(this._cursor) == -1) {
        // we selected an item and now must set it to the inventory state
        inventoryState.setSelectedItem(this._cursor);
        // now we change selected action button to inventoryItem
        StateManager.getState('menu').setSelected('inventoryItem');
      }
      this.popThisController();
      return;
    }
    this._setCursor(name);
  }
  _setCursor(name) {
    this._cursor = name;
    MouseManager.setCursor(name);
    this._layer.setDepressedButton(name);
  }
  _switchCursor() {
    switch (this._cursor) {
      case 'pointer': {
        this._setCursor('hand');
        break;
      }
      case 'hand': {
        this._setCursor('eye');
        break;
      }
      case 'eye': {
        this._setCursor('pointer');
        break;
      }
      default: {
        this._setCursor('pointer');
      }
    }
  }
  handleMouseMove(data) {
    //
  }
  loop() {
    //
  }
  _updateInventoryItems() {
    let inventoryState = StateManager.getState('inventory');
    let inventoryItems = inventoryState.getItems();

    this._layer.updateInventoryItems(inventoryItems);
  }
  _keyPressed(key) {
    this._layer.keyPressed(key);
  }
  _itemClicked(item) {
    let itemHandler = this._itemHandlers[item.name];
    switch (this._cursor) {
      case 'pointer': {
        this._setCursor(item.name);
        break;
      }
      case 'hand': {
        itemHandler.onHand(item);
        break;
      }
      case 'eye': {
        itemHandler.onEye(item);
        break;
      }
      default: {
        let cursorItem = StateManager.getState('inventory').getItem(this._cursor);
        itemHandler.onMix(item, cursorItem)
      }
    }
  }
  becameActive() {
    MouseManager.setCursor(this._cursor);
  }
  _makeItemHandlers() {
    this._itemHandlers = {
      'money': {
        onHand: (item) => {
          this.showText([`The money rustles in your hand.`]);
        },
        onEye: (item) => {
          this.showText([`You have ${item.amount} dollars.`]);
        },
        onMix: (item, cursorItem) => {
          switch (cursorItem.name) {
            case 'pliers': {
              this.showText([`That did nothing.`]);
              break;
            }
          }
        }
      },
      'pliers': {
        onHand: (item) => {
          this.showText([`The pliers work nicely. You give them a squeeze or two in your hand.`]);
        },
        onEye: (item) => {
          this.showText([`A nice pair of pliers from Walmart.`]);
        },
        onMix: (item, cursorItem) => {
          let invState = StateManager.getState('inventory');
          switch (cursorItem.name) {
            case 'money': {
              invState.setItemAsNotAcquired('pliers');
              this.showText([`The pliers exploded.`]);
              this._updateInventoryItems();
              break;
            }
          }
        }
      }
    }
  }
}

export default InventoryMenuController;

class PriorityQueue {
  constructor(comparator) {
    this._arr = [];
    // comparator should take two values, and should return true or false when comparing the two arguments.
    // Whether it returns true or false should depend on the arguments and also if you want this to be a minheap
    // or a maxheap
    this._comparator = comparator;
  }
  length() {
    return this._arr.length;
  }
  isEmpty() {
    return this._arr.length == 0;
  }
  inOrder() {
    for (let i = 0; i < this._arr.length; i++) {
      if (this._arr[i].getHeapIndex() != i) {
        return false;
      }
    }
    return true;
  }
  insert(n) {
    if (!(n instanceof PriorityQueueItem)) {
      throw Error('Attempting to insert item into PriorityQueue that isn\'t of type PriorityQueueItem.');
    }
    n.setParentGetter(() => this);
    this._arr.push(n);
    n.setHeapIndex(this._arr.length - 1);
    this.adjust(this._arr.length - 1);
  }
  adjust(heapIndex) {
    let arr = this._arr;
    let i = heapIndex;
    while (i != 0) {
      let parentIndex = Math.floor((i - 1)/2);
      // if (!arr[i] || !arr[parentIndex]) {
      //   throw Error(`i: arr[${i}] = ${arr[i]}, parentIndex: arr[${parentIndex}] = ${arr[parentIndex]}, length: ${arr.length}`);
      // }
      if (this._comparator(arr[i].getValue(), arr[parentIndex].getValue())) {
        let temp = arr[parentIndex];
        arr[parentIndex] = arr[i];
        arr[i] = temp;
        arr[i].setHeapIndex(i);
        arr[parentIndex].setHeapIndex(parentIndex);
        i = parentIndex;
      } else {
        break;
      }
    }
  }
  pop() {
    let arr = this._arr;
    let initialLength = arr.length;
    if (arr.length == 0) {
      return null;
    }
    if (arr.length == 1) {
      return arr.pop();
    }
    let top = arr[0];
    top.setHeapIndex(-1);
    let last = arr.pop();
    arr[0] = last;
    arr[0].setHeapIndex(0);
    let i = 0;
    while (true) {
      let leftChild = (i * 2) + 1;
      let rightChild = (i * 2) + 2;
      let higher = i
      if (leftChild < arr.length && this._comparator(arr[leftChild].getValue(), arr[higher].getValue())) {
        higher = leftChild;
      }
      if (rightChild < arr.length && this._comparator(arr[rightChild].getValue(), arr[higher].getValue())) {
        higher = rightChild;
      }
      if (higher == i) {
        break;
      }
      let temp = arr[i];
      arr[i] = arr[higher];
      arr[i].setHeapIndex(i);
      arr[higher] = temp;
      arr[higher].setHeapIndex(higher);
      i = higher;
    }
    return top;
  }
}

class PriorityQueueItem {
  constructor(value) {
    this._heapIndex == -1;
    this._value = value;
    this._parentGetter = null;
  }
  setParentGetter(parentGetter) {
    this._parentGetter = parentGetter;
  }
  setValue(value) {
    if (value != this._value) {
      this._value = value;
      if (this._parentGetter) {
        this._parentGetter().adjust(this._heapIndex);
      }
    }
  }
  getValue() {
    return this._value;
  }
  setHeapIndex(index) {
    this._heapIndex = index;
  }
  getHeapIndex() {
    return this._heapIndex;
  }
}

export {
  PriorityQueue,
  PriorityQueueItem
}

import Style from 'style/Style';
import colors from 'style/colors';

export default {
  text: {
    fontName: '300',
    lineHeight: 12,
    maxWidth: 230,
    padding: 0,
    centered: true,
    //lineHeight: 20,
    color: Style.darken(colors.MAIN_COLOR, 130),
    shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 5)),
  },
  base: {
    backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 3, colors.MAIN_COLOR, true, 5, true, 1, false),
    ],
    shadow: Style.makeBoxShadow(colors.SHADOW, 1, 1),
    padding: 10,
  },
}

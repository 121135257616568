import PictureBox from 'views/canvasBoxes/PictureBox';
import Box from 'views/canvasBoxes/Box';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import CompositeBox from 'views/compositeBoxes/CompositeBox';
import focusableMixin from 'mixins/focusableMixin';
import MouseManager from 'managers/MouseManager';

class PictureButton extends CompositeBox {
  constructor(name, onClick, imageStore, normalImageOptions, normalBoxOptions, activeImageOptions, activeBoxOptions, disabledImageOptions, disabledBoxOptions, focusBoxOptions) {
    super();
    focusableMixin(this);

    this._name = name;

    this._onClick = onClick;

    this._active = false;
    this._disabled = false;
    this._depressed = false;

    this._imageStore = imageStore;

    this._normalImageOptions = normalImageOptions;
    this._normalBoxOptions = normalBoxOptions;
    this._focusBoxOptions = focusBoxOptions;
    this._activeImageOptions = activeImageOptions;
    this._activeBoxOptions = activeBoxOptions;
    this._disabledImageOptions = disabledImageOptions;
    this._disabledBoxOptions = disabledBoxOptions;

    this._picture = new PictureBox();
    this._picture.drawImage(this._imageStore, normalImageOptions);
    this._base = new Box(this._picture);

    let pictureDims = this._picture.getDimensions();
    this._focusEmptyBox = new EmptyBox(pictureDims.w, pictureDims.h);
    this._focusBox = new Box(this._focusEmptyBox);
    this._base.addChild(this._focusBox);

    this._base.setClickable(true);
    this.draw();

    if (activeImageOptions) {
      this._base.addListener(
        'mousedown',
        (e) =>{
          if (!MouseManager.eventIsRightClick(e)) {
            this.click()
          }
        },
      );
    } else {
      this._base.addListener('mousedown', () => onClick(name));
    }
  }
  getName() {
    return this._name;
  }
  setImage(image) {
    this._imageStore = image;
  }
  setOnClick(callback) {
    this._onClick = callback;
  }
  setDepressed(bool) {
    this._depressed = bool;
    this.draw();
  }
  setDisabled(value) {
    this._disabled = value;
    this.draw();
  }
  destroy() {
    this._focusEmptyBox.destroy();
    this._focusBox.destroy();
    this._picture.destroy();
    this._base.destroy();
  }
  click() {
    if (this._disabled) {
      return;
    }
    this._active = true;
    this.draw();
    setTimeout(() => {
      this._active = false;
      this.draw();
      this._onClick(this._name);
    }, 0);
  }
  draw() {
    if (this._disabled) {
      this._drawDisabled();
    } else if (this._depressed) {
      this._drawActive();
    } else if (this._active) {
      this._drawActive();
    } else {
      this._drawNormal();
    }
    if (this.isFocused()) {
      this._drawFocus();
    } else {
      this._drawNonFocus();
    }
  }
  _drawNormal() {
    this._picture.drawImage(this._imageStore, this._normalImageOptions);
    this._base.draw(this._normalBoxOptions)
  }
  _drawFocus() {
    this._focusBox.draw(this._focusBoxOptions);
  }
  _drawNonFocus() {
    this._focusBox.draw({});
  }
  _drawActive() {
    this._picture.drawImage(this._imageStore, this._activeImageOptions);
    this._base.draw(this._activeBoxOptions);
  }
  _drawDisabled() {
    this._picture.drawImage(this._imageStore, this._disabledImageOptions);
    this._base.draw(this._disabledBoxOptions);
  }
}

export default PictureButton;

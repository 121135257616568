import TimeManager from 'managers/TimeManager'
import ImageManager from 'managers/ImageManager';
import StateManager from 'managers/StateManager';

class GameObject {
  constructor(getParent, state) {
    this._getParent = getParent;
    this._state = {
      name: '',
      actionQueue: [],
      position: {
        x: 0,
        y: 0,
      },
      // imageOffset should be two percentages. The percentage is of the width
      // and height of the image
      imageOffset: {
        x: 0,
        y: 0,
      },
      moveSpeed: 4,
      // basepoint should be a percentage of the height
      basePoint: 0,
      // absoluteBasePoint should be an absolute screen position (if set)
      absoluteBasePoint: null,
      // scale should be a number between 0 and 1
      scale: 1,
      isScalable: false,
      isDrawable: true,
      frame: 0,
      filter: null,
      imageName: null,
      subImageName: null,
      aliasFor: this.getDefaultAlias(),
      currentZones: {},
    };
    if (state) {
      Object.assign(this._state, state);
    } else {
      this._setDefaultState();
    }
    this._pictureBox = null;
    this._hasChanged = true;
  }
  getDefaultAlias() {
    return null;
  }
  getState() {
    return this._state;
  }
  setAliasFor(aliasFor) {
    this._state.aliasFor = aliasFor;
  }
  setAsInsideGeneralZone(zoneName) {
    if (!this._state.currentZones[zoneName]) {
      this._state.currentZones[zoneName] = true;
      this.handleGeneralZoneEntry(zoneName);
    }
  }
  setAsOutsideGeneralZone(zoneName) {
    if (this._state.currentZones[zoneName]) {
      this._state.currentZones[zoneName] = false;
      this.handleGeneralZoneExit(zoneName);
    }
  }
  setBasePoint(bp) {
    this._state.basePoint = bp;
  }
  handleGeneralZoneEntry(zoneName) {
    // should be overridden
  }
  handleGeneralZoneExit(zoneName) {
    // should be overridden
  }
  isClicked(point) {
    let dims = this._pictureBox.getDimensions();
    let pos = this._pictureBox.getPosition();
    if (point.x >= pos.x && point.x < pos.x + dims.w - 1) {
      if (point.y >= pos.y && point.y < pos.y + dims.h - 1) {
        if (this._pictureBox.hasColorAtPoint(point.x - pos.x, point.y - pos.y)) {
          return true;
        }
      }
    }
    return false;
  }
  isAtPoint(pt) {
    let pos = this._state.position;
    return pos.x == pt.x && pos.y == pt.y;
  }
  isWalking() {
    let aq = this._state.actionQueue;
    return aq.length > 0 && aq[0].type == 'walk';
  }
  handleClick(selected) {
    let handled = false;
    if (selected == 'inventoryItem') {
      let item = StateManager.getState('inventory').getSelectedItem();
      handled = this.handleInventoryClick(item);
    } else {
      handled = this.handleActionClick(selected);
    }
    if (!handled) {
      this.showText(this.getRandomClickResponse());
    }
  }
  handleActionClick() {
    // should be overridden if game object does stuff when clicked
    return false;
  }
  handleInventoryClick(type) {
    // should be overriden if game object does stuff when clicked by inventory item
    return false;
  }
  getRandomClickResponse() {
    let responses = [
      "I don't think that would be a good idea.",
      "That is not the correct action.",
      "Why would we do that?",
      "We should try something else."
    ];
    let randomIndex = Math.floor(Math.random() * responses.length);
    return [responses[randomIndex]];
  }
  setZIndex(zIndex) {
    if (!this._pictureBox) {
      throw Error("trying to set z index on game object with no picture box");
    }
    this._pictureBox.getElement().style.zIndex = zIndex;
  }
  // called by game layer
  setPictureBox(pictureBox) {
    this._pictureBox = pictureBox;
  }
  isScalable() {
    return this._state.isScalable;
  }
  isDrawable() {
    return this._state.isDrawable;
  }
  isAlias() {
    return this._state.aliasFor != null;
  }
  getAlias() {
    return this._state.aliasFor;
  }
  isMoving() {
    let queue = this._state.actionQueue;
    if (queue.length == 0) {
      return false;
    }
    switch (queue[0].type) {
      case 'walk': {
        return true;
      }
      case 'walkFree': {
        return true;
      }
      case 'glide': {
        return true;
      }
      case 'glideFree': {
        return true;
      }
    }
    return false;
  }
  getRequiredImages() {
    // should be overrided. Called on screen load so that the images can
    // be loaded
    return [];
  }
  advanceAnimation() {
    if (!this._imageStore) {
      this._grabImageStore();
    }
    if (!this._imageStore.isAnimation()) {
      return;
    }
    this._state.frame++;
    if (this._state.frame >= this._imageStore.getTotalFrames()) {
      if (this._imageStore.isRepeating()) {
        this._state.frame = 0;
        this._hasChanged = true;
      } else {
        this._state.frame--;
      }
    } else {
      this._hasChanged = true;
    }
  }
  getBasePoint() {
    if (!this._imageStore) {
      this._grabImageStore();
    }
    let basePoint = null;
    if (this._state.absoluteBasePoint !== null) {
      return this._state.absoluteBasePoint;
    }
    let dims = this._imageStore.getDimensions();
    let drawingPosition = {
      x: this._state.position.x - dims.w * this._state.imageOffset.x * this._state.scale,
      y: this._state.position.y - dims.h * this._state.imageOffset.y * this._state.scale,
    }
    return drawingPosition.y + dims.h * this._state.scale * this._state.basePoint;
  }
  loop() {
    this._runActionQueue();
    if (this.hasChanged()) {
      this.draw();
    }
    this.advanceAnimation();
  }
  draw() {
    if (!this._imageStore) {
      this._grabImageStore();
    }
    let dims = this._imageStore.getDimensions();
    let drawingPosition = {
      x: Math.round(this._state.position.x - dims.w * this._state.imageOffset.x * this._state.scale),
      y: Math.round(this._state.position.y - dims.h * this._state.imageOffset.y * this._state.scale),
    }
    let imagePosition = this._imageStore.getPosition();
    let xOffset = imagePosition.x;
    if (this._imageStore.isAnimation()) {
      if (this._imageStore.isReversed()) {
        xOffset -= dims.w * this._state.frame;
      } else {
        xOffset += dims.w * this._state.frame;
      }
    }
    this._pictureBox.drawImage(this._imageStore, {
      xOffset,
      yOffset: imagePosition.y,
      scale: this._state.scale,
      filter1: this._state.filter,
    });
    this._pictureBox.setPosition(drawingPosition.x, drawingPosition.y);
    this._hasChanged = false;
  }
  // needed to know if should be redrawn. The default is true because
  // you always need to be drawn initially
  hasChanged() {
    return this._hasChanged;
  }
  setScale(scale) {
    this._state.scale = scale;
    this._hasChanged = true;
  }
  setState(state) {
    this._state = state;
    this._hasChanged = true;
  }
  setFilter(filter) {
    this._state.filter = filter;
    this._hasChanged = true;
  }
  _setDefaultState() {
    // should be overriden
  }
  getObject(name) {
    return this._getParent().getObject(name);
  }
  getPoint(name) {
    return this._getParent().getPoint(name);
  }
  setImage(imageName, subImageName) {
    if (this._state.imageName == imageName && this._state.subImageName == subImageName) {
      return;
    }
    this._state.imageName = imageName;
    this._state.subImageName = subImageName;
    this._state.frame = 0;
    this._grabImageStore();
    this._hasChanged = true;
  }
  _grabImageStore() {
    if (this._state.imageName) {
      this._imageStore = ImageManager.getImage(this._state.imageName, this._state.subImageName);
    } else {
      this._imageStore = null;
    }
  }
  setPosition(x, y) {
    if (this._state.position.x == x && this._state.position.y == y) {
      return;
    }
    this._state.position = {x, y};
    this._hasChanged = true;
  }
  showText(lines) {
    this._getParent().showText(lines);
  }
  getPosition() {
    return this._state.position;
  }
  pushToActionQueue(action) {
    this._state.actionQueue.push(action);
  }
  move(point) {
    this.setPosition(point.x, point.y);
    this._hasChanged = true;
  }
  face(direction) {
    this.setImage(this._state.imageName, direction);
  }
  clear() {
    this._state.actionQueue = [];
  }
  _runActionQueue() {
    let actionQueue = this._state.actionQueue;
    if (actionQueue.length == 0) {
      return;
    }
    let action = actionQueue[0];
    switch (action.type) {
      case 'sleep': {
        let loopTime = TimeManager.getLoopTime();
        action.time -= loopTime;
        if (action.time <= 0) {
          actionQueue.shift();
        }
        break;
      }
      case 'face': {
        this.setImage(this._state.imageName, action.direction);
        actionQueue.shift();
        break;
      }
      case 'move': {
        this.setPosition(action.point.x, action.point.y);
        actionQueue.shift();
        break;
      }
      case 'walk': {
        if (action.points == null) {
          action.points = this._getParent().getFloorData().getPath(this._state.position, action.point);
        }
        this._advanceWalk(action);
        if (action.points.length == 0) {
          if (action.callback) {
            if (action.callback.owner == 'screen') {
              this._getParent()[action.callback.method]();
            } else {
              this.getObject(action.callback.owner)[action.callback.method]();
            }
          }
          actionQueue.shift();
        }
        break;
      }
      case 'walkFree': {
        if (action.points == null) {
          action.points = this._getParent().getFloorData().getPath(this._state.position, action.point, false);
        }
        this._advanceWalk(action);
        if (action.points.length == 0) {
          if (action.callback) {
            if (action.callback.owner == 'screen') {
              this._getParent()[action.callback.method]();
            } else {
              this.getObject(action.callback.owner)[action.callback.method]();
            }
          }
          actionQueue.shift();
        }
        break;
      }
      // glide is like walk but without adjusting the animation
      case 'glide': {
        if (action.points == null) {
          action.points = this._getParent().getFloorData().getPath(this._state.position, action.point);
        }
        this._advanceGlide(action);
        if (action.points.length == 0) {
          if (action.callback) {
            if (action.callback.owner == 'screen') {
              this._getParent()[action.callback.method]();
            } else {
              this.getObject(action.callback.owner)[action.callback.method]();
            }
          }
          actionQueue.shift();
        }
        break;
      }
      case 'glideFree': {
        if (action.points == null) {
          action.points = this._getParent().getFloorData().getPath(this._state.position, action.point, false);
        }
        this._advanceGlide(action);
        if (action.points.length == 0) {
          if (action.callback) {
            if (action.callback.owner == 'screen') {
              this._getParent()[action.callback.method]();
            } else {
              this.getObject(action.callback.owner)[action.callback.method]();
            }
          }
          actionQueue.shift();
        }
        break;
      }
      case 'method': {
        if (action.owner == 'screen') {
          let screen = this._getParent();
          screen[action.method].apply(screen, action.arguments);
        } else {
          let object = this.getObject(action.owner);
          object[action.method].apply(object, action.arguments);
        }
        actionQueue.shift();
        break;
      }
    }
  }
  _advanceGlide(action) {
    let glidingPoints = action.points;
    this._hasChanged = true;
    let advanceNumber = Math.round(this._state.moveSpeed * this._state.scale);
    if (glidingPoints.length == 1) {
      glidingPoints.splice(0, 1);
    } else if (glidingPoints.length <= advanceNumber) {
      glidingPoints.splice(0, glidingPoints.length - 1);
    } else {
      glidingPoints.splice(0, advanceNumber);
    }
    if (glidingPoints.length == 0) {
      return;
    }
    let pt1 = glidingPoints[0];
    this.setPosition(pt1.x, pt1.y);
    if (action.thenFace) {
      this.setImage(this._state.imageName, action.thenFace);
      return;
    }
  }
  _advanceWalk(action) {
    let walkingPoints = action.points;
    this._hasChanged = true;
    let advanceNumber = Math.round(this._state.moveSpeed * this._state.scale);
    if (walkingPoints.length == 1) {
      walkingPoints.splice(0, 1);
    } else if (walkingPoints.length <= advanceNumber) {
      walkingPoints.splice(0, walkingPoints.length - 1);
    } else {
      walkingPoints.splice(0, advanceNumber);
    }
    if (walkingPoints.length == 0) {
      if (action.thenFace) {
        this.setImage(this._state.imageName, action.thenFace);
        return;
      }
      switch (this._state.subImageName) {
        case 'walk_r': {
          this.setImage(this._state.imageName, 'stop_r');
          break;
        }
        case 'walk_l': {
          this.setImage(this._state.imageName, 'stop_l');
          break;
        }
        case 'walk_d': {
          this.setImage(this._state.imageName, 'stop_d');
          break;
        }
        case 'walk_u': {
          this.setImage(this._state.imageName, 'stop_u');
          break;
        }
        case 'walk_rd': {
          this.setImage(this._state.imageName, 'stop_rd');
          break;
        }
        case 'walk_ld': {
          this.setImage(this._state.imageName, 'stop_ld');
          break;
        }
        case 'walk_lu': {
          this.setImage(this._state.imageName, 'stop_lu');
          break;
        }
        case 'walk_ru': {
          this.setImage(this._state.imageName, 'stop_ru');
          break;
        }
      }
      return;
    }
    let pt1 = walkingPoints[0];
    this.setPosition(pt1.x, pt1.y);

    let pt2;
    if (walkingPoints.length == 1) {
      return;
    }
    if (walkingPoints.length < 10) {
      pt2 = walkingPoints[walkingPoints.length - 1];
    } else {
      pt2 = walkingPoints[9];
    }
    let degrees = Math.atan2(pt2.y - pt1.y, pt2.x - pt1.x) * 180 / Math.PI + 180

    let angleCodes = {
      'walk_lu': 30,
      'walk_u': 60,
      'walk_ru': 120,
      'walk_r': 150,
      'walk_rd': 210,
      'walk_d': 240,
      'walk_ld': 300,
      'walk_l': 330
    };
    let walkingDirection = 'walk_l';
    for (let direction in angleCodes) {
      if (degrees >= angleCodes[direction]) {
        walkingDirection = direction;
      }
    }
    this.setImage(this._state.imageName, walkingDirection);
  }
}

export default GameObject;

import config from 'config';

export default function(obj) {
  let mixin = {
    // this sets up the event listener on the document body
    setupEventListener: function() {
      this.gameEventListener = (e) => {
        if (this.eventHandler) {
          this.eventHandler(e.detail);
        }
      };
      // we don't use the LayoutManager to grab the screen because importing it was causing timing issues
      document.getElementById(config.layout.screenId).addEventListener('GameEvent', this.gameEventListener);
    },
    // this removes the event listener
    removeEventListener: function() {
      // we don't use the LayoutManager to grab the screen because importing it was causing timing issues
      document.getElementById(config.layout.screenId).removeEventListener('GameEvent', this.gameEventListener);
    },
    // this fires the event
    fireEvent: function(data) {
      if (!data.type) {
        console.error('Data missing "type" in fireEvent().');
        throw Error();
      }
      let event = new CustomEvent('GameEvent', { detail: data });
      document.getElementById(config.layout.screenId).dispatchEvent(event);
    }
  };
  Object.assign(obj, mixin);
  obj.setupEventListener();
}

import Style from 'style/Style';
import colors from 'style/colors';

const BUTTON_FONT = '300';
const BUTTON_WIDTH = 200;

export default {
  face: {
    mainColumn: Style.makeColumnRowStyle('center', 0),
    label: {
      fontName: 'main',
      padding: 2,
      centered: true,
      //lineHeight: 20,
      color: colors.WHITE,
    },
    labelBox: {
      centered: true,
      minWidth: 98,
      maxWidth: 98,
      backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      borders: [
      ],
    },
    faceBox: {
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    },
    faceBase: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
      shadow: Style.makeBoxShadow(colors.SHADOW, 1, 1),
    }
  },
  questions: {
    questionColumn: Style.makeColumnRowStyle('start', 1),
    text: {
      fontName: BUTTON_FONT,
      maxWidth: BUTTON_WIDTH,
      minWidth: BUTTON_WIDTH,
      padding: 0,
      centered: false,
      color: colors.BLACK,
    },
    textHover: {
      fontName: BUTTON_FONT,
      maxWidth: BUTTON_WIDTH,
      minWidth: BUTTON_WIDTH,
      padding: 0,
      centered: false,
      color: colors.BLACK,
    },
    textActive: {
      fontName: BUTTON_FONT,
      maxWidth: BUTTON_WIDTH,
      minWidth: BUTTON_WIDTH,
      padding: 0,
      centered: false,
      color: colors.BLACK,
    },
    box: {
      padding: 2,
      backgroundColor: Style.makeBackgroundColor(colors.WHITE),
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    },
    boxHover: {
      padding: 1,
      backgroundColor: Style.makeBackgroundColor(colors.WHITE),
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    },
    boxActive: {
      backgroundColor: Style.makeBackgroundColor(colors.WHITE),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    },
  },
  mainRow: Style.makeColumnRowStyle('center', 2),
  base: {
  },
}

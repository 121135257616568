import LayerController from 'controllers/layerControllers/LayerController';
import MouseManager from 'managers/MouseManager';
import KeyManager from 'managers/KeyManager';
import StateManager from 'managers/StateManager';
import TimeManager from 'managers/TimeManager';
import PolygonManager from 'managers/PolygonManager';
import ImageManager from 'managers/ImageManager';
import SaveManager from 'managers/SaveManager';
import FixtureObject from 'classes/gameObjects/FixtureObject';
import FloorData from 'classes/FloorData';
import Actions from 'classes/Actions';

import config from 'config';
/**
* If a game is made up of different screens, they are controlled
* by the screen controller. The gamecontroller should load different
* screen controllers as screens change.
*
* The screen controllers control the sub layers, and the root layer of the screen
*/
class ScreenController extends LayerController {
  constructor(entryName, loadData, testing = false) {
    super();
    SaveManager.setCurrentScreen(this);
    MouseManager.setReceiver(this);
    KeyManager.setReceiver(this);
    if (testing) {
      return;
    }
    this._floorData = null;
    this._isWaiting = false;
    this._loadedObjectsState = null;
    this._gameObjects = {};
    this._drawableObjectsArray = [];
    this._setWaiting(true);
    this._init(entryName, loadData);
    // each sublcass of screen controller will have to trigger their own 'pushLayer'
  }
  _init(entryName, loadData) {
    this._state = this._getDefaultState(entryName);
    this._setLoadData(loadData);
    this._setScreenData(this._getScreenData());
    ImageManager.loadScreen(this._screenData.name, this._screenData.fixtures)
    .then(() => {
      this._initializeFixtures();
      this._initializeNonFixtures();
      return ImageManager.loadImages(this._getRequiredImages());
    })
    .then(() => {
      this.pushLayer();
      if (!this._loadedObjectsState) {
        this._setInitialState();
      }
      // should call this so the objects are drawn at the correct depth
      this._setScales();
      this._draw();
      this._layer.unhide();
      this._loaded = true;
    });
  }
  // you can override this if needed
  _getDefaultState(entryName) {
    return {
      actionQueues: {
        main: [],
        filter: [],
      },
      enteredAt: entryName || this._getDefaultEntry(),
    };
  }
  _getDefaultEntry() {
    throw Error("Why hasn't this subclass of ScreenController overridden _getDefaultEntry()? Should return a string")
  }
  _getScreenData() {
    throw Error("Why hasn't this subclass of ScreenController overridden _getScreenData()? Should return an object")
  }
  _setLoadData(loadData) {
    if (loadData) {
      this._state = loadData.centralState;
      this._loadedObjectsState = loadData.objectsState;
      this._setWaiting(loadData.isWaiting);
    }
  }
  _setInitialState() {
    throw Error("Why hasn't this subclass of ScreenController overridden _setInitialState()?")
  }
  getState() {
    let objectsState = {};
    for (let key in this._gameObjects) {
      objectsState[key] = this._gameObjects[key].getState();
    }
    return {
      centralState: this._state,
      isWaiting: this._isWaiting,
      objectsState,
    }
  }
  _leftClicked(position) {
    let selected = StateManager.getState('menu').getSelected();
    if (!selected) {
      return;
    }
    if (selected == 'walk') {
      this.getObject('character').clear();
      this.getObject('character').pushToActionQueue(Actions.walk(position));
    } else {
      let clickedObject = this._getClickedGameObject(position);
      clickedObject.handleClick(selected);
    }
  }
  getPoint(name) {
    return this._screenData.points[name];
  }
  getObject(name) {
    return this._gameObjects[name];
  }
  _getRequiredImages() {
    let required = this._getRequiredFaceImages();
    this._drawableObjectsArray.forEach((drawable) => {
      required = required.concat(drawable.getRequiredImages());
    })
    return required;
  }
  _getRequiredFaceImages() {
    // should be overridden by subclasses of screencontroller
    return [];
  }
  _setWaiting(shouldWait) {
    StateManager.getState('menu').setWaiting(shouldWait);
    MouseManager.setCursor();
    this._isWaiting = shouldWait;
  }
  _switchCursor() {
    StateManager.getState('menu').setSelectedToNext();
    MouseManager.setCursor();
  }
  _initializeFixtures() {
    for (let fixtureName in this._screenData.fixtures) {
      let fixtureData = this._screenData.fixtures[fixtureName];
      this._addGameObject(fixtureName, new FixtureObject(() => this, fixtureData));
    }
  }
  setFilter(filter) {
    this._drawableObjectsArray.forEach((drawable) => {
      drawable.setFilter(filter);
    });
  }
  getFloorData() {
    return this._floorData;
  }
  _addGameObject(name, object) {
    this._gameObjects[name] = object;
    if (object.isDrawable()) {
      this._drawableObjectsArray.push(object);
    }
    if (this._loadedObjectsState) {
      object.setState(this._loadedObjectsState[name]);
    }
  }
  _draw() {
    this._drawableObjectsArray.sort((a, b) => {
      return a.getBasePoint() - b.getBasePoint();
    });
    this._drawableObjectsArray.forEach((gObj, index) => {
      gObj.setZIndex(index);
      gObj.loop();
    })
  }
  _initializeNonFixtures() {
    // should be overridden by subclasses
  }
  _loop() {
    // should be overridden by subclasses. This loop controls the game objects
  }
  _clearActionQueue(name) {
    this._actionsQueues[name] = [];
  }
  _setActionQueue(name, actions) {
    this._state.actionQueues[name] = actions;
  }
  _runActionQueues() {
    for (let actionQueueName in this._state.actionQueues) {
      let actionQueue = this._state.actionQueues[actionQueueName];
      if (actionQueue.length == 0) {
        continue;
      }
      let action = actionQueue[0];
      if (action.type == 'multi') {
        action.actions = action.actions.filter((subAction) => {
          return !this._executeAction(subAction);
        });
        if (action.actions.length == 0) {
          actionQueue.shift();
        }
      } else {
        if (this._executeAction(action)) {
          actionQueue.shift();
        }
      }
    }
  }
  // returns true if action is complete, false if not
  _executeAction(action) {
    switch (action.type) {
      case 'wait': {
        this._setWaiting(true);
        return true;
        break;
      }
      case 'end wait': {
        this._setWaiting(false);
        return true;
        break;
      }
      case 'sleep': {
        let loopTime = TimeManager.getLoopTime();
        action.time -= loopTime;
        return (action.time <= 0);
        break;
      }
      case 'blocker': {
        if (action.owner == 'screen') {
          let screen = this;
          return screen[action.gate].apply(screen, action.arguments);
          break;
        } else {
          let object = this.getObject(action.owner);
          return object[action.gate].apply(object, action.arguments);
          break;
        }
      }
      case 'method': {
        if (action.owner == 'screen') {
          let screen = this;
          screen[action.method].apply(screen, action.arguments);
        } else {
          let object = this.getObject(action.owner);
          object[action.method].apply(object, action.arguments);
        }
        return true;
        break;
      }
      case 'text': {
        this.showText(action.lines);
        return true;
        break;
      }
      case 'pushToObjectQueue': {
        let gameObject = this.getObject(action.objectName);
        gameObject.pushToActionQueue(action.action);
        return true;
        break;
      }
      case 'filter': {
        this.setFilter(action.filter);
        return true;
        break;
      }
      case 'speak': {
        this.showDialogue(action.character, action.faceName, action.lines);
        return true;
        break;
      }
      case 'converse': {
        if (action.conversationStack.length == 0) {
          return true;
        } else {
          this.showConversation(action.conversationStack);
          return false;
        }
        break;
      }
    }
  }
  _setScreenName(name) {
    this._screenName = name;
  }
  _setScreenData(data) {
    this._screenData = data;
    this._buildFloorData();
  }
  _setScales() {
    this._drawableObjectsArray.forEach((drawable) => {
      if (drawable.hasChanged() && drawable.isScalable()) {
        let pt = drawable.getPosition();
        this._screenData.zones.depth.forEach((depthZone) => {
          if (PolygonManager.pointIsInPolygon(pt, depthZone.points)) {
            drawable.setScale(+depthZone.name);
          }
        })
      }
    });
  }
  _checkGeneralZones() {
    let generalZones = this._screenData.zones.general;
    for (let generalName in generalZones) {
      let zone = generalZones[generalName];
      this._drawableObjectsArray.forEach((object) => {
        let position = object.getPosition();
        if (PolygonManager.pointIsInPolygon(position, zone.points)) {
          object.setAsInsideGeneralZone(zone.name);
        } else {
          object.setAsOutsideGeneralZone(zone.name);
        }
      });
    }
  }
  _checkExits() {
    let exits = this._screenData.zones.exits;
    if (exits.length == 0) {
      return;
    }
    let character = this.getObject('character');
    if (!character) {
      return;
    }
    let charPosition = character.getPosition();
    for (let exitName in exits) {
      if (PolygonManager.pointIsInPolygon(charPosition, exits[exitName].points)) {
        this._handleExit(exitName);
        return;
      }
    }
  }
  _handleExit(exitName) {
    // this should be overridden
  }
  _buildFloorData() {
    this._floorData = new FloorData(config.layout.screenWidth, config.layout.screenHeight, this._screenData.zones.walkAreas);
  }
  _getClickedGameObject(position) {
    let arr = this._drawableObjectsArray;
    for (let i = arr.length - 1; i >= 0; i--) {
      let object = arr[i];
      if (object.isClicked(position)) {
        if (object.isAlias()) {
          return this._gameObjects[object.getAlias()];
        } else {
          return object;
        }
      }
    }
  }
  _handleMouseMove(data) {
    if (!this._layer) {
      return;
    } else {
      this._layer.handleMouseMove(data);
    }
  }
  destroy() {
    // more should be added to this in subclasses
    while (this._layerControllerStack.length > 0) {
      this.popController();
    }
    this._layer.destroy();
  }
  becameActive() {
    MouseManager.setCursor();
  }
}


export default ScreenController;

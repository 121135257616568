import Actions from 'classes/Actions';
import Speech from 'classes/Speech';
import StateManager from 'managers/StateManager';
import ConversationLine from 'classes/ConversationLine';

export default {
  getFace: function() {
    return ['Paul', 'paulFace'];
  },
  getEmotion: function() {
    return '';
  },
  questions: {
    "Hi there. You must be Paul.": new ConversationLine(
               // getActions function
               () => {
                 return [
                   Actions.speak('Paul', 'paulFace', [new Speech(`Yes that's me. Nice to meet you.`),]),
                 ];
               },
               // shouldShow function
               () => {
                 return !StateManager.getState('paul').hasIntroduced();
               },
               // changeState function
               () => {
                 StateManager.getState('paul').setHasIntroduced(true);
               },
             ),
    "Why don't you come into the office and have a seat.": new ConversationLine(
              () => {
                return [
                  Actions.speak('Paul', 'paulFace', [new Speech("Sure thing.")]),
                  Actions.popConversation()
                ]
              },
              () => {
                return StateManager.getState('paul').hasIntroduced();
              },
              () => {
                StateManager.getState('paul').setHasBegunInterview(true);
              },
            ),
    "Sorry. Can you wait a second. I'm not ready.": new ConversationLine(
              () => {
                return [
                  Actions.speak('Paul', 'paulFace', [new Speech("No problem.")]),
                  Actions.popConversation(),
                ]
              },
              () => true,
              () => {},
            ),
  }
}

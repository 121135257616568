import LayoutManager from 'managers/LayoutManager';
import eventMixin from 'mixins/eventMixin';
import Destroyable from 'classes/Destroyable';

class DivCanvas extends Destroyable {
  constructor(width, height) {
    super();

    eventMixin(this);

    this.width = 0;
    this.height = 0;
    this.x = 0;
    this.y = 0;

    this.wrapper = document.createElement('div');
    this.wrapper.style.position = 'absolute';
    this.wrapper.style.pointerEvents = 'none';
    this.wrapper.style.userSelect = 'none';
    this.wrapper.style.webkitUserSelect = 'none';
    this.wrapper.style.mozUserSelect = 'none';

    this.setPosition(this.x, this.y);

    this.setDimensions(width, height);
  }
  getElement() {
    return this.wrapper;
  }
  getDimensions() {
    return { w: this.width, h: this.height };
  }
  getPosition() {
    return { x: this.x, y: this.y };
  }
  setDimensions(width, height) {
    let magnification = LayoutManager.getMagnification();
    if (width != this.width || height != this.height) {
      this.width = width;
      this.height = height;
    }
    this.wrapper.style.width = width * magnification + 'px';
    this.wrapper.style.height = height * magnification + 'px';
  }
  setPosition(x, y) {
    let magnification = LayoutManager.getMagnification();
    this.x = x;
    this.y = y;
    this.wrapper.style.left = x * magnification + 'px';
    this.wrapper.style.top = y * magnification + 'px';
  }
  setZIndex(zIndex) {
    this.wrapper.style.zIndex = zIndex;
  }
  addChild(childCanvas) {
    this.wrapper.appendChild(childCanvas.getElement());
  }
  addListener(type, callback) {
    this.wrapper.addEventListener(type, callback);
  }
  setClickable(bool) {
    this.wrapper.style.pointerEvents = bool ? 'all' : 'none';
  }
  eventHandler(detail) {
    if (detail.type == LayoutManager.MAGNIFY) {
      this.setDimensions(this.width, this.height);
      this.setPosition(this.x, this.y);
    }
  }
  destroy() {
    this.removeEventListener();
    if (this.wrapper.parentElement) {
      this.wrapper.parentElement.removeChild(this.wrapper);
    }
    this.wrapper = null;
  }
}

export default DivCanvas;

export default{
  "src": "/images/faces/paulFace.png",
  "spriteInfo": {
    "bg": {
      "width": 96,
      "height": 96,
      "x": 0,
      "y": 0,
      "animation": false,
      "repeat": false,
      "frames": 1
    },
    "bg_naked": {
      "width": 96,
      "height": 96,
      "x": 96,
      "y": 0,
      "animation": false,
      "repeat": false,
      "frames": 1
    },
    "mouth": {
      "width": 37,
      "height": 31,
      "x": 192,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 10
    },
    "eyes": {
      "width": 46,
      "height": 23,
      "x": 562,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 2
    },
    "eyes_angry": {
      "width": 46,
      "height": 23,
      "x": 654,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 2
    },
    "eyes_scared": {
      "width": 46,
      "height": 23,
      "x": 746,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 2
    }
  }
}
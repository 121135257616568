import Style from 'style/Style';
import colors from 'style/colors';


export default {
  mainColumn: Style.makeColumnRowStyle('center', 1),
  base: {
    backgroundColor: Style.makeBackgroundColor(colors.GREY, false, 10),
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 3, [220, 220, 220, 255], false, 15, true, 1, false),
    ],
    shadow: Style.makeBoxShadow([0, 0, 0, 255], 1, 1),
    padding: 5,
  },
  deathImage: {
    scale: 1,
  },
  explanationText: {
    fontName: 'main',
    maxWidth: 150,
    minWidth: 150,
    padding: 0,
    centered: true,
    lineHeight: 10,
    color: colors.BLACK,
  },
  buttons: {
    text: {
      fontName: 'main',
      maxWidth: 60,
      minWidth: 60,
      padding: 0,
      centered: true,
      color: colors.BLACK,
    },
    hoverText: {
      fontName: 'main',
      maxWidth: 60,
      minWidth: 60,
      padding: 0,
      centered: true,
      color: colors.BLACK,
    },
    activeText: {
      fontName: 'main',
      maxWidth: 60,
      minWidth: 60,
      padding: 0,
      centered: true,
      color: colors.WHITE,
    },
    box: {
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
      //shadow: Style.makeBoxShadow([0, 0, 0, 100], 1, 2),
    },
    hoverBox: {
      padding: 1,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES,2, colors.BLACK),
      ],
      //shadow: Style.makeBoxShadow([0, 0, 0, 100], 1, 2),
    },
    activeBox: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.WHITE),
      ],
      //shadow: Style.makeBoxShadow([0, 0, 0, 100], 1, 2),
    },
    row: Style.makeColumnRowStyle('center', 2),
  },
  explanationButtonsColumn: Style.makeColumnRowStyle('center', 5),
  bottomRow: Style.makeColumnRowStyle('start', 5),
}

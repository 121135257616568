import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import Column from 'views/compositeBoxes/Column';
import Button from 'views/compositeBoxes/Button';
import CompositeBox from 'views/compositeBoxes/CompositeBox';
import Style from 'style/Style';

class LabelBox extends CompositeBox {
  constructor(name, contents, labelStyle, boxStyle) {
    super();

    let theme = Style.getTheme('labelBox');

    let contentDims = contents.getDimensions();

    let textStyle = {
      ...theme.labelText,
      minWidth: contentDims.w + (theme.bottomBox.padding || 0) * 2 - (theme.labelBox.padding || 0) * 2,
      maxWidth: contentDims.w + (theme.bottomBox.padding || 0) * 2 - (theme.labelBox.padding || 0) * 2,
    };
    this._label = new TextBox(name);
    this._label.draw(textStyle);
    this._labelBox = new Box(this._label);
    this._labelBox.draw(theme.labelBox);

    this._bottomBox = new Box(contents);
    this._bottomBox.draw(theme.bottomBox);

    this._column = new Column([ this._labelBox, this._bottomBox ], theme.mainColumn);
    this._base = new Box(this._column);
    this._base.draw(theme.outerBox);

  }
  destroy() {
    this._label.destroy();
    this._labelBox.destroy();
    this._column.destroy();
    this._base.destroy();
    this._bottomBox.destroy();
  }
}

export default LabelBox;

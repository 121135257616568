import Canvas from 'views/Canvas';
import CanvasBox from 'views/canvasBoxes/CanvasBox';
import config from 'config';
import Style from 'style/Style';

class Box extends CanvasBox {
  constructor(content) {
    super();
    if (!content) {
      throw Error('Content required in Box constructor.')
    }
    this._content = content;
    this._canvas = new Canvas(1, 1);
    this._contentPosition = {
      x: 0,
      y: 0,
    };
  }
  getContentPosition() {
    return this._contentPosition;
  }
  draw(options) {
    this._contentPosition = {
      x: 0,
      y: 0,
    };
    let contentDimensions = this._content.getDimensions();
    let backgroundColor = options.backgroundColor || null;
    let backgroundImage = options.backgroundImage || null;
    let padding = options.padding || 0;
    let shadow = options.shadow || null;
    let minWidth = options.minWidth || 0;
    let width = contentDimensions.w;
    let height = contentDimensions.h;
    let centered = options.centered || false;

    if (padding) {
      width += padding * 2;
      height += padding * 2;
      this._contentPosition.x += padding;
      this._contentPosition.y += padding;
    }
    let totalBorder = 0;
    // options.borders should be an array
    if (options.borders) {
      options.borders.forEach((border) => {
        if (border.sides == Style.ALL_SIDES) {
          width += border.width * 2;
          height += border.width * 2;
          this._contentPosition.x += border.width;
          this._contentPosition.y += border.width;
        } else if (border.sides == Style.TOP || border.sides == Style.BOTTOM) {
          height += border.width;
          this._contentPosition.y += border.width;
        } else if (border.sides == Style.LEFT || border.sides == Style.RIGHT) {
          width += border.width;
          this._contentPosition.x += border.width;
        }
      });
    }
    if (options.minWidth && width < options.minWidth) {
      width = options.minWidth;
    }
    if (options.minHeight && height < options.minHeight) {
      height = options.minHeight;
    }

    if (shadow) {
     width += shadow.width;
     height += shadow.height;
    }

    let dim = this.getDimensions();
    if (width != dim.w || height != dim.h) {
      this.setDimensions(width, height);
    }

    if (backgroundColor) {
      this._drawBackground(this._canvas, backgroundColor, width, height, shadow || { width: 0, height: 0});
    }
    if (backgroundImage) {
      this._drawBackgroundImage(this._canvas, backgroundImage.canvas, backgroundImage.style, width, height, shadow || { width: 0, height: 0});
    }
    if (options.borders) {
      this._drawBorders(this._canvas, options.borders, shadow || { width: 0, height: 0});
    }
    if (shadow) {
      this._drawShadow(this._canvas, shadow);
    }
    this._canvas.commitData();
    if (centered) {
      let newX = Math.floor((width - contentDimensions.w - (shadow ? shadow.width : 0)) / 2);
      this._contentPosition.x = newX;
    }
    this._content.setPosition(this._contentPosition.x, this._contentPosition.y);
    this._canvas.addChild(this._content.getCanvas());
  }
  _drawBackground(canvas, backgroundColor, width, height, shadow) {
    let seed = 0;
    for (let j = 0; j < height - shadow.height ; j++) {
      for (let i = 0; i < width - shadow.width; i++) {
        if (!backgroundColor.textured) {
          canvas.drawPoint(i, j, backgroundColor.color);
        } else {
          canvas.drawPoint(i, j, backgroundColor.color.getRandom(seed++));
        }
      }
    }
  }
  _drawBackgroundImage(canvas, imageCanvas, style, width, height, shadow) {
    let drawingW = width - shadow.width;
    let drawingH = height - shadow.height;
    let bgDim = imageCanvas.getDimensions();
    let wIncrement = bgDim.w / drawingW;
    let hIncrement = bgDim.h / drawingH;

    let x = 0;
    let y = 0;
    for (let i = 0; i < drawingH; i++) {
      for (let j = 0; j < drawingW; j++) {
        let xRound = Math.floor(x);
        let yRound = Math.floor(y);
        let color = imageCanvas.getCommittedColorAtPoint(xRound, yRound);
        if (color) {
          canvas.drawPoint(j, i, color);
        }
        x += wIncrement;
      }
      x = 0;
      y += hIncrement;
    }
  }
  _drawBorders(canvas, borders, shadow) {
    let { w, h } = canvas.getDimensions();
    let currX = 0;
    let currY = 0;
    let currW = w - shadow.width;
    let currH = h - shadow.height;
    for (let j = borders.length - 1; j >= 0; j--) {
      let border = borders[j];
      for (let i = 0; i < border.width; i++) {
        if (border.sides == Style.ALL_SIDES) {
          this._drawHorizontalLine(currX, currY, currW, border.top, canvas);
          this._drawHorizontalLine(currX, currY + currH - 1, currW, border.bottom, canvas);
          this._drawVerticalLine(currX, currY, currH, border.left, canvas);
          this._drawVerticalLine(currX + currW - 1, currY, currH, border.right, canvas);
          currX += 1;
          currY += 1;
          currW -= 2;
          currH -= 2;
        } else if (border.sides == Style.TOP) {
          this._drawHorizontalLine(currX, currY, currW, border.top, canvas);
          currY += 1;
          currH -= 1;
        } else if (border.sides == Style.BOTTOM) {
          this._drawHorizontalLine(currX, currY + currH - 1, currW, border.bottom, canvas);
          currH -= 1;
        } else if (border.sides == Style.LEFT) {
          this._drawVerticalLine(currX, currY, currH, border.left, canvas);
          currX += 1;
          currW -= 1;
        } else if (border.sides == Style.RIGHT) {
          this._drawVerticalLine(currX + currW - 1, currY, currH, border.right, canvas);
          currW -= 1;
        }
      }
    }
  }
  _getShadedColor(color, shadeValue) {
    return [color[0] - shadeValue, color[1] - shadeValue, color[2] - shadeValue, color[3] - shadeValue];
  }
  _drawShadow(canvas, shadow) {
    let { w, h } = canvas.getDimensions();
    w = w - shadow.width;
    h = h - shadow.height;
    for (let i = w - 1; i >= 0; i--) {
      for (let j = h - 1; j >= 0; j--) {
        if (i < w - 1 - shadow.width && j < h - 1 - shadow.height) {
          continue;
        }
        let col = canvas.getWorkingColorAtPoint(i, j);
        if (col && col[3] != 0) {
          let targetCol = canvas.getWorkingColorAtPoint(i, j, shadow.width, shadow.height);
          if (targetCol && targetCol[3] == 0) {
            canvas.drawPoint(i, j, shadow.color, shadow.width, shadow.height);
          }
        }
      }
    }
  }
  _drawHorizontalLine(x, y, length, color, canvas) {
    for (let i = x; i < x + length; i++) {
      canvas.drawPoint(i, y, color.getRandom ? color.getRandom(i + x * x) : color);
    }
  }
  _drawVerticalLine(x, y, length, color, canvas) {
    for (let i = y; i < y + length; i++) {
      canvas.drawPoint(x, i, color.getRandom ? color.getRandom(i + y * y) : color);
    }
  }
}

export default Box;

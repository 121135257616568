import LayerController from 'controllers/layerControllers/LayerController';
import ScreenController from 'screens/ScreenController';
import RestoreLayer from 'views/layers/RestoreLayer';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';
import SaveManager from 'managers/SaveManager';

class RestoreMenuController extends LayerController {
  constructor() {
    super();
  }
  _makeLayer() {

    let close = () => this.popThisController();
    let savedGameNames = SaveManager.getGameNames();

    let layer = new RestoreLayer(
      savedGameNames,
      this._cancel.bind(this),
      this._restore.bind(this),
      this._delete.bind(this),
    );

    this._layer = layer;
  }
  _restore(name) {
    if (name == null || name == '') {
      this.showText(["You must supply a name."]);
      return;
    }
    SaveManager.restoreGame(name);
  }
  _delete(name) {
    if (name == null || name == '') {
      this.showText(["You must supply a name."]);
      return;
    }
    SaveManager.deleteSavedGame(name);
    this.popThisController();
  }
  _cancel() {
    this.popThisController();
  }
  handleMouseMove(data) {
    this._layer.handleMouseMove(data);
  }
  handleMouseUp(data) {
    this._layer.handleMouseUp(data);
  }
  loop() {
    //
  }
  _keyPressed(key) {
    this._layer.keyPressed(key);
  }
  becameActive() {
    MouseManager.setCursor('pointer');
  }
}

export default RestoreMenuController;

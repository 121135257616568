import DivCanvas from 'views/DivCanvas';
import CanvasBox from 'views/canvasBoxes/CanvasBox';

import config from 'config';

class EmptyBox extends CanvasBox {
  constructor(width, height) {
    super();
    this._canvas = new DivCanvas(width, height);
  }
  setContent(content) {
    this._canvas.addChild(content.getCanvas());
  }
}

export default EmptyBox;

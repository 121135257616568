export default {
  src: '/images/screens/lobby/sittingPaul.png',
  spriteInfo: {
    sitting: {
      width: 28,
      height: 52,
      x: 0,
      y: 0,
    },
  }
}

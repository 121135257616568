import LayerController from 'controllers/layerControllers/LayerController';
import MenuLayer from 'views/layers/MenuLayer';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';

class MenuController extends LayerController {
  constructor() {
    super();
  }
  _makeLayer() {
    let menuState = StateManager.getState('menu');
    let actionButtons = menuState.getActionButtons();
    let otherButtons = menuState.getOtherButtons();
    let selected = menuState.getSelected();

    let inventoryState = StateManager.getState('inventory');
    let inventoryItem = inventoryState.getSelectedItem();

    let otherButtonCallbacks = {
      'inventory': () => {
        this._getParentController().replaceLayerWith(LayerController.INVENTORY);
      },
      'settings': () => {
        this._getParentController().replaceLayerWith(LayerController.SETTINGS);
      },
    };

    let layer = new MenuLayer(
      selected,
      inventoryItem,
      actionButtons,
      this._switchAction.bind(this),
      this._changedAction.bind(this),
      otherButtons,
      otherButtonCallbacks,
    );

    this._layer = layer;
  }
  _switchAction() {
    let menuState = StateManager.getState('menu');
    menuState.setSelectedToNext();
    let selected = menuState.getSelected();
    this._changedAction(selected);
  }
  _changedAction(name) {
    let menuState = StateManager.getState('menu');
    let actionButtons = menuState.getActionButtons();
    menuState.setSelected(name);
    MouseManager.setCursor();
    this._layer.setSelected(name);
    this._layer.redrawButtons();
  }
  handleMouseMove(data) {
    if (this._layer && this._layer.isBelowMenu(data.position)) {
      this._getParentController().popController();
    }
  }
  keyPressed(key) {
    this._layer.keyPressed(key);
  }
  loop() {
    //
  }
  becameActive() {
    MouseManager.setCursor();
  }
}

export default MenuController;

import EmptyBox from 'views/canvasBoxes/EmptyBox';
import CompositeBox from 'views/compositeBoxes/CompositeBox';

class Column extends CompositeBox {
  constructor(items, options) {
    super();
    this._base = new EmptyBox(1,1);
    this._options = options;
    this._items = items;

    this.draw();
  }
  // the previous items should be destroyed before this
  setContent(items) {
    this._items = items;
    this.draw();
  }
  draw() {
    let space = this._options.space || 0;
    let alignment = this._options.alignment || 'center';
    let width = 0;
    let height = 0;
    this._items.forEach((item) => {
      let dims = item.getDimensions();
      if (dims.w > width) {
        width = dims.w;
      }
      height += dims.h;
    });
    height += (this._items.length - 1) * space;
    this._base.setDimensions(width, height);
    let currH = 0;
    let currX = 0;
    this._items.forEach((item) => {
      let itemDims = item.getDimensions();
      if (alignment == 'center') {
        currX = Math.round((width - itemDims.w) / 2);
      }
      item.setPosition(currX, currH);
      currH += item.getDimensions().h + space;
      this._base.getCanvas().addChild(item.getCanvas());
    });
  }
  destroy() {
    this._base.destroy();
  }
}

export default Column;

import Actions from 'classes/Actions';
import Speech from 'classes/Speech';
import State from 'state';
import ConversationLine from 'classes/ConversationLine';

export default {
  getFace: function() {
    return ['Paul', 'paulFace'];
  },
  getEmotion: function() {
    return '';
  },
  questions: {
    "How would you rate your JavaScript skills?": new ConversationLine(
               // getActions function
               () => {
                 return [
                   Actions.speak(
                     'Paul', 'paulFace',
                     [
                       new Speech('It is my main language but the JavaScript world is so vast, so I would say a 9.'),
                     ]
                   ),
                 ];
               },
               // shouldShow function
               () => true,
               // changeState function
               () => {},
             ),

    "How would you rate your CSS skills?": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("I am very comfortable with CSS and SCSS so I would say a 9."),
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
  "How would you rate your backend skills?": new ConversationLine(
            () => {
              return [
                Actions.speak(
                  'Paul', 'paulFace',
                  [
                    new Speech("I am bigger on the front end but I have done backend work. I have used several different PHP Frameworks."),
                    new Speech("I've used a bit of Node in the backend. I have used MySQL quite a bit."),
                  ]
                )
              ]
            },
            () => true,
            () => {},
          ),
    "What programming languages have you had some experience with?": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("I started off using Objective-C, although I haven't used it in a long time."),
                      new Speech("I have used PHP quite a bit. Recently I have been using the Slim Framework."),
                      new Speech("I have used Java extensively in the past."),
                      new Speech("I am currently trying to get better at using Swift."),
                      new Speech("I write scripts in bash sometimes."),
                      new Speech("And I use node."),
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
    "Can I see a sample of your code?": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech(`If you view the source code this page, you will see some of my work. Most of my git repos are private though.`),
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
    "Ask something else": new ConversationLine(
              () => {
                return [
                  Actions.popConversation()
                ]
              },
              () => true,
              () => {},
            ),
  }
}

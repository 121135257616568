export default {
  "src": "/images/characters/interviewer.png",
  "spriteInfo": {
    "stop_r": {
      "width": 64,
      "height": 64,
      "x": 0,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "stop_l": {
      "width": 64,
      "height": 64,
      "x": 64,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "stop_d": {
      "width": 64,
      "height": 64,
      "x": 128,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "stop_u": {
      "width": 64,
      "height": 64,
      "x": 192,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "stop_rd": {
      "width": 64,
      "height": 64,
      "x": 256,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "stop_ld": {
      "width": 64,
      "height": 64,
      "x": 320,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "stop_lu": {
      "width": 64,
      "height": 64,
      "x": 384,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "stop_ru": {
      "width": 64,
      "height": 64,
      "x": 448,
      "y": 0,
      "animation": false,
      "repeat": true,
      "frames": 1,
      "reverse": false
    },
    "walk_r": {
      "width": 64,
      "height": 64,
      "x": 512,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    },
    "walk_l": {
      "width": 64,
      "height": 64,
      "x": 1024,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    },
    "walk_d": {
      "width": 64,
      "height": 64,
      "x": 1536,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    },
    "walk_u": {
      "width": 64,
      "height": 64,
      "x": 2048,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    },
    "walk_rd": {
      "width": 64,
      "height": 64,
      "x": 2560,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    },
    "walk_ld": {
      "width": 64,
      "height": 64,
      "x": 3072,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    },
    "walk_lu": {
      "width": 64,
      "height": 64,
      "x": 3584,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    },
    "walk_ru": {
      "width": 64,
      "height": 64,
      "x": 4096,
      "y": 0,
      "animation": true,
      "repeat": true,
      "frames": 8,
      "reverse": false
    }
  }
}
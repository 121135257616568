const colors = {
  CLEAR: [0, 0, 0, 0],
  BLACK: [0, 0, 0, 255],
  WHITE: [255, 255, 255 ,255],
  RED: [255, 0, 0, 255],
  BLUE: [0, 0, 255, 255],
  PINK: [255, 150, 150, 255],
  GREY: [175, 175, 175, 255],
  LIGHT_BROWN: [200, 155, 125, 255],
  GOLD: [255, 200, 100, 255],
  SHADOW: [0, 0, 0, 255],
}

colors.MAIN_COLOR = colors.LIGHT_BROWN;

export default colors;

export default {
  _data: {
    // selected can be null. MouseManager will have to choose
    // how to respond to that value
    selected: 'walk',
    actionButtons: {
      walk: {
        showing: true,
        disabled: false,
      },
      hand: {
        showing: true,
        disabled: false,
      },
      eye: {
        showing: true,
        disabled: false,
      },
      talk: {
        showing: true,
        disabled: false,
      },
      inventoryItem: {
        showing: true,
        disabled: false,
      },
    },
    otherButtons: {
      inventory: {
        showing: true,
        disabled: false,
      },
      settings: {
        showing: true,
        disabled: false,
      },
    }
  },
  getActionButtons: function() {
    return this._data.actionButtons;
  },
  getOtherButtons: function() {
    return this._data.otherButtons;
  },
  getSelected: function() {
    return this._data.selected;
  },
  setSelected: function(name) {
    if (name != null && !this._data.actionButtons[name]) {
      throw Error(`You can't select a button that doesn't exist. (${name})`);
    }
    let button = this._data.actionButtons[name];
    if (button.disabled || !button.showing) {
      this._selectFirstNonDisabled();
    } else {
      this._data.selected = name;
    }
  },
  setSelectedToNext: function() {
    let foundNext = false;
    let selected = this.getSelected();
    let actionButtons = this.getActionButtons();
    let names = Object.keys(actionButtons);

    let selectedIndex = names.indexOf(selected);
    if (selectedIndex == -1) {
      // selected was null
      return;
    }
    for (let i = selectedIndex + 1; i < names.length; i++) {
      if (names[i] == 'inventoryItem' && this._inventoryItemIsEmpty()) {
        continue;
      }
      let button = actionButtons[names[i]];
      if (button.showing && !button.disabled) {
        this.setSelected(names[i]);
        return;
      }
    }
    this._selectFirstNonDisabled();
  },
  setDisabled: function(names) {
    for (let actionName in this._data.actionButtons) {
      if (names.indexOf(actionName) != -1) {
        this._data.actionButtons[actionName].disabled = true;
      } else {
        this._data.actionButtons[actionName].disabled = false;
      }
    }
    if (names.indexOf('inventory') != -1) {
      this._data.otherButtons['inventory'].disabled = true;
    } else {
      this._data.otherButtons['inventory'].disabled = false;
    }
    // if we disable the currently selected button, we change the selected
    // button to the first non-disabled one
    let selected = this._data.actionButtons[this._data.selected];
    if (!selected || selected.disabled) {
      this._selectFirstNonDisabled();
    }
  },
  setShowing: function(names) {
    for (let actionName in this._data.actionButtons) {
      if (names.indexOf(actionName) != -1) {
        this._data.actionButtons[actionName].showing = true;
      } else {
        this._data.actionButtons[actionName].showing = false;
      }
    }
    // if we hid the currently selected button, we change the selected
    // button to the first non-disabled one
    let selected = this._data.actionButtons[this._data.selected];
    if (!selected.showing) {
      this._selectFirstNonDisabled();
    }
  },
  _selectFirstNonDisabled: function() {
    let foundAbledButton = false;
    for (let actionName in this._data.actionButtons) {
      if (actionName == 'inventoryItem' && this._inventoryItemIsEmpty()) {
        continue;
      }
      let button = this._data.actionButtons[actionName];
      if (!button.disabled && button.showing) {
        this._data.selected = actionName;
        foundAbledButton = true;
        break;
      }
    }
    if (!foundAbledButton) {
      this._data.selected = null;
    }
  },
  _inventoryItemIsEmpty: function() {
    let inventoryState = this.getState('inventory');
    return !inventoryState.hasSelectedItem();
  },
  setWaiting(shouldWait) {
    if (shouldWait) {
      this.setDisabled(['walk', 'hand', 'talk', 'eye', 'inventoryItem', 'inventory']);
    } else {
      this.setDisabled([]);
    }
    this._selectFirstNonDisabled();
  }
}

import CompositeBox from 'views/compositeBoxes/CompositeBox';
import PictureBox from 'views/canvasBoxes/PictureBox';
import ImageManager from 'managers/ImageManager';
import Box from 'views/canvasBoxes/Box';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import Button from 'views/compositeBoxes/Button';
import Row from 'views/compositeBoxes/Row';
import Column from 'views/compositeBoxes/Column';
import Style from 'style/Style';

class SelectionList extends CompositeBox {
  constructor(
    onSelected,
    selections,
    selectionIndex,
    width,
    arrowImage,
    topArrowOptions,
    bottomArrowOptions,
    textOptions,
    textBoxOptions,
    highlightTextOptions,
    highlightBoxOptions,
    listItemHeight,
    outerColumnOptions,
    outerBoxOptions) { // end of constructor arguments

    super();

    this._selections = selections;
    this._selectionIndex = selectionIndex;
    this._width = width;
    this._scrollAmount = 0;
    this._maxShowing = 8;
    this._onSelected = onSelected;

    this._arrowImage = arrowImage;
    this._topArrowOptions = topArrowOptions;
    this._bottomArrowOptions = bottomArrowOptions;
    this._textOptions = textOptions;
    this._textBoxOptions = textBoxOptions;
    this._highlightTextOptions = highlightTextOptions;
    this._highlightBoxOptions = highlightBoxOptions;
    this._listItemHeight = listItemHeight;
    // for the outer column (including arrows), not the list column
    this._outerColumnOptions = outerColumnOptions;
    this._outerBoxOptions = outerBoxOptions;

    this._arrow = new PictureBox();
    this._arrow.drawImage(arrowImage);
    this._arrowBox = new Box(this._arrow);
    this._arrowBox.draw(this._topArrowOptions);
    this._arrowBox.addListener('mousedown', this.scrollUp.bind(this));
    this._arrowBox.setClickable(true);

    this._selectionItems = [];
    this._makeSelections();
    this._listCol = new Column(this._selectionItems, { alignment: 'start' });
    this._listBox = new EmptyBox(width, listItemHeight * this._maxShowing);
    this._listBox.setContent(this._listCol);

    this._arrow2 = new PictureBox();
    this._arrow2.drawImage(arrowImage, { flippedY: true });
    this._arrow2Box = new Box(this._arrow2);
    this._arrow2Box.draw(this._bottomArrowOptions);
    this._arrow2Box.addListener('mousedown', this.scrollDown.bind(this));
    this._arrow2Box.setClickable(true);

    this._totalColumn = new Column([this._arrowBox, this._listBox, this._arrow2Box], this._outerColumnOptions);

    this._base = new Box(this._totalColumn);
    this._base.draw(this._outerBoxOptions);

  }
  getSelectionValue() {
    if (this._selections.length == 0) {
      return null;
    }
    return this._selections[this._selectionIndex];
  }
  _makeSelections() {
    this._selectionItems.forEach((item) => {
      item.destroy();
    });
    this._selectionItems = [];

    this._selections.forEach((sel, index) => {
      if (index >= this._scrollAmount && index < this._scrollAmount + this._maxShowing) {
        let textOptions = index == this._selectionIndex ? this._highlightTextOptions : this._textOptions;
        let boxOptions = index == this._selectionIndex ? this._highlightBoxOptions : this._textBoxOptions;
        let listItem = new Button(
          (e) => { this._onSelected(index); },
          sel,
          textOptions,
          boxOptions,
        );
        this._selectionItems.push(listItem);
      }
    })
  }
  setSelectedIndex(index) {
    if (index == this._selectionIndex) {
      return;
    }
    this._selectionIndex = index;
    this.draw();
  }
  scrollUp() {
    if (this._selectionIndex == 0) {
      return;
    }
    this._selectionIndex--;
    if (this._scrollAmount > this._selectionIndex) {
      this._scrollAmount--;
    }
    this._onSelected(this._selectionIndex);
    this.draw();
  }
  scrollDown() {
    if (this._selectionIndex == this._selections.length - 1) {
      return;
    }
    this._selectionIndex++;
    if (this._scrollAmount + this._maxShowing == this._selectionIndex) {
      this._scrollAmount++;
    }
    this._onSelected(this._selectionIndex);
    this.draw();
  }
  draw() {
    this._makeSelections();
    this._listCol.setContent(this._selectionItems);
  }
  destroy() {
    this._arrow.destroy()
    this._arrowBox.destroy();
    this._selectionItems.forEach((item) => item.destroy());
    this._listCol.destroy();
    this._listBox.destroy();
    this._arrow2.destroy();
    this._arrow2Box.destroy();
    this._totalColumn.destroy();
    this._base.destroy();
  }
}

export default SelectionList;

import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import CompositeBox from 'views/compositeBoxes/CompositeBox';
import focusableMixin from 'mixins/focusableMixin';
import MouseManager from 'managers/MouseManager';

class Button extends CompositeBox {
  constructor(onClick, text, normalTextOptions, normalBoxOptions, hoverTextOptions, hoverBoxOptions, activeTextOptions, activeBoxOptions) {
    super();
    focusableMixin(this);

    this._onClick = onClick;

    this._hovering = false;
    this._active = false;

    this._normalTextOptions = normalTextOptions;
    this._normalBoxOptions = normalBoxOptions;
    this._hoverTextOptions = hoverTextOptions;
    this._hoverBoxOptions = hoverBoxOptions;
    this._activeTextOptions = activeTextOptions;
    this._activeBoxOptions = activeBoxOptions;

    this._text = new TextBox(text);
    this._base = new Box(this._text);

    this._base.setClickable(true);
    this.draw();

    if (hoverTextOptions) {
      this._base.addListener(
        'mouseover',
        () => {
          this._hovering = true
          this.draw();
        }
      )
      this._base.addListener(
        'mouseout',
        () => {
          this._hovering = false;
          this.draw();
        }
      )
    }
    if (activeTextOptions) {
      this._base.addListener(
        'mousedown',
        (e) => {
          if (MouseManager.eventIsRightClick(e)) {
            return;
          }
          this._active = true;
          this.draw();
          setTimeout(() => {
            this._active = false;
            this.draw();
            this._onClick();
          }, 30);
        }
      );
    } else {
      this._base.addListener('mousedown', onClick);
    }
  }
  destroy() {
    this._text.destroy();
    this._base.destroy();
  }
  draw() {
    if (this._active) {
      this._drawActive();
    } else if (this._hovering) {
      this._drawHover();
    } else if (this._focused) {
      this._drawHover();
    } else {
      this._drawNormal();
    }
  }
  click() {
    this._active = true;
    this.draw();
    setTimeout(() => {
      this._active = false;
      this.draw();
      this._onClick();
    }, 30);
  }
  _drawNormal() {
    this._text.draw(this._normalTextOptions);
    this._base.draw(this._normalBoxOptions)
  }
  _drawHover() {
    this._text.draw(this._hoverTextOptions);
    this._base.draw(this._hoverBoxOptions)
  }
  _drawFocused() {
    this._text.draw(this._hoverTextOptions);
    this._base.draw(this._hoverBoxOptions)
  }
  _drawActive() {
    this._text.draw(this._activeTextOptions);
    this._base.draw(this._activeBoxOptions);
  }
  setDisabled(value) {
    console.log("NEED TO IMPLEMENT THIS SET DISABLED BUTTON");
  }
}

export default Button;

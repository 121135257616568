import LayerController from 'controllers/layerControllers/LayerController';
import ScreenController from 'screens/ScreenController';
import SaveLayer from 'views/layers/SaveLayer';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';
import SaveManager from 'managers/SaveManager';

class SaveMenuController extends LayerController {
  constructor() {
    super();
  }
  _makeLayer() {

    let close = () => this.popThisController();
    let savedGameNames = SaveManager.getGameNames();

    let layer = new SaveLayer(
      savedGameNames,
      this._cancel.bind(this),
      this._save.bind(this),
      this._delete.bind(this),
    );

    this._layer = layer;
  }
  _cancel() {
    this.popThisController();
  }
  _save(name) {
    if (name == null || name == '') {
      this.showText(["You must supply a name."]);
      return;
    }
    SaveManager.saveGame(name);
    this.popThisController();
  }
  _delete(name) {
    if (name == null || name == '') {
      this.showText(["You must supply a name."]);
      return;
    }
    SaveManager.deleteSavedGame(name);
    this.popThisController();
  }
  handleMouseMove(data) {
    this._layer.handleMouseMove(data);
  }
  handleMouseUp(data) {
    this._layer.handleMouseUp(data);
  }
  loop() {
    //
  }
  _keyPressed(key) {
    this._layer.keyPressed(key);
  }
  becameActive() {
    MouseManager.setCursor('pointer');
  }
}

export default SaveMenuController;

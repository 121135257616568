import LayerController from 'controllers/layerControllers/LayerController';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';
import ConversationLayer from 'views/layers/ConversationLayer';
import faceData from 'faceData';
import ImageManager from 'managers/ImageManager';
import TimeManager from 'managers/TimeManager';
import conversations from 'conversations';

class ConversationController extends LayerController {
  constructor(conversationStack) {
    super();
    this._conversationStack = conversationStack;
    this._actionQueue = [];
    this._conversation = null;

    this._eyeFrame = 0;
    this._hasChanged = false;
    this._showingQuestions = false;
  }
  _loadConversation() {
    let conversationString = this._conversationStack[this._conversationStack.length - 1];
    let [character, conversationName] = conversationString.split('.');
    this._conversation = conversations[character][conversationName];
  }
  _makeLayer() {
    this._layer = new ConversationLayer(this._clickedQuestion.bind(this));
    this._showQuestions();
  }
  _showQuestions() {
    this._loadConversation();
    this._showingQuestions = true;
    let {
      questions,
      characterName,
      eyeData,
      mouthData,
      bgData,
    } = this._getData();
    this._layer.showFaceAndQuestions(questions, characterName, eyeData, mouthData, bgData);
  }
  _loop() {
    this._advanceAnimation();
    if (this._hasChanged) {
      this._updateAnimation();
    }
    this._runActionQueue();
  }
  _clickedQuestion(q) {
    this._currentQuestion = q;
    let actions = this._conversation.questions[q].getActions();
    this._actionQueue = actions;
    this._showingQuestions = false;
    this._layer.clearFaceAndQuestions();
  }
  _runActionQueue() {
    if (this._actionQueue.length == 0) {
      if (this._conversationStack.length == 0) {
        this.popThisController();
      } else if (!this._showingQuestions) {
        this._showQuestions();
      }
      return;
    }
    let action = this._actionQueue[0];
    if (this._executeAction(action)) {
      this._actionQueue.shift();
    }
    if (this._actionQueue.length == 0) {
      this._conversation.questions[this._currentQuestion].changeState();
    }
  }
  // returns true if action is complete, false if not
  _executeAction(action) {
    switch (action.type) {
      case 'sleep': {
        let loopTime = TimeManager.getLoopTime();
        action.time -= loopTime;
        return (action.time <= 0);
        break;
      }
      case 'text': {
        this.showText(action.lines);
        return true;
        break;
      }
      case 'speak': {
        this.showDialogue(action.character, action.faceName, action.lines);
        return true;
        break;
      }
      case 'pushConversation': {
        this._conversationStack.push(action.conversation);
        return true;
        break;
      }
      case 'popConversation': {
        this._conversationStack.pop();
        return true;
        break;
      }
    }
  }

  _resetAnimationState() {
    this._eyeFrame = 0;
  }
  _advanceAnimation() {
    let {
      eyeData,
    } = this._getData();

    if (this._eyeFrame < eyeData.store.getTotalFrames() - 1) {
      this._eyeFrame++;
      this._hasChanged = true;
    }
  }
  _updateAnimation() {
    let {
      eyeData,
      mouthData,
      bgData
    } = this._getData();

    this._layer.updateAnimation(
      eyeData,
      mouthData,
      bgData,
    );
    this._hasChanged = false;
  }
  _getData() {
    let [characterName, faceName] = this._conversation.getFace();
    let emotion = this._conversation.getEmotion();
    let fcData = faceData[faceName];
    let fcImages = ImageManager.getImageSet(faceName);

    let eyeSprite = 'eyes' + (emotion ? `_${emotion}` : '');
    let eyeStore = fcImages[eyeSprite];
    let eyeSpritePosition = eyeStore.getFramePosition(this._eyeFrame);
    let eyePosition = fcData.eyes;
    let eyeOptions = {
      xOffset: eyeSpritePosition.x,
      yOffset: eyeSpritePosition.y,
    }

    let mouthStore = fcImages['mouth'];
    let mouthSpritePosition = mouthStore.getFramePosition(0);
    let mouthPosition = fcData.mouth;
    let mouthOptions = {
      xOffset: mouthSpritePosition.x,
      yOffset: mouthSpritePosition.y,
    }

    let bgStore = fcImages.bg;

    let questions = this._getQuestions();

    return {
      questions,
      characterName,
      eyeData: {
        store: eyeStore,
        position: eyePosition,
        options: eyeOptions
      },
      mouthData: {
        store: mouthStore,
        position: mouthPosition,
        options: mouthOptions
      },
      bgData: {
        store: bgStore,
      },
    };
  }
  _getQuestions() {
    let questions = [];
    for (let q in this._conversation.questions) {
      if (this._conversation.questions[q].shouldShow()) {
        questions.push(q);
      }
    }
    return questions;
  }
  becameActive() {
    MouseManager.setCursor('pointer');
  }
}

export default ConversationController;

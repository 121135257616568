import ImageManager from 'managers/ImageManager';
import Layer from 'views/layers/Layer';
import Box from 'views/canvasBoxes/Box';
import Row from 'views/compositeBoxes/Row';
import PictureButton from 'views/compositeBoxes/PictureButton';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';

class MenuLayer extends Layer {
  constructor(selected, inventoryItem, actionButtons, switchAction, changedAction, otherButtons, otherButtonCallbacks) {
    super();

    this._rightClickHandler = switchAction;

    this._selected = selected;
    this._inventoryItem = inventoryItem;
    this._actionButtons = actionButtons;
    this._changedAction = changedAction;
    this._otherButtons = otherButtons;
    this._otherButtonCallbacks = otherButtonCallbacks;

    let theme = Style.getTheme('menuBox');

    this._focusIndex = 0;
    this._focusables = [];

    this._buttons = this._makeButtons();

    this._mainRow = new Row(this._buttons, theme.buttons.buttonRow);

    this._base = new Box(this._mainRow);
    this._base.draw(theme.base);

    this._drawFocus();

    this.addChild(this._base);
  }
  isBelowMenu(position) {
    let baseDims = this._base.getDimensions();
    return position.y > baseDims.h;
  }
  destroy() {
    this.removeEventListener();
    this._buttons.forEach((button) => button.destroy());
    this._mainRow.destroy();
    this._base.destroy();
    this._removeLayerDiv();
  }
  setSelected(selected) {
    this._selected = selected;
  }
  redrawButtons() {
    this._buttons.forEach((button) => {
      if (button.getName() == this._selected) {
        button.setDepressed(true);
      } else {
        button.setDepressed(false);
      }
      button.draw();
    })
  }
  _makeButtons() {
    return [...this._makeActionButtons(), ...this._makeOtherButtons()];
  }
  _makeActionButtons() {
    let theme = Style.getTheme('menuBox');

    let buttons = [];

    for (let name in this._actionButtons) {
      let buttonInfo = this._actionButtons[name];
      if (!buttonInfo.showing) {
        continue;
      }
      let imageStore;
      let button;
      if (name == 'inventoryItem') {
        imageStore = ImageManager.getImage('inventory', this._inventoryItem);
        button = new PictureButton(
          name,
          this._changedAction,
          imageStore,
          theme.buttons.inventoryImage,
          theme.buttons.inventoryBox,
          theme.buttons.inventoryImageActive,
          theme.buttons.inventoryBoxActive,
          theme.buttons.imageDisabled,
          theme.buttons.inventoryBoxDisabled,
          theme.buttons.boxFocused,
        );
      } else {
        imageStore = ImageManager.getImage('menuButtons', name);
        button = new PictureButton(
          name,
          this._changedAction,
          imageStore,
          theme.buttons.image,
          theme.buttons.box,
          theme.buttons.imageActive,
          theme.buttons.boxActive,
          theme.buttons.imageDisabled,
          theme.buttons.boxDisabled,
          theme.buttons.boxFocused,
        );
      }

      if (buttonInfo.disabled || (name == 'inventoryItem' && this._inventoryItem == 'blank')) {
        button.setDisabled(true);
      } else {
        this._focusables.push(button);
      }
      button.setDepressed(this._selected == name);
      buttons.push(button);
    };
    return buttons;
  }
  _makeOtherButtons() {
    let theme = Style.getTheme('menuBox');

    let buttons = [];
    for (let name in this._otherButtons) {
      let buttonInfo = this._otherButtons[name];
      if (!buttonInfo.showing) {
        continue;
      }
      let imageStore = ImageManager.getImage('menuButtons', name);
      let button = new PictureButton(
        name,
        this._otherButtonCallbacks[name],
        imageStore,
        theme.buttons.image,
        theme.buttons.box,
        theme.buttons.imageActive,
        theme.buttons.boxActive,
        theme.buttons.imageDisabled,
        theme.buttons.boxDisabled,
        theme.buttons.boxFocused,
      );
      if (buttonInfo.disabled) {
        button.setDisabled(true);
      } else {
        this._focusables.push(button);
      }
      buttons.push(button);
    };
    return buttons;
  }
  _drawFocus() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex) {
        focusable.setFocused(true);
      } else {
        focusable.setFocused(false);
      }
      focusable.draw();
    });
  }
  _focusInput() {
    this._focusIndex = 0;
    this._drawFocus();
  }
  _clickFocusedButton() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex && focusable instanceof PictureButton) {
        focusable.click();
      }
    });
  }
  keyPressed(key) {
    if (key == 'Enter') {
      this._clickFocusedButton();
    }
    if (key == 'ArrowDown' || key == 'ArrowRight') {
      this._focusIndex++;
      if (this._focusIndex >= this._focusables.length) {
        // 0 is the input in this layer
        this._focusIndex = 0;
      }
      this._drawFocus();
    }
    if (key == 'ArrowUp' || key == 'ArrowLeft') {
      this._focusIndex--;
      if (this._focusIndex < 0) {
        this._focusIndex = this._focusables.length - 1;
      }
      this._drawFocus();
    }
  }
}

export default MenuLayer;

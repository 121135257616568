import Actions from 'classes/Actions';
import Speech from 'classes/Speech';
import State from 'state';
import ConversationLine from 'classes/ConversationLine';

export default {
  getFace: function() {
    return ['Paul', 'paulFace'];
  },
  getEmotion: function() {
    return '';
  },
  questions: {
    "Ask about Paul.": new ConversationLine(
               // getActions function
               () => {
                 return [
                   Actions.speak('Paul', 'paulFace', [new Speech('What would you like to know?')]),
                   Actions.pushConversation('paul.aboutPaul')
                 ];
               },
               // shouldShow function
               () => true,
               // changeState function
               () => {},
             ),
    "Ask about current employment.": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("I was working at a digital marketing agency, but I was laid off in June."),
                      new Speech("I was making hybrid mobile apps for the most part."),
                      new Speech("Then I got some short term contract work as a React developer for a company in Kamloops."),
                      new Speech("Their website aggregates news about cryptocurrencies and blockchain technologies."),
                      new Speech("Currently I am not working anywhere."),
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
  "Ask about programming.": new ConversationLine(
            () => {
              return [
                Actions.speak(
                  'Paul', 'paulFace',
                  [
                    new Speech("What would you like to know?"),
                  ]
                ),
                Actions.pushConversation('paul.programming'),
              ]
            },
            () => true,
            () => {},
          ),
    "Ask about interview simulator.": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("I made a JavaScript framework for making point and click adventures."),
                      new Speech("I made the interview simulator mostly as a way to test if the features were working."),
                      new Speech("I also thought it would help me stick out from the other applicants.")
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
    "Offer job. (Non-binding)": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("That is very nice of you."),
                    ]
                  ),
                  Actions.popConversation()
                ]
              },
              () => true,
              () => {},
            ),
    "Tell him you'll keep his resume on file.": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("Wow, thanks!"),
                    ]
                  ),
                  Actions.popConversation()
                ]
              },
              () => true,
              () => {},
            ),
    "Enough already.": new ConversationLine(
              () => {
                return [
                  Actions.popConversation()
                ]
              },
              () => true,
              () => {},
            ),
  }
}

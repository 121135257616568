import Destroyable from 'classes/Destroyable';
import config from 'config';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import eventMixin from 'mixins/eventMixin';
import MouseManager from 'managers/MouseManager';

class Layer extends Destroyable {
  constructor() {
    super();
    eventMixin(this);
    this._layer = new EmptyBox(config.layout.screenWidth, config.layout.screenHeight);
    this._layer.getElement().style.zIndex = 0;
    this._layer.setClickable(true);

    this.addMousedownListener((e) => {
      let position = MouseManager.getLogicalPosition(e);
      if (MouseManager.eventIsRightClick(e) && this._rightClickHandler) {
        this._rightClickHandler(position);
      }
      if (!MouseManager.eventIsRightClick(e) && this._leftClickHandler) {
        this._leftClickHandler(position);
      }
    })
  }
  setRightClickHandler(rightClickHandler) {
    this._rightClickHandler = rightClickHandler;
  }
  setLeftClickHandler(leftClickHandler) {
    this._leftClickHandler = leftClickHandler;
  }
  setClickable(bool) {
    this._layer.setClickable(bool);
  }
  setZIndex(z) {
    this._layer.getElement().style.zIndex = z;
  }
  addMousedownListener(layerClickHandler = ()=>{}) {
    this._layer.addListener('mousedown', layerClickHandler);
  }
  getElement() {
    return this._layer.getElement();
  }
  keyPressed(key) {
    // should be overwritten if a layer takes key events
  }
  handleMouseMove(position) {
    // should be overwritten if a layer needs to
  }
  handleMouseUp(position) {
    // should be overwritten if a layer needs to
  }
  addChild(box) {
    this._layer.getElement().appendChild(box.getElement());
  }
  centerBox(box) {
    let dims = box.getDimensions();
    let w = config.layout.screenWidth;
    let h = config.layout.screenHeight;
    let boxW = Math.floor(w / 2 - dims.w / 2);
    let boxH = Math.floor(h / 2 - dims.h / 2);
    box.setPosition(boxW, boxH);
  }
  eventHandler(detail) {
    // should be overridden
  }
  _removeLayerDiv() {
    this._layer.destroy();
  }
  destroy() {
    throw Error('Subclasses of Layer should override the destroy() method. It should call removeLayerDiv()');
  }
}

export default Layer;

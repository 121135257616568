import CanvasBox from 'views/canvasBoxes/CanvasBox';
import Canvas from 'views/Canvas';
import config from 'config';

class PictureBox extends CanvasBox {
  constructor() {
    super();
    this._canvas = new Canvas(1, 1);
  }
  getContentPosition() {
    return this._contentPosition;
  }
  drawImage(imageStore, options = {}) {
    let imgDim = imageStore.getDimensions();
    let imgPos = imageStore.getPosition();
    let width = options.width || imgDim.w;
    let height = options.height || imgDim.h;

    let xOffset = typeof options.xOffset == 'undefined' ? imgPos.x : options.xOffset;
    let yOffset = typeof options.yOffset == 'undefined' ? imgPos.y : options.yOffset;

    let scale = options.scale || 1;
    let flippedX = options.flippedX || false;
    let flippedY = options.flippedY || false;

    let filter1 = options.filter1 || [0, 0, 0];
    let filter2 = options.filter2 || filter1;

    let drawingW = width;
    let drawingH = height;

    drawingW = Math.round(width * scale);
    drawingH = Math.round(height * scale);

    let dim = this.getDimensions();
    if (drawingW != dim.w || drawingH != dim.h) {
      this.setDimensions(drawingW, drawingH);
    }
    let wIncrement = width / drawingW;
    let hIncrement = height / drawingH;
    let x = 0;
    let y = 0;
    for (let i = 0; i < drawingH; i++) {
      for (let j = 0; j < drawingW; j++) {
        let xRound = Math.floor(x);
        let yRound = Math.floor(y);
        let filter = i == j ? filter1  : ((i - j) % 2 == 0 ? filter1 : filter2);
        let color = imageStore.getColorAtPoint(xRound, yRound, xOffset, yOffset);
        if (color) {
          let drawX = flippedX ? drawingW - 1 - j : j;
          let drawY = flippedY ? drawingH - 1 - i : i;
          this._canvas.drawPoint(drawX, drawY, [color[0] + filter[0], color[1] + filter[1], color[2] + filter[2], color[3]]);
        }
        x += wIncrement;
      }
      x = 0;
      y += hIncrement;
    }
    this._canvas.commitData();
  }
  // uses get committed color at point because this is used to detect if a pictuebox has been clicked
  // on currently displaying colors
  hasColorAtPoint(x, y) {
    let color = this.getCanvas().getCommittedColorAtPoint(x, y);
    return color && color[3] == 255;
  }
}

export default PictureBox;

export default {
  _data: {
    selected: null,
    items: {
      money: {
        name: 'money',
        acquired_at: 0,
        amount: 250
      },
      // pliers: {
      //   name: 'pliers',
      //   acquired_at: 4,
      // },
      burnedMoney: {
        name: 'burnedMoney',
        acquired_at: null,
      },
    },
  },
  hasSelectedItem: function() {
    return this._data.selected != null;
  },
  getSelectedItem: function() {
    return this._data.selected || 'blank';
  },
  setSelectedItem: function(name) {
    if (name != null && !this._data.items[name]) {
      throw Error(`You can't select an inventory item that doesn't exist. (${name})`);
    }
    this._data.selected = name;
  },
  getItems: function() {
    let itemArray = [];
    let items = this._data.items;
    for (let name in items) {
      let item = items[name];
      if (item.acquired_at != null) {
        itemArray.push(item);
      }
    }
    itemArray.sort((item1, item2) => {
      return item1.acquired_at - item2.acquired_at;
    });
    return itemArray;
  },
  getItem: function(name) {
    return this._data.items[name];
  },
  setItemAsAcquired: function(name) {
    this._data.items[name].acquired_at = new Date().getTime();
  },
  setItemAsNotAcquired: function(name) {
    this._data.items[name].acquired_at = null;
    if (this._data.selected == name) {
      this._data.selected = null;
      let menuState = this.getState('menu');
      if (menuState.getSelected() == 'inventoryItem') {
        menuState.setSelected('walk');
      }
    }
  }
}

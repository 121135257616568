import config from 'config';
import Canvas from 'views/Canvas';

let FontManager = {
  _fonts: {},
  loadFonts: function() {
    let fonts = config.fonts;
    let fontPromises = [];
    for (let fontName in fonts) {
      let font = fonts[fontName];
      fontPromises.push(
        fetch(font.src)
        .then(response => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status); // Rejects the promise
          } else {
            return response.blob();
          }
        })
        .then((blob) => {
          let fr = new FileReader();
          return new Promise((resolve) => {
            fr.onload = (ev) => {
              switch (font.type) {
                case 'wfn': {
                  this._fonts[fontName] = this._processWFN(fr.result);
                  this._fonts[fontName].lineHeight = font.lineHeight;
                  this._fonts[fontName].offsetY = font.offsetY;
                  this._fonts[fontName].offsetX = font.offsetX;
                  break;
                }
                case 'sci': {
                  this._fonts[fontName] = this._processSCI(fr.result);
                  this._fonts[fontName].lineHeight = font.lineHeight;
                  this._fonts[fontName].offsetY = font.offsetY;
                  this._fonts[fontName].offsetX = font.offsetX;
                  break;
                }
              }
              return resolve();
            }
            fr.readAsBinaryString(blob);
          })
        })
      );
    }
    return Promise.all(fontPromises);
  },
  _processWFN(wfn, lineHeight) {
    // the character data always starts on the 17th byte of wfn files
    let position = 17;
    // returns an object with the width, height, and points representing the shape
    // of the letter
    let getLetter = () => {
      // the width and height of each letter are stored in 2 bytes each, both little endian.
      // I don't expect any letters to be wider or taller than 255 pixels so I just
      // grab the first byte of each value
      let w = wfn.charCodeAt(position);
      let h = wfn.charCodeAt(position + 2);
      position += 4;

      let letter = {
        width: w,
        height: h,
        points: [],
      }
      // get the total number of bytes needed to contain the width of letter
      let wBytes = Math.ceil(w / 8);
      for (let j = 0; j < h; j++) {
        let rowStr = '';
        for (let i = 0; i < wBytes; i++) {
          rowStr += wfn.charCodeAt(position + i).toString(2).padStart(8, '0');
        }
        rowStr = rowStr.substring(0, w);
        for (let k = 0; k < w; k++) {
          if (rowStr[k] == '1') {
            let point = { x: k, y: j };
            letter.points.push(point);
          }
        }
        // advance position to next row of characters' data
        position += wBytes;
      }
      return letter;
    }
    let font = {};
    for (let i = 0; i < 256; i++) {
      let letterName = String.fromCharCode(i);
      font[letterName] = getLetter();
    }
    return font;
  },

  _processSCI(sci, lineHeight) {
    // the total number of characters in file is located at the 5th byte
    let numberOfCharacters = sci.charCodeAt(4);

    // the character data always starts on the 9th (index 8) byte of SCI files. It's 2 bytes little endian
    let position = numberOfCharacters * 2 + 8;
    // returns an object with the width, height, and points representing the shape
    // of the letter
    let getLetter = () => {
      // the width and height of each letter are stored in 2 bytes each, both little endian.
      // I don't expect any letters to be wider or taller than 255 pixels so I just
      // grab the first byte of each value
      let w = sci.charCodeAt(position);
      let h = sci.charCodeAt(position + 1);
      position += 2;

      let letter = {
        width: w,
        height: h,
        points: [],
      }
      // get the total number of bytes needed to contain the width of letter
      let wBytes = Math.ceil(w / 8);
      for (let j = 0; j < h; j++) {
        let rowStr = '';
        for (let i = 0; i < wBytes; i++) {
          rowStr += sci.charCodeAt(position + i).toString(2).padStart(8, '0');
        }
        rowStr = rowStr.substring(0, w);
        for (let k = 0; k < w; k++) {
          if (rowStr[k] == '1') {
            let point = { x: k, y: j };
            letter.points.push(point);
          }
        }
        // advance position to next row of characters' data
        position += wBytes;
      }
      return letter;
    }
    let font = {};
    for (let i = 0; i < numberOfCharacters; i++) {
      let letterName = String.fromCharCode(i);
      font[letterName] = getLetter();
    }
    return font;
  },
  getFont(fontName) {
    if (!this._fonts[fontName]) {
      console.error(`No font exists with name "${fontName}".`);
      throw Error();
    }
    return this._fonts[fontName];
  }
};

export default FontManager;

class ImageStore {
  constructor(canvas, spriteData) {
    this._canvas = canvas;
    if (spriteData) {
      this._spriteData = spriteData;
    } else {
      let dims = canvas.getDimensions();
      // we default to treating the image as if it is the entire canvas
      this._spriteData = {
        x: 0,
        y: 0,
        width: dims.w,
        height: dims.h,
      };
    }
  }
  isReversed() {
    return this._spriteData.reverse == true;
  }
  isAnimation() {
    return this._spriteData.animation == true;
  }
  isRepeating() {
    return this._spriteData.repeat == true;
  }
  getTotalFrames() {
    return this._spriteData.frames || 1;
  }
  getDimensions() {
    return {
      w: this._spriteData.width,
      h: this._spriteData.height,
    }
  }
  getFramePosition(frameNumber) {
    if (this.isReversed()) {
      return {
        x: this._spriteData.x - this._spriteData.width * frameNumber,
        y: this._spriteData.y
      }
    }
    return {
      x: this._spriteData.x + this._spriteData.width * frameNumber,
      y: this._spriteData.y
    }
  }
  getPosition() {
    return {
      x: this._spriteData.x,
      y: this._spriteData.y
    }
  }
  // uses get committed color at point because imagestores have committed data
  // and should never have working data
  getColorAtPoint(xRound, yRound, xOffset, yOffset) {
    return this._canvas.getCommittedColorAtPoint(xRound, yRound, xOffset, yOffset);
  }
}

export default ImageStore;

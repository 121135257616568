export default {
  src: '/images/inventory/inventory.png',
  spriteInfo: {
    blank: {
      width: 20,
      height: 20,
      x: -20,
      y: 0,
    },
    money: {
      width: 20,
      height: 20,
      x: 0,
      y: 0,
    },
    pliers: {
      width: 20,
      height: 20,
      x: 20,
      y: 0,
    },
  }
}

import ImageManager from 'managers/ImageManager';
import GameObject from './GameObject';

class FixtureObject extends GameObject {
  constructor(parentGetter, screenObject) {
    super(parentGetter, screenObject);
    if (!screenObject) {
      throw Error('Fixture objects must have screenObject passed to constructor');
    }
  }
  getRequiredImages() {
    return []; // fixture images are loaded from the screen data
  }
  _grabImageStore() {
    this._imageStore = ImageManager.getScreenImage(this._state.imageName, this._state.subImageName);
  }
}

export default FixtureObject;

import CompositeBox from 'views/compositeBoxes/CompositeBox';
import PictureBox from 'views/canvasBoxes/PictureBox';
import ImageManager from 'managers/ImageManager';
import Box from 'views/canvasBoxes/Box';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import Button from 'views/compositeBoxes/Button';
import Row from 'views/compositeBoxes/Row';
import Column from 'views/compositeBoxes/Column';
import Style from 'style/Style';

class ScrollBar extends CompositeBox {
  constructor(
    onClick,
    height,
    arrowImage,
    topArrowOptions,
    bottomArrowOptions,
    stemBoxOptions,
    outerColumnOptions,
    outerBoxOptions) { // end of constructor arguments

    super();

    this._onClick = onClick;

    this._arrowImage = arrowImage;
    this._topArrowOptions = topArrowOptions;
    this._bottomArrowOptions = bottomArrowOptions;
    this._stemBoxOptions = stemBoxOptions;
    this._outerColumnOptions = outerColumnOptions;
    this._outerBoxOptions = outerBoxOptions;

    this._arrow = new PictureBox();
    this._arrow.drawImage(arrowImage);
    this._arrowBox = new Box(this._arrow);
    this._arrowBox.draw(this._topArrowOptions);
    this._arrowBox.addListener('mousedown', this.scrollUp.bind(this));
    this._arrowBox.setClickable(true);

    let arrowDims = this._arrowBox.getDimensions();
    let staffHeight = height - arrowDims.h * 2;

    this._emptyBox = new EmptyBox(arrowDims.w, staffHeight);
    this._stem = new Box(this._emptyBox);
    this._stem.draw(this._stemBoxOptions);

    this._arrow2 = new PictureBox();
    this._arrow2.drawImage(arrowImage, { flippedY: true });
    this._arrow2Box = new Box(this._arrow2);
    this._arrow2Box.draw(this._bottomArrowOptions);
    this._arrow2Box.addListener('mousedown', this.scrollDown.bind(this));
    this._arrow2Box.setClickable(true);

    this._totalColumn = new Column([this._arrowBox, this._stem, this._arrow2Box], this._outerColumnOptions);

    this._base = new Box(this._totalColumn);
    this._base.draw(this._outerBoxOptions);

  }
  scrollUp() {
    this._onClick(-1);
  }
  scrollDown() {
    this._onClick(1);
  }
  destroy() {
    this._arrow.destroy()
    this._arrowBox.destroy();
    this._emptyBox.destroy();
    this._stem.destroy();
    this._arrow2.destroy();
    this._arrow2Box.destroy();
    this._totalColumn.destroy();
    this._base.destroy();
  }
}

export default ScrollBar;

import config from 'config';

export default function(obj) {
  let mixin = {
    _focused: false,
    setFocused: function(bool) {
      this._focused = bool;
      if (bool && this._becameFocused) {
        this._becameFocused();
      }
      if (!bool && this._lostFocus) {
        this._lostFocus();
      }
    },
    isFocused: function() {
      return this._focused;
    }
    // this sets up the event listener on the document body

  };
  Object.assign(obj, mixin);
}

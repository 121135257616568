import ScreenController from 'screens/ScreenController';
import Lobby from 'screens/Lobby';
import MenuController from 'controllers/layerControllers/MenuController';
import GameLayer from 'views/layers/GameLayer';
import MouseManager from 'managers/MouseManager';
import TimeManager from 'managers/TimeManager';
import ImageManager from 'managers/ImageManager';
import GameObject from 'classes/gameObjects/GameObject';
import FixtureObject from 'classes/gameObjects/FixtureObject';
import AliasedObject from 'classes/gameObjects/AliasedObject';
import StateManager from 'managers/StateManager';
import Actions from 'classes/Actions';
import Speech from 'classes/Speech';

import officeData from 'screenData/office';

class Office extends ScreenController {
  _getDefaultEntry() {
    return 'doorEntry';
  }
  _getScreenData() {
    return officeData;
  }
  _getRequiredFaceImages() {
    return ['paulFace'];
  }
  _initializeNonFixtures() {
    this._addGameObject('background', new Background(() => this));
    this._addGameObject('desk', new Desk(() => this));
    this._addGameObject('chair1', new Chair1(() => this));
    this._addGameObject('chair2', new Chair2(() => this));
    this._addGameObject('character', new Character(() => this));
    this._addGameObject('paul', new Paul(() => this));
    this._addGameObject('sittingMan', new SittingMan(() => this));
    this._addGameObject('sittingPaul', new SittingPaul(() => this));
    this._addGameObject('door', new Door(() => this));
  }
  _loop() {
    if (!this._loaded) {
      return;
    }
    this._runActionQueues();
    this._setScales();
    if (this._shouldCheckExits()) {
      this._checkExits();
    }
    if (this._shouldCheckGeneralZones()) {
      this._checkGeneralZones();
    }
    this._draw();
  }
  _shouldCheckExits() {
    let selected = StateManager.getState('menu').getSelected();
    return selected != null;
  }
  _shouldCheckGeneralZones() {
    let selected = StateManager.getState('menu').getSelected();
    return selected != null;
  }
  _setInitialState() {
    let paulState = StateManager.getState('paul');
    if (paulState.hasBegunInterview()) {
      if (!paulState.hasSatDown()) {
        this.bothWalkInside();
      } else {
        this.walkInsideWhilePaulSitting();
      }
    } else {
      this.walkInside();
    }
  }
  walkInside() {
    this.getObject('character').move(this.getPoint('outsideDoor'));
    this.getObject('character').face('stop_d');
    this._setActionQueue(
      "main",
      [
        Actions.sleep(.5),
        Actions.method('open', 'door'),
        Actions.sleep(.5),
        Actions.pushToObjectQueue('character', Actions.walkFree(this.getPoint('insideDoor'))),
        Actions.blocker('isAtPoint', 'character', [this.getPoint('insideDoor')]),
        Actions.method('close', 'door'),
        Actions.endWait(),
      ]
    );
  }
  bothWalkInside() {
    this.getObject('character').move(this.getPoint('outsideDoor'));
    this.getObject('character').face('stop_d');
    this.getObject('paul').move(this.getPoint('furtherOutsideDoor'));
    this.getObject('paul').face('stop_d');
    this._setActionQueue(
      "main",
      [
        Actions.sleep(.5),
        Actions.method('open', 'door'),
        Actions.sleep(.5),
        Actions.pushToObjectQueue('character', Actions.walkFree(this.getPoint('insideDoor'))),
        Actions.pushToObjectQueue('character', Actions.walk(this.getPoint('welcomeSpot'), 'stop_u')),
        Actions.pushToObjectQueue('paul', Actions.walkFree(this.getPoint('insideDoor'))),
        Actions.blocker('isAtPoint', 'paul', [this.getPoint('insideDoor')]),
        Actions.method('close', 'door'),
        Actions.pushToObjectQueue('paul', Actions.walk(this.getPoint('paulSit'), 'stop_rd', Actions.callback('screen', 'paulSit'))),
        Actions.blocker('isSitting', 'paul'),
        Actions.endWait(),
      ]
    );
  }
  lowerLighting() {
    this.setFilter([-75, -75, 0]);
  }
  raiseLighting() {
    this._setActionQueue(
      'filter',
      [
        Actions.filter([-75, -75, 0]),
        Actions.sleep(.1),
        Actions.filter([-50, -50, 0]),
        Actions.sleep(.1),
        Actions.filter([-25, -25, 0]),
        Actions.sleep(.1),
        Actions.filter([0, 0, 0]),
      ]
    );
  }
  walkInsideWhilePaulSitting() {
    let paul = this.getObject('paul');
    let sittingPaul = this.getObject('sittingPaul');
    paul.hide();
    sittingPaul.show();
    this.walkInside();
  }
  paulSit() {
    let paul = this.getObject('paul');
    let sittingPaul = this.getObject('sittingPaul');
    paul.hide();
    sittingPaul.show();
    StateManager.getState('paul').setHasSatDown(true);
  }
  _handleExit(exitName) {
  }
  _makeLayer() {
    // switchCursor inherited from screencontroller, pushMenuController inherited from LayerController
    this._layer = new GameLayer(
      this._pushMenuController.bind(this),
      this._switchCursor.bind(this),
      this._leftClicked.bind(this),
      this._drawableObjectsArray,
    );
  }
  beginTalking() {
    let ch = this.getObject('character');
    ch.clear();
    this._setActionQueue(
      "main",
      [
        Actions.wait(),
        Actions.method('clear', 'character'),
        Actions.pushToObjectQueue('character', Actions.walk(this.getPoint('chair1Sit'), 'stop_lu')),
        Actions.blocker('isAtPoint', 'character', [this.getPoint('chair1Sit')]),
        Actions.multi(
          [
            Actions.method('hide', 'character'),
            Actions.method('show', 'sittingMan'),
          ]
        ),
        Actions.sleep(.5),
        Actions.converse(['paul.interviewTop']),
        Actions.sleep(.5),
        Actions.multi(
          [
            Actions.method('show', 'character'),
            Actions.method('hide', 'sittingMan'),
          ]
        ),
        Actions.endWait(),
      ]
    );
  }
  characterIsntWalking() {
    return !this.getObject('character').isWalking();
  }
  finishSitting() {
    this._setWaiting(true);
    this.getObject('character').hide();
    this.getObject('sittingMan').show();
  }
  beginLeavingRoom() {
    let ch = this.getObject('character');
    ch.clear();
    ch.pushToActionQueue(Actions.walk(this.getPoint('insideDoor'), 'stop_u', Actions.callback('screen', 'finishLeavingRoom')));
  }
  finishLeavingRoom() {
    this._setActionQueue(
      "main",
      [
        Actions.wait(),
        Actions.method('open', 'door'),
        Actions.sleep(.5),
        Actions.pushToObjectQueue('character', Actions.walkFree(this.getPoint('outsideDoor'))),
        Actions.blocker('isAtPoint', 'character', [this.getPoint('outsideDoor')]),
        Actions.method('close', 'door'),
        Actions.sleep(.5),
        Actions.method('enterLobby', 'screen')
      ]
    );
  }
  enterLobby() {
    this._getParentController().changeScreen(Lobby.id, 'officeDoor');
  }
}

Office.id = "Office";

export default Office;

class Character extends GameObject {
  getRequiredImages() {
    return ['interviewer'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 0,
        y: 0,
      },
      imageOffset: {
        x: .5,
        y: .953
      },
      name: 'character',
      basePoint: .953,
      isScalable: true,
      isDrawable: true,
      imageName: 'interviewer',
      subImageName: 'stop_l',
      isSitting: false,
    });
    this._hasChanged = true;
  }
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["You can do that later."]);
        return true;
        break;
      }
      case 'eye': {
        this.showText(["That's yourself."]);
        return true;
        break;
      }
      case 'talk': {
        this.showText(["You rehearse an amusing anecdote."]);
        return true;
        break;
      }
    }
  }
  isSitting() {
    return this._state.isSitting;
  }
  handleGeneralZoneEntry(name) {
    // switch (name) {
    //   case 'stopServer': {
    //     if (!StateManager.getState('rockroom').isAllowedInRocks()) {
    //       this._getParent().beginConfrontation();
    //     }
    //   }
    // }
  }
  handleGeneralZoneExit(name) {
    console.log('exited', name);
  }
  show() {
    this.pushToActionQueue(Actions.move(this.getPoint('chair1Sit')));
    this._state.isSitting = false;
  }
  hide() {
    console.log(JSON.stringify(this._state.actionQueue));
    this.pushToActionQueue(Actions.move({x: -1000, y: -1000 }));
    this._state.isSitting = true;
  }
}

class Paul extends GameObject {
  getRequiredImages() {
    return ['paul'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 0,
        y: 0,
      },
      imageOffset: {
        x: .5,
        y: .953
      },
      name: 'character',
      basePoint: .953,
      isScalable: true,
      isDrawable: true,
      imageName: 'paul',
      subImageName: 'stop_l',
      isSitting: false,
    });
    this._hasChanged = true;
  }
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["He probably doesn't want that."]);
        return true;
        break;
      }
      case 'eye': {
        this.showText(["That's the applicant, Paul."]);
        return true;
        break;
      }
      case 'talk': {
        this._getParent().beginTalking();
        return true;
        break;
      }
    }
  }
  handleInventoryClick(type) {
    switch (type) {
      case 'money': {
        this.showText(["The company will pay him. Keep your money."])
        return true;
        break;
      }
    }
  }
  isSitting() {
    return this._state.isSitting;
  }
  show() {
    this.pushToActionQueue(Actions.move(this.getPoint('paulSit')));
    this._state.isSitting = false;
  }
  hide() {
    this.pushToActionQueue(Actions.move({x: -1000, y: -1000 }));
    this._state.isSitting = true;
  }
}

class SittingMan extends GameObject {
  getRequiredImages() {
    return ['sittingMan'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: -1000,
        y: -1000,
      },
      name: 'sittingMan',
      basePoint: 1,
      isScalable: false,
      isDrawable: true,
      imageName: 'sittingMan',
      subImageName: 'sitting',
    });
    this._hasChanged = true;
  }
  getDefaultAlias() {
    return 'character';
  }
  show() {
    this.pushToActionQueue(Actions.move({x: 188, y: 123 }))
  }
  hide() {
    this.pushToActionQueue(Actions.move({x: -1000, y: -1000 }));
  }
}

class SittingPaul extends GameObject {
  getRequiredImages() {
    return ['sittingPaulOffice'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: -1000,
        y: -1000,
      },
      name: 'sittingPaul',
      basePoint: .835,
      isScalable: false,
      isDrawable: true,
      imageName: 'sittingPaulOffice',
      subImageName: 'sitting',
    });
    this._hasChanged = true;
  }
  getDefaultAlias() {
    return 'paul';
  }
  show() {
    this.pushToActionQueue(Actions.move({x: 148, y: 94 }))
  }
  hide() {
    this.pushToActionQueue(Actions.move({x: -1000, y: -1000 }));
  }
}


class Door extends GameObject {
  getRequiredImages() {
    return ['officeDoor'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 54,
        y: 66,
      },
      name: 'door',
      basePoint: .7,
      isScalable: false,
      isDrawable: true,
      imageName: 'officeDoor',
      subImageName: 'closed',
    });
    this._hasChanged = true;
  }
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().beginLeavingRoom();
        return true;
        break;
      }
      case 'eye': {
        this._getParent().showText(["That's the door to the lobby."]);
        return true;
        break;
      }
      case 'talk': {
        this._getParent().showText(["It says nothing."]);
        return true;
        break;
      }
    }
  }
  open() {
    this.setImage('officeDoor', 'open');
  }
  close() {
    this.setImage('officeDoor', 'close');
  }
}

class Background extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().showText(["Just a bunch of boring office stuff."]);
        return true;
        break;
      }
      case 'eye': {
        this._getParent().showText(["It's your beautiful office."]);
        return true;
        break;
      }
      case 'talk': {
        this._getParent().showText(["It says nothing."]);
        return true;
        break;
      }
    }
  }
}

class Desk extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["It's empty."]);
        return true
        break;
      }
      case 'eye': {
        this.showText(["It's a desk."]);
        return true
        break;
      }
      case 'talk': {
        this.showText(["It says nothing."]);
        return true;
        break;
      }
    }
  }
}

class Chair1 extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["No point in doing that."]);
        return true
        break;
      }
      case 'eye': {
        this.showText(["That's the chair for office guests."]);
        return true;
        break;
      }
      case 'talk': {
        this.showText(["It says nothing."]);
        return true;
        break;
      }
    }
  }
}

class Chair2 extends AliasedObject {
  handleActionClick(type) {
    switch (type) {
      case 'hand': {
        if (this.getObject('paul').isSitting()) {
          this._getParent().beginTalking();
        } else {
          this.showText(["Nothing to do there until the interview has started."]);
        }
        return true;
        break;
      }
      case 'eye': {
        this.showText(["It's your chair."]);
        return true;
        break;
      }
      case 'talk': {
        this.showText(["It says nothing."]);
        return true;
        break;
      }
    }
  }
}

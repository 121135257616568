import Layer from 'views/layers/Layer';
import config from 'config';
import MouseManager from 'managers/MouseManager';

class TestLayer extends Layer {
  constructor() {
    super();
  }
  destroy() {

  }
}

export default TestLayer;

export default {
  "name": "rockroom",
  "points": {
    "rock2Point": {
      "x": 162,
      "y": 171
    },
    "serverTalkSpot": {
      "x": 221,
      "y": 177
    },
    "exitAOut": {
      "x": 235,
      "y": 52
    },
    "exitAIn": {
      "x": 234,
      "y": 69
    },
    "rock1Point": {
      "x": 239,
      "y": 139
    }
  },
  "fixtures": {
    "rockroom_fixture_background": {
      "aliasFor": "background",
      "alphaKey": 255,
      "width": 320,
      "height": 200,
      "name": "rockroom_fixture_background",
      "position": {
        "x": 0,
        "y": 0
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "basePoint": 0,
      "imageName": "rockroom",
      "subImageName": "rockroom_fixture_background"
    },
    "rockroom_fixture_0": {
      "aliasFor": "background",
      "basePoint": 0.9838709677419355,
      "width": 28,
      "height": 62,
      "x": 221,
      "y": 0,
      "alphaKey": 254,
      "name": "rockroom_fixture_0",
      "position": {
        "x": 221,
        "y": 0
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "rockroom",
      "subImageName": "rockroom_fixture_0"
    },
    "rockroom_fixture_1": {
      "aliasFor": "rock1",
      "basePoint": 0.8225806451612904,
      "width": 33,
      "height": 62,
      "x": 209,
      "y": 77,
      "alphaKey": 253,
      "name": "rockroom_fixture_1",
      "position": {
        "x": 209,
        "y": 77
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "rockroom",
      "subImageName": "rockroom_fixture_1"
    },
    "rockroom_fixture_2": {
      "aliasFor": "rock2",
      "basePoint": 0.8414634146341463,
      "width": 22,
      "height": 82,
      "x": 113,
      "y": 102,
      "alphaKey": 252,
      "name": "rockroom_fixture_2",
      "position": {
        "x": 113,
        "y": 102
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "rockroom",
      "subImageName": "rockroom_fixture_2"
    },
    "rockroom_fixture_3": {
      "aliasFor": "rock2",
      "basePoint": 0.8395061728395061,
      "width": 25,
      "height": 81,
      "x": 135,
      "y": 102,
      "alphaKey": 251,
      "name": "rockroom_fixture_3",
      "position": {
        "x": 135,
        "y": 102
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "rockroom",
      "subImageName": "rockroom_fixture_3"
    }
  },
  "zones": {
    "walkAreas": [
      {
        "name": "walk",
        "points": [
          {
            "x": 111,
            "y": 98
          },
          {
            "x": 193,
            "y": 98
          },
          {
            "x": 223,
            "y": 64
          },
          {
            "x": 249,
            "y": 64
          },
          {
            "x": 292,
            "y": 195
          },
          {
            "x": 24,
            "y": 196
          }
        ],
        "type": "walk",
        "extraData": ""
      },
      {
        "name": "rockSpace1",
        "points": [
          {
            "x": 209,
            "y": 120
          },
          {
            "x": 220,
            "y": 116
          },
          {
            "x": 233,
            "y": 116
          },
          {
            "x": 244,
            "y": 123
          },
          {
            "x": 245,
            "y": 133
          },
          {
            "x": 235,
            "y": 138
          },
          {
            "x": 224,
            "y": 144
          },
          {
            "x": 213,
            "y": 140
          },
          {
            "x": 206,
            "y": 128
          }
        ],
        "type": "negativeWalk",
        "extraData": ""
      },
      {
        "name": "rockSpace2",
        "points": [
          {
            "x": 112,
            "y": 159
          },
          {
            "x": 106,
            "y": 169
          },
          {
            "x": 114,
            "y": 180
          },
          {
            "x": 130,
            "y": 191
          },
          {
            "x": 144,
            "y": 186
          },
          {
            "x": 158,
            "y": 176
          },
          {
            "x": 163,
            "y": 165
          },
          {
            "x": 142,
            "y": 157
          }
        ],
        "type": "negativeWalk",
        "extraData": ""
      }
    ],
    "exits": {
      "exitA": {
        "name": "exitA",
        "points": [
          {
            "x": 221,
            "y": 52
          },
          {
            "x": 255,
            "y": 51
          },
          {
            "x": 256,
            "y": 68
          },
          {
            "x": 217,
            "y": 68
          }
        ],
        "type": "exit",
        "extraData": ""
      }
    },
    "general": {
      "stopServer": {
        "name": "stopServer",
        "points": [
          {
            "x": 191,
            "y": 78
          },
          {
            "x": 267,
            "y": 89
          },
          {
            "x": 298,
            "y": 199
          },
          {
            "x": 13,
            "y": 198
          },
          {
            "x": 100,
            "y": 86
          }
        ],
        "type": "general",
        "extraData": ""
      }
    },
    "depth": [
      {
        "name": ".6",
        "points": [
          {
            "x": 101,
            "y": 70
          },
          {
            "x": 264,
            "y": 70
          },
          {
            "x": 274,
            "y": 102
          },
          {
            "x": 79,
            "y": 99
          }
        ],
        "type": "depth",
        "extraData": ""
      },
      {
        "name": ".8",
        "points": [
          {
            "x": 272,
            "y": 101
          },
          {
            "x": 289,
            "y": 145
          },
          {
            "x": 47,
            "y": 150
          },
          {
            "x": 79,
            "y": 101
          },
          {
            "x": 79,
            "y": 99
          }
        ],
        "type": "depth",
        "extraData": ""
      },
      {
        "name": "1",
        "points": [
          {
            "x": 46,
            "y": 150
          },
          {
            "x": 288,
            "y": 144
          },
          {
            "x": 301,
            "y": 200
          },
          {
            "x": 12,
            "y": 200
          }
        ],
        "type": "depth",
        "extraData": ""
      },
      {
        "name": ".5",
        "points": [
          {
            "x": 113,
            "y": 39
          },
          {
            "x": 257,
            "y": 35
          },
          {
            "x": 264,
            "y": 71
          },
          {
            "x": 101,
            "y": 70
          }
        ],
        "type": "depth",
        "extraData": ""
      }
    ]
  }
}
import LayerController from 'controllers/layerControllers/LayerController';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';
import TextLayer from 'views/layers/TextLayer';

class TextController extends LayerController {
  constructor(textArray) {
    super();
    this._textIndex = 0;
    this._textArray = textArray;
  }
  _makeLayer() {
    this._layer = new TextLayer(
      this._textArray[this._textIndex],
      this.next.bind(this),
    );
  }
  keyPressed(key) {
    this.next();
  }
  next() {
    this._textIndex++;
    if (this._textIndex > this._textArray.length - 1) {
      this.popThisController();
      return;
    } else {
      this._layer.setText(this._textArray[this._textIndex]);
    }
  }
  loop() {
    //
  }
  becameActive() {
    MouseManager.setCursor('pointer');
  }
}

export default TextController;

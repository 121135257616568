import ScreenController from 'screens/ScreenController';
import MenuController from 'controllers/layerControllers/MenuController';
import GameLayer from 'views/layers/GameLayer';
import MouseManager from 'managers/MouseManager';
import TimeManager from 'managers/TimeManager';
import ImageManager from 'managers/ImageManager';
import GameObject from 'classes/gameObjects/GameObject';
import FixtureObject from 'classes/gameObjects/FixtureObject';
import AliasedObject from 'classes/gameObjects/AliasedObject';
import StateManager from 'managers/StateManager';
import Actions from 'classes/Actions';
import Speech from 'classes/Speech';
import OuterRockroom from 'screens/OuterRockroom';

import rockroomData from 'screenData/rockroom';

class Rockroom extends ScreenController {
  _getDefaultEntry() {
    return 'exitA';
  }
  _getScreenData() {
    return rockroomData;
  }
  _getRequiredFaceImages() {
    return ['guardFace'];
  }
  _initializeNonFixtures() {
    this._addGameObject('background', new Background(() => this));
    this._addGameObject('rock1', new Rock1(() => this));
    this._addGameObject('rock2', new Rock2(() => this));
    this._addGameObject('slidingDoor', new SlidingDoor(() => this));
    this._addGameObject('fan', new Fan(() => this));
    this._addGameObject('character', new Character(() => this));
    this._addGameObject('server', new Server(() => this));
  }
  _loop() {
    if (!this._loaded) {
      return;
    }
    this._runActionQueues();
    this._setScales();
    if (this._shouldCheckExits()) {
      this._checkExits();
    }
    if (this._shouldCheckGeneralZones()) {
      this._checkGeneralZones();
    }
    this._draw();
  }
  _shouldCheckExits() {
    let selected = StateManager.getState('menu').getSelected();
    // if null then we are waiting
    return selected != null;
  }
  _shouldCheckGeneralZones() {
    let selected = StateManager.getState('menu').getSelected();
    return selected != null;
  }
  characterInside() {
    let point = this.getPoint('exitAIn');
    let pos = this.getObject('character').getPosition();
    return pos.x == point.x && pos.y == point.y;
  }
  _setInitialState() {
    if (this._state.enteredAt == 'exitA') {
      this.getObject('character').move(this.getPoint('exitAOut'));
      this.getObject('character').face('stop_d');
    }
    this.getObject('server').move(this.getPoint('serverTalkSpot'));
    this.setFilter([-255, -255, -100]);
    this._setActionQueue(
      "main",
      [
        Actions.sleep(1),
        Actions.multi([
          Actions.method('millAbout', 'server'),
          Actions.method('raiseLighting', 'screen'),
        ]),
        Actions.sleep(1.5),
        Actions.text(['The door is opening!']),
        Actions.method('open', 'slidingDoor'),
        Actions.sleep(.5),
        Actions.pushToObjectQueue('character', Actions.walkFree(this.getPoint('exitAIn'))),
        Actions.blocker('characterInside'),
        Actions.method('close', 'slidingDoor'),
        Actions.sleep(.5),
        Actions.method('face', 'character', ['stop_r']),
        Actions.sleep(.5),
        Actions.method('face', 'character', ['stop_d']),
        Actions.sleep(.5),
        Actions.method('face', 'character', ['stop_l']),
        Actions.sleep(.5),
        Actions.method('face', 'character', ['stop_d']),
        Actions.endWait(),
      ]
    );
  }
  _handleExit(exitName) {
    switch (exitName) {
      case 'exitA': {
        this._setActionQueue(
          "main",
          [
            Actions.wait(),
            Actions.method('clear', 'character'),
            Actions.method('face', 'character', ['stop_u']),
            Actions.method('open', 'slidingDoor'),
            Actions.sleep(.5),
            Actions.pushToObjectQueue('character', Actions.walkFree(this.getPoint('exitAOut'))),
            Actions.sleep(.5),
            Actions.method('close', 'slidingDoor'),
            Actions.sleep(.5),
            Actions.method('exitToOutside', 'screen'),
          ]
        );
      }
    }
  }
  _makeLayer() {
    // switchCursor inherited from screencontroller, pushMenuController inherited from LayerController
    this._layer = new GameLayer(
      this._pushMenuController.bind(this),
      this._switchCursor.bind(this),
      this._leftClicked.bind(this),
      this._drawableObjectsArray,
    );
  }
  raiseLighting() {
    this._setActionQueue(
      'filter',
      [
        Actions.filter([-75, -75, 0]),
        Actions.sleep(.1),
        Actions.filter([-50, -50, 0]),
        Actions.sleep(.1),
        Actions.filter([-25, -25, 0]),
        Actions.sleep(.1),
        Actions.filter([0, 0, 0]),
      ]
    );
  }
  talkToGuard() {
    if (!StateManager.getState('rockroom').isAllowedInRocks()) {
      this.beginConfrontation();
      return;
    }
    this._setActionQueue(
      'main',
      [
        Actions.wait(),
        Actions.multi([
          Actions.method('clear', 'character'),
          Actions.method('clear', 'server'),
          Actions.pushToObjectQueue('character', Actions.walk({x: 203, y: 91 })),
          Actions.pushToObjectQueue('character', Actions.face('stop_d')),
          Actions.pushToObjectQueue('server', Actions.walk({x: 197, y: 104 })),
          Actions.pushToObjectQueue('server', Actions.face('stop_u')),
        ]),
        Actions.blocker('charactersHaveStopped'),
        Actions.sleep(.5),
        Actions.converse(['test.start']),
        Actions.sleep(1),
        Actions.method('millAbout', 'server'),
        Actions.endWait(),
      ]
    )
  }
  beginConfrontation() {
    this._setActionQueue(
      'main',
      [
        Actions.wait(),
        Actions.multi([
          Actions.method('clear', 'character'),
          Actions.method('clear', 'server'),
          Actions.pushToObjectQueue('character', Actions.walk({x: 203, y: 91 })),
          Actions.pushToObjectQueue('character', Actions.face('stop_d')),
          Actions.pushToObjectQueue('server', Actions.walk({x: 197, y: 104 })),
          Actions.pushToObjectQueue('server', Actions.face('stop_u')),
        ]),
        Actions.blocker('charactersHaveStopped'),
        Actions.sleep(.5),
        Actions.speak(
          'Guard',
          'guardFace',
          [
            new Speech('...', 'angry', false),
            new Speech(`These rocks are off limits`, 'scared')
          ]
        ),
        Actions.converse(['test.start']),
        Actions.sleep(1),
        Actions.multi([
          Actions.method('clear', 'character'),
          Actions.method('clear', 'server'),
          Actions.pushToObjectQueue('character', Actions.walk({x: 217, y: 73 })),
          Actions.pushToObjectQueue('character', Actions.face('stop_d')),
        ]),
        Actions.blocker('charactersHaveStopped'),
        Actions.method('millAbout', 'server'),
        Actions.endWait(),
      ]
    )
  }
  charactersHaveStopped() {
    return !this.getObject('character').isMoving() && !this.getObject('server').isMoving();
  }
  exitToOutside() {
    this._getParentController().changeScreen(OuterRockroom.id, 'doorEntry');
  }
}

Rockroom.id = "Rockroom";

export default Rockroom;

class Server extends GameObject {
  getRequiredImages() {
    return ['man'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 0,
        y: 0,
      },
      imageOffset: {
        x: .5,
        y: .953
      },
      name: 'server',
      basePoint: .953,
      isScalable: true,
      isDrawable: true,
      imageName: 'man',
      subImageName: 'stop_d',
    });
    this._hasChanged = true;
  }
  handleClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["Don't touch people, weirdo."]);
        break;
      }
      case 'eye': {
        this.showText(["That's the guard of these rocks."]);
        break;
      }
      case 'talk': {
        this._getParent().talkToGuard();
        break;
      }
    }
  }
  millAbout() {
    this._state.actionQueue = [
      Actions.walk(this.getPoint('serverTalkSpot')),
      Actions.sleep(1),
      Actions.walk({ x: 73, y: 182 }),
      Actions.sleep(1),
      Actions.walk({ x: 102, y: 121 }),
      Actions.sleep(1),
      Actions.walk({ x: 179, y: 127 }),
      Actions.sleep(1),
      Actions.method('millAbout', 'server')
    ];
  }
}

class Character extends GameObject {
  getRequiredImages() {
    return ['man'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 0,
        y: 0,
      },
      imageOffset: {
        x: .5,
        y: .953
      },
      name: 'character',
      basePoint: .953,
      isScalable: true,
      isDrawable: true,
      imageName: 'man',
      subImageName: 'stop_d',
    });
    this._hasChanged = true;
  }
  handleClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["You can do that later."]);
        break;
      }
      case 'eye': {
        this.showText(["That's yourself."]);
        break;
      }
      case 'talk': {
        this.showText(["You rehearse an amusing anecdote."]);
        break;
      }
    }
  }
  handleGeneralZoneEntry(name) {
    switch (name) {
      case 'stopServer': {
        if (!StateManager.getState('rockroom').isAllowedInRocks()) {
          this._getParent().beginConfrontation();
        }
      }
    }
  }
  handleGeneralZoneExit(name) {
    console.log('exited', name);
  }
}

class SlidingDoor extends GameObject {
  getRequiredImages() {
    return ['slidingDoor'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 213,
        y: 24,
      },
      name: 'slidingDoor',
      basePoint: .7708,
      drawable: true,
      imageName: 'slidingDoor',
      subImageName: 'closed',
    });
    this._hasChanged = true;
  }
  handleClick(type) {
    switch (type) {
      case 'hand': {
        this.getObject('character').pushToActionQueue(Actions.walk(this.getPoint("exitAOut")));
        break;
      }
      case 'eye': {
        this.showText(["That's a nicely kept door and it slides open."]);
        break;
      }
      case 'talk': {
        this.showText(["The door says nothing."]);
        break;
      }
    }
  }
  open() {
    this.setImage('slidingDoor', 'open');
  }
  close() {
    this.setImage('slidingDoor', 'close');
  }
}

class Fan extends GameObject {
  getRequiredImages() {
    return ['fan'];
  }
  _setDefaultState() {
    Object.assign(this._state, {
      position: {
        x: 118,
        y: 0,
      },
      name: 'fan',
      basePoint: 1,
      drawable: true,
      imageName: 'fan',
      subImageName: 'still',
    });
    this._hasChanged = true;
  }
  handleClick(type) {
    switch (type) {
      case 'hand': {
        this.showText(["You can't reach that!"])
        break;
      }
      case 'eye': {
        this.showText(["That's a fan which doesn't seem to be moving."]);
        break;
      }
      case 'talk': {
        this.showText(["The fan isn't talking."]);
        break;
      }
    }
  }
}

class Background extends AliasedObject {
  handleClick(type) {
    switch (type) {
      case 'hand': {
        this._getParent().showText(["This room feels clean."]);
        break;
      }
      case 'eye': {
        this._getParent().showText(["You're in a large room with two green boulders sitting on the ground."]);
        break;
      }
      case 'talk': {
        this._getParent().showText(["It says nothing."]);
        break;
      }
    }
  }
}

class Rock1 extends AliasedObject {
  handleClick(type) {
    switch (type) {
      case 'hand': {
        let character = this.getObject('character');
        character.clear();
        character.pushToActionQueue(Actions.walk(this.getPoint('rock1Point'), 'stop_lu', Actions.callback('rock1', 'touchRock')));
        break;
      }
      case 'eye': {
        this.showText(["It's a boulder."]);
        break;
      }
      case 'talk': {
        this.showText(["It says nothing."]);
        break;
      }
    }
  }
  touchRock() {
    this._getParent().showText(["You feel the rock and it is good."]);
  }
}

class Rock2 extends AliasedObject {
  handleClick(type) {
    switch (type) {
      case 'hand': {
        let character = this.getObject('character');
        character.clear();
        character.pushToActionQueue(Actions.walk(this.getPoint('rock2Point'), 'stop_lu', Actions.callback('rock2', 'touchRock')));
        break;
      }
      case 'eye': {
        this._getParent().showText(["It's a boulder."]);
        break;
      }
      case 'talk': {
        this._getParent().showText(["It says nothing."]);
        break;
      }
    }
  }
  touchRock() {
    this._getParent().showText(["You feel the rock and it is good."]);
  }
}

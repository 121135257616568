import Style from 'style/Style';
import colors from 'style/colors';

export default {
  face: {
    mainColumn: Style.makeColumnRowStyle('center', 0),
    label: {
      fontName: 'main',
      padding: 2,
      centered: true,
      //lineHeight: 20,
      color: colors.WHITE,
    },
    labelBox: {
      centered: true,
      minWidth: 98,
      maxWidth: 98,
      backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      borders: [
      ],
    },
    faceBox: {
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    },
    faceBase: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
      shadow: Style.makeBoxShadow(colors.SHADOW, 1, 1),
    }
  },
  words: {
    text: {
      fontName: '300',
      lineHeight: 12,
      maxWidth: 150,
      minWidth: 150,
      padding: 0,
      centered: true,
      //lineHeight: 20,
      color: Style.darken(colors.MAIN_COLOR, 130),
      shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 20)),
    },
    textBox: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 3, colors.MAIN_COLOR, true, 5, true, 1, false),
      ],
      shadow: Style.makeBoxShadow(colors.SHADOW, 1, 1),
      padding: 10,
    }
  },
  mainRow: Style.makeColumnRowStyle('start', 2),
  base: {
  },
}

export default {
  src: '/images/screens/rockroom/slidingDoor.png',
  spriteInfo: {
    closed: {
      width: 44,
      height: 48,
      x: 0,
      y: 0,
    },
    opened: {
      width: 44,
      height: 48,
      x: 44 * 4,
      y: 0,
    },
    open: {
      width: 44,
      height: 48,
      x: 0,
      y: 0,
      animation: true,
      repeat: false,
      frames: 5,
    },
    close: {
      width: 44,
      height: 48,
      x: 44 * 4,
      y: 0,
      animation: true,
      repeat: false,
      frames: 5,
      reverse: true,
    },
  }
}

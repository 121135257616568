export default {
  _data: {
    isAllowedInRocks: false,
  },
  isAllowedInRocks: function() {
    return this._data.isAllowedInRocks;
  },
  setAllowedInRocks: function(bool) {
    this._data.isAllowedInRocks = bool;
  },
}

import eventMixin from 'mixins/eventMixin';
import LayoutManager from 'managers/LayoutManager';
import ImageManager from 'managers/ImageManager';
import PictureBox from 'views/canvasBoxes/PictureBox';
import StateManager from 'managers/StateManager';
import Canvas from 'views/Canvas';
import config from 'config';

let MouseManager = {
  _lastPosition: null,
  init: function() {
    this._cursorImages = ImageManager.getImageSet('cursors');
    this._setupCursor();
    this._setupListeners();
    eventMixin(this);
  },
  setReceiver: function(receiver) {
    this._receiver = receiver;
  },
  _setupCursor: function() {
    this._cursorPosition = {
      x: 0,
      y: 0,
    };
    this._cursor = new PictureBox();
    this.setCursor();
    this._setDimensionsAndPosition();
    LayoutManager.addToCursorLayer(this._cursor);
  },
  setCursor: function(cursorName) {
    let image;
    if (cursorName) {
      image = this._cursorImages[cursorName];
    } else {
      let selected = StateManager.getState('menu').getSelected();
      if (selected == 'inventoryItem') {
        selected = StateManager.getState('inventory').getSelectedItem();
      }
      if (selected == null) {
        selected = 'wait';
      }
      image = this._cursorImages[selected];
    }
    this._cursor.drawImage(image);
  },
  _setupListeners: function() {
    let screen = LayoutManager.getScreen();
    // we don't do a 'mousedown' because the layers will handle those directly
    screen.addEventListener('click', this._clicked.bind(this));
    screen.addEventListener('mousemove', this._mouseMoved.bind(this));
    screen.addEventListener('mouseup', this._mouseUp.bind(this));
  },
  _clicked: function(e) {
    let pos = this.getLogicalPosition(e);
    LayoutManager.focusScreen();
  },
  eventIsRightClick: function(e) {
    return (e.which === 3 || e.button === 2);
  },
  _mouseMoved: function(e) {
    let pos = this.getLogicalPosition(e);
    let diff = {
      x: pos.x - this._cursorPosition.x,
      y: pos.y - this._cursorPosition.y
    }
    this._cursorPosition.x = pos.x;
    this._cursorPosition.y = pos.y;
    this._setDimensionsAndPosition();
    LayoutManager.focusScreen();
    if (this._receiver) {
      this._receiver.handleMouseMove({
        position: pos,
        difference: diff,
      });
    };
    if (config.settings.testingScreen) {
      document.title = `${pos.x}, ${pos.y}`;
    }
  },
  _mouseUp: function(e) {
    let pos = this.getLogicalPosition(e);
    LayoutManager.focusScreen();
    if (this._receiver) {
      this._receiver.handleMouseUp(pos);
    }
  },
  getLogicalPosition: function(e) {
    let magnification = LayoutManager.getMagnification();
    let clientX = e.clientX;
    let clientY = e.clientY;
    let rect = LayoutManager.getScreen().getBoundingClientRect();
    let x = clientX - rect.left;
    let y = clientY - rect.top;

    x = Math.floor(x / magnification);
    y = Math.floor(y / magnification);
    return { x, y };
  },
  eventHandler: function(detail) {
    switch (detail.type) {
      case LayoutManager.MAGNIFY: {
        this._setDimensionsAndPosition();
        break;
      }
    }
  },
  _setDimensionsAndPosition: function() {
    let magnification = LayoutManager.getMagnification();
    let { x, y } = this._cursorPosition;
    this._cursor.setPosition(x,y);
  }
}

export default MouseManager;

import config from 'config';
import eventMixin from 'mixins/eventMixin';
import Layer from 'views/layers/Layer';

let LayoutManager = {
  MAGNIFY: 'layout_magnify',
  init: function() {
    eventMixin(this);
    this._magnification = config.layout.magnification;
    this._screen = document.getElementById(config.layout.screenId);
    this._setScreenAppearance();

    // the layer stack is a stack of layers. The top layer will receive mouse click
    // and will be passed key events.  The top most layer will always be the one
    // that holds the cursor on it, but it will not be in the layer stack
    this._layerStack = [];

    this._setLayers();

    this._addResizeListener();
    this._preventContextMenu();
  },
  _setLayers: function() {
    // the cursor layer is excluded from the layer stack because it's always
    // up on screen regardless
    this._addCursorLayer();
  },
  _addCursorLayer: function() {
    this._cursorLayer = new Layer();
    this._cursorLayer.setZIndex(200);
    this._cursorLayer.setClickable(false);
    this._screen.appendChild(this._cursorLayer.getElement());
  },
  addToCursorLayer: function(box) {
    this._cursorLayer.addChild(box);
  },
  pushLayer: function(layer) {
    this._layerStack.push(layer);
    this._screen.appendChild(layer.getElement());
  },
  popLayer: function() {
    this._layerStack.pop();
  },
  getCursorLayer: function() {
    return this._cursorLayer;
  },
  getMagnification: function() {
    return this._magnification;
  },
  getScreen: function() {
    return this._screen;
  },
  getScreenSize: function() {
    return { w: config.layout.screenWidth, h: config.layout.screenHeight };
  },
  getTopLayer: function() {
    return this._layerStack[this._layerStack.length - 1];
  },
  focusScreen: function() {
    this._screen.focus();
  },
  setMagnification: function(val) {
    this._magnification = val;
    this.fireEvent({ type: this.MAGNIFY });
  },
  eventHandler: function(detail) {
    if (detail.type == this.MAGNIFY) {
      this._setScreenAppearance();
    }
  },
  _setScreenAppearance: function() {
    this._screen.style.position = 'relative';
    this._screen.style.overflow = 'hidden';
    this._screen.style.flexShrink = 'none';
    this._screen.style.backgroundColor = 'black';
    this._screen.style.width = config.layout.screenWidth * this._magnification + 'px';
    this._screen.style.minWidth = config.layout.screenWidth * this._magnification + 'px';
    this._screen.style.height = config.layout.screenHeight * this._magnification + 'px';
    this._screen.style.minHeight = config.layout.screenHeight * this._magnification + 'px';
    this._screen.style.border = "1px solid white";
    this._screen.style.margin = "auto";
    this._screen.style.cursor = 'none';
    this._screen.style.outline = 'none';
  },
  _addResizeListener: function() {
    window.onresize = () => {
      clearTimeout(this._resizeTimeout);
      this._resizeTimeout = setTimeout(() => {
        this._screen.style.display = 'none';
        setTimeout(() => {
          this._screen.style.display = 'block';
        }, 0);
      }, 200);
    }
  },
  _preventContextMenu: function() {
    this._screen.addEventListener('contextmenu', (e) => e.preventDefault());
  }
}

export default LayoutManager;

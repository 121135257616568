import FontManager from 'managers/FontManager';
import CanvasBox from 'views/canvasBoxes/CanvasBox';
import config from 'config';
import Canvas from 'views/Canvas';

class TextBox extends CanvasBox {
  constructor(paragraphs, options) {
    super();
    this._paragraphs = paragraphs;
    this._canvas = new Canvas(1, 1);
  }
  draw(options) {
    if (!options.fontName) {
      throw Error('No font name supplied.');
    }
    let fontName = options.fontName;
    let maxWidth = options.maxWidth || 280;
    let minWidth = options.minWidth || 0;
    let color = options.color || config.colors.BLACK;
    let outline = options.outline || null;
    let shadow = options.shadow || null;
    let centered = options.centered || false;
    let padding = options.padding || 0;
    let backgroundColor = options.backgroundColor || null;

    let font = FontManager.getFont(fontName);
    let lineHeight = options.lineHeight || font.lineHeight;
    let offsetY = options.offsetY || font.offsetY;
    let offsetX = options.offsetX || font.offsetX;
    let width = minWidth;
    let height = 0;
    let lines = [];
    let currentLine = '';

    let splitParagraphs = this._paragraphs.split('\n');
    for (let j = 0; j < splitParagraphs.length; j++) {
      let para = splitParagraphs[j];
      let words = para == ' ' ? [' '] : para.split(' ');
      for (let i = 0; i < words.length; i++) {
        let word = words[i];

        if (this._getLineLength(word, font, offsetX) >= maxWidth) {
          if (currentLine.length != 0) {
            lines.push(currentLine);
          }
          currentLine = '';
          while (this._getLineLength(word, font, offsetX) >= maxWidth) {
            let { firstHalf, lastHalf } = this._splitWordAtWidth(word, maxWidth, font, offsetX);
            lines.push(firstHalf);
            word = lastHalf;
          }
          if (word.length > 0) {
            currentLine = word + (i != words.length - 1 ? ' ' : '');
          }
        } else if (this._getLineLength(currentLine, font, offsetX) + this._getLineLength(words[i], font, offsetX) >= maxWidth) {
          lines.push(currentLine);
          currentLine = word + (i != words.length - 1 ? ' ' : '');
        } else {
          currentLine += word + (i != words.length - 1 ? ' ' : '');
        }
      }

      if (currentLine != '') {
        lines.push(currentLine);
        currentLine = '';
      }
      // add blank line between paragraphs
      if (j != splitParagraphs.length - 1) {
        lines.push(' ');
      }
    }
    lines.forEach((line) => {
      let lineLength = this._getLineLength(line, font, offsetX);
      if (lineLength > width) {
        width = lineLength;
      }
    });
    height = lines.length *  lineHeight;
    let boxWidth = width + 2 * padding;
    let boxHeight = height + 2 * padding;

    let dim = this.getDimensions();
    if (boxWidth != dim.w || boxHeight != dim.h) {
      this.setDimensions(boxWidth, boxHeight);
    }

    let currY = padding;
    for (let j = 0; j < lines.length; j++) {
      let line = lines[j];
      let currX = padding;
      if (centered) {
        currX = Math.floor(boxWidth / 2 - this._getLineLength(line, font, offsetX) / 2);
      }
      for (let i = 0; i < line.length; i++) {
        let letter = font[line[i]];
        if (backgroundColor) {
          for (let j = 0; j < letter.width ; j++) {
            for (let k = 0; k < lineHeight; k++) {
              this._canvas.drawPoint(j, k, backgroundColor, currX, currY + offsetY);
            }
          }
        }
        if (outline) {
          letter.points.forEach((pt) => {
            this._canvas.drawPoint(pt.x - 1, pt.y-1, outline.color, currX, currY + offsetY)
            this._canvas.drawPoint(pt.x, pt.y-1, outline.color, currX, currY + offsetY)
            this._canvas.drawPoint(pt.x + 1, pt.y-1, outline.color, currX, currY + offsetY)
            this._canvas.drawPoint(pt.x - 1, pt.y, outline.color, currX, currY + offsetY)
            this._canvas.drawPoint(pt.x + 1, pt.y, outline.color, currX, currY + offsetY)
            this._canvas.drawPoint(pt.x - 1, pt.y + 1, outline.color, currX, currY + offsetY)
            this._canvas.drawPoint(pt.x, pt.y + 1, outline.color, currX, currY + offsetY)
            this._canvas.drawPoint(pt.x + 1, pt.y + 1, outline.color, currX, currY + offsetY)
          });
        }
        if (shadow) {
          letter.points.forEach((pt) => {
            this._canvas.drawPoint(pt.x + 1, pt.y + 1, shadow.color, currX, currY + offsetY)
          });
        }
        letter.points.forEach((pt) => {
          this._canvas.drawPoint(pt.x, pt.y, color, currX, currY + offsetY)
        });
        currX += letter.width + offsetX;
      }
      currY += lineHeight;
    }
    this._canvas.commitData();
  }
  _getLineLength(line, font, offsetX) {
    let length = 0;
    line.split('').forEach((ch, index) => {
      length += font[ch].width;
      if (index != 0) {
        length += offsetX;
      }
    });
    return length;
  }
  _splitWordAtWidth(word, width, font, offsetX) {
    let length = 0;
    for (let i = 0; i < word.length; i++) {
      length += font[word[i]].width + offsetX;
      if (length >= width) {
        return { firstHalf: word.substring(0, i), lastHalf: word.substring(i, word.length) };
      }
    }
  }
}

export default TextBox;

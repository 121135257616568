import config from 'config';
import StateManager from 'managers/StateManager';

let SaveManager = {
  init: function() {
    this._currentScreen = null;
  },
  setGameController(gc) {
    this._gameController = gc;
  },
  setCurrentScreen(screen) {
    this._currentScreen = screen;
  },
  deleteSavedGame(name) {
    let games = JSON.parse(localStorage.getItem('saved_games')) || {};
    if (games) {
      delete games[name];
    };
    localStorage.setItem('saved_games', JSON.stringify(games));
  },
  restoreGame: function(name) {
    let games = JSON.parse(localStorage.getItem('saved_games')) || {};
    let game = games[name];
    if (game) {
      StateManager.restoreState(game.centralState);
      this._gameController.restoreScreen(game.screenClass, game.screenState);
    }
  },
  restartGame: function(name) {
    StateManager.restoreToDefaultState();
    this._gameController.startGame();
  },
  saveGame: function(name) {
    let screenClass = this._currentScreen.constructor.id;
    let centralState = StateManager.getWholeState();
    let screenState = this._currentScreen.getState();
    let games = JSON.parse(localStorage.getItem('saved_games')) || {};
    if (games) {
      games[name] = {
        centralState,
        screenClass,
        screenState,
      };
    };
    localStorage.setItem('saved_games', JSON.stringify(games));
  },
  getGameNames: function() {
    let games = JSON.parse(localStorage.getItem('saved_games'));
    if (games) {
      return Object.keys(games);
    } else {
      return [];
    }
  }
}

export default SaveManager;

export default {
  src: '/images/screens/office/officeDoor.png',
  spriteInfo: {
    closed: {
      width: 59,
      height: 74,
      x: 0,
      y: 0,
    },
    opened: {
      width: 59,
      height: 74,
      x: 59 * 3,
      y: 0,
    },
    open: {
      width: 59,
      height: 74,
      x: 0,
      y: 0,
      animation: true,
      frames: 4,
    },
    close: {
      width: 59,
      height: 74,
      x: 59 * 3,
      y: 0,
      animation: true,
      repeat: false,
      frames: 4,
      reverse: true,
    },
  }
}

import ImageManager from 'managers/ImageManager';
import Layer from 'views/layers/Layer';
import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import PictureBox from 'views/canvasBoxes/PictureBox';
import LabelBox from 'views/compositeBoxes/LabelBox';
import Column from 'views/compositeBoxes/Column';
import Row from 'views/compositeBoxes/Row';
import Button from 'views/compositeBoxes/Button';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';

class ConversationLayer extends Layer {
  constructor(choseQuestion) {
    super();
    this._choseQuestion = choseQuestion;
  }
  showFaceAndQuestions(questions, characterName, eyeData, mouthData, bgData) {
    this._questions = questions;
    this._characterName = characterName;
    this._eyeData = eyeData;
    this._mouthData = mouthData;
    this._bgData = bgData;
    this.draw();
  }
  keyPressed(key) {
  }
  updateAnimation(eyeData, mouthData, bgData) {
    this._eyeData = eyeData;
    this._mouthData = mouthData;
    this._bgData = bgData;

    this._eyesBox.drawImage(this._eyeData.store, this._eyeData.options);
    this._mouthBox.drawImage(this._mouthData.store, this._mouthData.options);
    this._bgBox.drawImage(this._bgData.store);
  }
  draw() {
    let theme = Style.getTheme('conversationBox');

    this._label = new TextBox(this._characterName);
    this._label.draw(theme.face.label);
    this._labelBox = new Box(this._label);
    this._labelBox.draw(theme.face.labelBox);

    this._bgBox = new PictureBox();
    this._bgBox.drawImage(this._bgData.store);
    this._eyesBox = new PictureBox();
    this._eyesBox.drawImage(this._eyeData.store, this._eyeData.options);
    this._bgBox.addChild(this._eyesBox);
    this._eyesBox.setPosition(this._eyeData.position.x, this._eyeData.position.y);
    this._mouthBox = new PictureBox();
    this._mouthBox.drawImage(this._mouthData.store, this._mouthData.options);
    this._bgBox.addChild(this._mouthBox);
    this._mouthBox.setPosition(this._mouthData.position.x, this._mouthData.position.y);

    this._faceBox = new Box(this._bgBox);
    this._faceBox.draw(theme.face.faceBox);
    this._mainColumn = new Column([this._labelBox, this._faceBox], theme.face.mainColumn);
    this._faceBase = new Box(this._mainColumn);
    this._faceBase.draw(theme.face.faceBase);

    this._buttons = this._questions.map((q) => {
      return new Button(
        () => this._choseQuestion(q),
        q,
        theme.questions.text,
        theme.questions.box,
        theme.questions.textHover,
        theme.questions.boxHover,
        theme.questions.textActive,
        theme.questions.boxActive
      );
    });

    this._qColumn = new Column(this._buttons, theme.questions.questionColumn);

    this._mainRow = new Row([this._faceBase, this._qColumn], theme.mainRow);

    this._base = new Box(this._mainRow);
    this._base.draw(theme.base);

    this._disposables = [this._label, this._labelBox, this._bgBox, this._eyesBox, this._mouthBox, this._faceBox, this._faceBase, this._mainColumn, this._buttons, this._qColumn, this._mainRow, this._base];

    this.addChild(this._base);
    this.centerBox(this._base);
  }
  clearFaceAndQuestions() {
    if (this._disposables) {
      this._disposables.forEach((disp) => {
        if (Array.isArray(disp)) {
          disp.forEach((d) => d.destroy());
        } else {
          disp.destroy();
        }
      });
    }
    this._disposables = [];
  }
  destroy() {
    this.removeEventListener();
    this._disposables.forEach((disp) => {
      if (Array.isArray(disp)) {
        disp.forEach((d) => d.destroy());
      } else {
        disp.destroy();
      }
    });
    this._removeLayerDiv();
  }
}

export default ConversationLayer;

import EmptyBox from 'views/canvasBoxes/EmptyBox';
import CompositeBox from 'views/compositeBoxes/CompositeBox';

class Row extends CompositeBox {
  constructor(items, options = {}) {
    super();
    this._base = new EmptyBox(1, 1);

    this._options = options;

    this.setContent(items);
  }
  destroy() {
    this._base.destroy();
    delete this;
  }
  // the previous items should be destroyed before this
  setContent(items) {
    this._items = items;
    this.draw();
  }
  draw() {
    let space = this._options.space || 0;
    let alignment = this._options.alignment || 'center';
    let width = 0;
    let height = 0;
    if (this._items.length == 0) {
      return;
    }
    this._items.forEach((item) => {
      let dims = item.getDimensions();
      if (dims.h > height) {
        height = dims.h;
      }
      width += dims.w;
    });
    width += (this._items.length - 1) * space;

    this._base.setDimensions(width, height);

    let currX = 0;
    let currY = 0;
    this._items.forEach((item) => {
      let itemDims = item.getDimensions();
      if (alignment == 'center') {
        currY = Math.round((height - itemDims.h) / 2);
      }
      item.setPosition(currX, currY);
      currX += item.getDimensions().w + space;
      this._base.getCanvas().addChild(item.getCanvas());
    });
  }
}

export default Row;

import Destroyable from 'classes/Destroyable';

class CanvasBox extends Destroyable {
  constructor() {
    super();
  }
  getDimensions() {
    return this._canvas.getDimensions();
  }
  getPosition() {
    return this._canvas.getPosition();
  }
  setPosition(x, y) {
    this._canvas.setPosition(x, y);
  }
  setDimensions(w, h) {
    this._canvas.setDimensions(w, h);
  }
  getCanvas() {
    return this._canvas;
  }
  getElement() {
    return this._canvas.getElement();
  }
  setClickable(bool) {
    this._canvas.setClickable(bool);
  }
  addChild(child) {
    this._canvas.addChild(child);
  }
  addListener(type, listener) {
    this._canvas.addListener(type, listener);
  }
  destroy() {
    this._canvas.destroy();
    this._canvas = null;
  }
}

export default CanvasBox;

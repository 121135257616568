class Destroyable {
  constructor() {
  }
  destroy() {
    throw Error('Destroy must be overriden');
    // should be overridden
  }
}

export default Destroyable;

import ImageManager from 'managers/ImageManager';
import Layer from 'views/layers/Layer';
import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import PictureBox from 'views/canvasBoxes/PictureBox';
import LabelBox from 'views/compositeBoxes/LabelBox';
import Column from 'views/compositeBoxes/Column';
import Row from 'views/compositeBoxes/Row';
import Button from 'views/compositeBoxes/Button';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';

class FaceTextLayer extends Layer {
  constructor(callBack = () => {}) {
    super();
    this._callBack = callBack;
    this.addMousedownListener(this._callBack);
  }
  setNewTextData(characterName, text, eyeData, mouthData, bgData) {
    if (this._disposables) {
      this._disposables.forEach((disp) => {
        disp.destroy();
      })
    }
    this._characterName = characterName;
    this._text = text;
    this._eyeData = eyeData;
    this._mouthData = mouthData;
    this._bgData = bgData;
    this.draw();
  }
  updateAnimation(eyeData, mouthData, bgData) {
    this._eyeData = eyeData;
    this._mouthData = mouthData;
    this._bgData = bgData;

    this._eyesBox.drawImage(this._eyeData.store, this._eyeData.options);
    this._mouthBox.drawImage(this._mouthData.store, this._mouthData.options);
    this._bgBox.drawImage(this._bgData.store);
  }
  keyPressed(key) {
  }
  draw() {
    let theme = Style.getTheme('faceTextBox');

    this._label = new TextBox(this._characterName);
    this._label.draw(theme.face.label);
    this._labelBox = new Box(this._label);
    this._labelBox.draw(theme.face.labelBox);

    this._bgBox = new PictureBox();
    this._bgBox.drawImage(this._bgData.store);
    this._eyesBox = new PictureBox();
    this._eyesBox.drawImage(this._eyeData.store, this._eyeData.options);
    this._bgBox.addChild(this._eyesBox);
    this._eyesBox.setPosition(this._eyeData.position.x, this._eyeData.position.y);
    this._mouthBox = new PictureBox();
    this._mouthBox.drawImage(this._mouthData.store, this._mouthData.options);
    this._bgBox.addChild(this._mouthBox);
    this._mouthBox.setPosition(this._mouthData.position.x, this._mouthData.position.y);

    this._faceBox = new Box(this._bgBox);
    this._faceBox.draw(theme.face.faceBox);
    this._mainColumn = new Column([this._labelBox, this._faceBox], theme.face.mainColumn);
    this._faceBase = new Box(this._mainColumn);
    this._faceBase.draw(theme.face.faceBase);

    this._text = new TextBox(this._text);
    this._text.draw(theme.words.text);
    this._textBox = new Box(this._text);
    this._textBox.draw(theme.words.textBox);

    this._mainRow = new Row([this._faceBase, this._textBox], theme.mainRow);

    this._base = new Box(this._mainRow);
    this._base.draw(theme.base);

    this._disposables = [this._label, this._labelBox, this._bgBox, this._eyesBox, this._mouthBox, this._faceBox, this._mainColumn, this._text, this._textBox, this._mainRow, this._base];

    this.addChild(this._base);
    this.centerBox(this._base);
  }
  destroy() {
    this.removeEventListener();
    this._disposables.forEach((disp) => disp.destroy());
    this._removeLayerDiv();
  }
}

export default FaceTextLayer;

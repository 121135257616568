import layout from 'config/layout';
import fonts from 'config/fonts';
import settings from 'config/settings';
import paths from 'config/paths';

export default {
  layout,
  fonts,
  settings,
  paths,
};

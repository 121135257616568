export default {
  _data: {
    hasIntroduced: false,
    hasBegunInterview: false,
    hasSatDown: false,
  },
  hasIntroduced: function() {
    return this._data.hasIntroduced;
  },
  setHasIntroduced: function(bool) {
    this._data.hasIntroduced = bool;
  },
  hasBegunInterview: function() {
    return this._data.hasBegunInterview;
  },
  setHasBegunInterview: function(bool) {
    this._data.hasBegunInterview = bool;
  },
  hasSatDown: function() {
    return this._data.hasSatDown;
  },
  setHasSatDown: function(bool) {
    this._data.hasSatDown = bool;
  }
}

import Destroyable from 'classes/Destroyable';

class CompositeBox extends Destroyable {
  constructor() {
    super();
  }
  getDimensions() {
    return this._base.getDimensions();
  }
  getPosition() {
    return this._base.getPosition();
  }
  setPosition(x, y) {
    this._base.setPosition(x, y);
  }
  setDimensions(w, h) {
    this._base.setDimensions(w, h);
  }
  getCanvas() {
    return this._base.getCanvas();
  }
  getElement() {
    return this._base.getCanvas().getElement();
  }
  setClickable() {
    this._base.getCanvas().setClickable();
  }
  addListener(type, listener) {
    this._base.addListener(type, listener);
  }
  destroy() {
    throw Error('Subclasses of CompositeBox must override the destroy() method')
  }
}

export default CompositeBox;

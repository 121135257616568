import ImageManager from 'managers/ImageManager';
import Layer from 'views/layers/Layer';
import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import PictureBox from 'views/canvasBoxes/PictureBox';
import LabelBox from 'views/compositeBoxes/LabelBox';
import Column from 'views/compositeBoxes/Column';
import Row from 'views/compositeBoxes/Row';
import Button from 'views/compositeBoxes/Button';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';

class TextLayer extends Layer {
  constructor(text, callBack = () => {}) {
    super();
    let theme = Style.getTheme('narrationBox');
    this._text = text;
    this._callBack = callBack;
    this.addMousedownListener(this._callBack);
    this.draw();
  }
  setText(text) {
    if (this.textBox) {
      this._textBox.destroy();
    }
    if (this._base) {
      this._base.destroy();
    }
    this._text = text;
    this.draw();
  }
  keyPressed(key) {
  }
  draw() {
    let theme = Style.getTheme('narrationBox');
    this._textBox = new TextBox(this._text);
    this._textBox.draw(theme.text);

    this._base = new Box(this._textBox);
    this._base.draw(theme.base);

    this.addChild(this._base);
    this.centerBox(this._base);
  }
  destroy() {
    this.removeEventListener();
    this._textBox.destroy();
    this._base.destroy()
    this._removeLayerDiv();
  }
}

export default TextLayer;

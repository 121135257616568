export default {
  src: '/images/screens/office/sittingPaulOffice.png',
  spriteInfo: {
    sitting: {
      width: 22,
      height: 41,
      x: 0,
      y: 0,
    },
  }
}

import PolygonManager from 'managers/PolygonManager';
import { PriorityQueue, PriorityQueueItem } from 'classes/PriorityQueue';
import config from 'config';

class FloorData {
  constructor(width, height, walkAreas) {
    this._width = width;
    this._height = height;
    this._points = new Array(width * height);
    this._nonWalkNumber = 2000;
    for (let i = 0; i < width; i++) {
      for (let j = 0; j < height; j++) {
        let pt = {x: i, y: j};
        let ptKey = this.getPointKey(pt);
        this._points[ptKey] = this._nonWalkNumber;
        walkAreas.forEach((walkArea, index) => {
          if (PolygonManager.pointIsInPolygon(pt, walkArea.points)) {
            this._points[ptKey] = walkArea.type == 'walk' ? 1 : this._nonWalkNumber;
          }
        })
      }
    }
  }
  pointIsWalkable(ptKey) {
    return !(ptKey < 0 || this._points.length < ptKey || this._points[ptKey] >= this._nonWalkNumber);
  }
  getPointKey(pt) {
    return pt.y * this._width + pt.x;
  }
  getDistance(pt, pt2) {
    return Math.sqrt((pt.x - pt2.x) * (pt.x - pt2.x) + (pt.y - pt2.y) * (pt.y - pt2.y));
  }
  getNearestWalkable(pt) {
    let x = pt.x;
    let y = pt.y;

    let pointFound = null;
    let pointDistance = 10000000;

    outer:
    for (let i = 0; y + i < config.layout.screenHeight && x + i < config.layout.screenWidth; i += 3 ) {
      let yTemp = y + i;
      let xTemp = x + i;
      let key = yTemp * this._width + xTemp;
      if (this._points.length > key && this._points[key] < this._nonWalkNumber) {
        let newPoint = {x: xTemp, y: yTemp};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break outer;
      }
    }

    outer:
    for (let i = 0; y + i < config.layout.screenHeight && x - i > 0; i += 3 ) {
      let yTemp = y + i;
      let xTemp = x - i;
      let key = yTemp * this._width + xTemp;
      if (this._points.length > key && this._points[key] < this._nonWalkNumber) {
        let newPoint = {x: xTemp, y: yTemp};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break outer;
      }
    }

    outer:
    for (let i = 0; y - i > 0 && x + i < config.layout.screenWidth; i += 3 ) {
      let yTemp = y - i;
      let xTemp = x + i;
      let key = yTemp * this._width + xTemp;
      if (this._points.length > key && this._points[key] < this._nonWalkNumber) {
        let newPoint = {x: xTemp, y: yTemp};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break outer;
      }
    }

    outer:
    for (let i = 0; y - i > 0 && x - i > 0; i += 3 ) {
      let yTemp = y - i;
      let xTemp = x - i;
      let key = yTemp * this._width + xTemp;
      if (this._points.length > key && this._points[key] < this._nonWalkNumber) {
        let newPoint = {x: xTemp, y: yTemp};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break outer;
      }
    }

    for (let i = y; i < config.layout.screenHeight; i += 3) {
      let key = i * this._width + x;
      if (this._points.length > key && this._points[key] < this._nonWalkNumber) {
        let newPoint = {x, y: i};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break;
      }
    }
    for (let i = y; i >= 0; i -= 3) {
      let key = i * this._width + x;
      if (this._points[key] < this._nonWalkNumber) {
        let newPoint = {x, y: i};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break;
      }
    }
    for (let i = x; i < config.layout.screenWidth; i += 3) {
      let key = y * this._width + i;
      if (this._points.length > key && this._points[key] < this._nonWalkNumber) {
        let newPoint = {x: i, y};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break;
      }
    }
    for (let i = x; i >= 0; i -= 3) {
      let key = y * this._width + i;
      if (this._points[key] < this._nonWalkNumber) {
        let newPoint = {x: i, y};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break;
      }
    }
    for (let i = 0; i >= 0; i -= 2) {
      let key = y * this._width + i;
      if (this._points[key] < this._nonWalkNumber) {
        let newPoint = {x: i, y};
        let distance = this.getDistance(pt, newPoint);
        if (distance < pointDistance) {
          pointDistance = distance;
          pointFound = newPoint;
        }
        break;
      }
    }

    return pointFound;
  }
  getPath(pt1, pt2, avoidObstacles = true) {
    if (pt1.x == pt2.x && pt1.y == pt2.y) {
      return [];
    }
    let pt2Key = this.getPointKey(pt2);
    if (avoidObstacles && !this.pointIsWalkable(this._points[pt2Key])) {
      let tempLeftPt2 = {x: pt2.x, y: pt2.y };
      let tempRightPt2 = {x: pt2.x, y: pt2.y };
      let newPt2 = null;
      while (!newPt2) {
        newPt2 = this.getNearestWalkable(tempLeftPt2);
        if (newPt2) {
          break;
        }
        newPt2 = this.getNearestWalkable(tempRightPt2);
        if (newPt2) {
          break;
        }
        tempLeftPt2.x--;
        tempRightPt2.x++;
      }
      pt2 = newPt2;
    }
    let start = new PriorityQueueItem();
    let end = new PriorityQueueItem();
    start.place = pt1;
    end.place = pt2;
    start.gScore = 0;
    start.setValue(this._endHeuristic(start.place, end.place));

    let closedSet = {};
    let openSet = new PriorityQueue((a, b) => a < b);
    let openKeys = {};
    let cameFrom = {};
    openSet.insert(start);
    openKeys[this.getPointKey(start.place)] = start;

    while (!openSet.isEmpty()) {
      let current = openSet.pop();
      let currentKey = this.getPointKey(current.place);

      openKeys[currentKey] = undefined;

      if(current.place.x == end.place.x && current.place.y == end.place.y) {
        return this._reconstructPath(cameFrom, current, avoidObstacles);
      }

      closedSet[currentKey] = true;

      let neighbours = this._getNeighbours(current.place, avoidObstacles);

      for (let i = 0; i < neighbours.length; i++) {
        let neighbourPoint = neighbours[i];
        let neighbourKey = this.getPointKey(neighbourPoint);
        let neighbourQueueItem = null;

        // if (avoidObstacles && this._points[neighbourKey] > 0) {
        //   continue;
        // }

        if (typeof closedSet[neighbourKey] !== 'undefined') {
          continue;
        }

        let addNeighbour = false;

        if (typeof openKeys[neighbourKey] === 'undefined') {
          neighbourQueueItem = new PriorityQueueItem();
          neighbourQueueItem.gScore = -1;
          neighbourQueueItem.place = neighbourPoint;
          addNeighbour = true;
        } else {
          neighbourQueueItem = openKeys[neighbourKey];
        }

        let tentative_gScore = current.gScore + this._neighbourHeuristic(neighbourKey, avoidObstacles);

        if(neighbourQueueItem.gScore != -1 && (tentative_gScore >= neighbourQueueItem.gScore)) {
          continue;
        }

        cameFrom[neighbourKey] = current;
        neighbourQueueItem.gScore = tentative_gScore;
        neighbourQueueItem.setValue(neighbourQueueItem.gScore + this._endHeuristic(neighbourQueueItem.place, end.place))

        if (addNeighbour) {
          openKeys[neighbourKey] = neighbourQueueItem;
          openSet.insert(neighbourQueueItem);
        }
      }
    }

    return [];
  }
  _reconstructPath(cameFrom, current, avoidObstacles) {
    let path = [current.place];
    while (typeof cameFrom[this.getPointKey(current.place)] !== 'undefined') {
      current = cameFrom[this.getPointKey(current.place)];
      path.push(current.place);
    }
    path.reverse();
    if (avoidObstacles) {
      let index = path.findIndex((pt) => this._points[this.getPointKey(pt)] >= this._nonWalkNumber);
      if (index != -1) {
        path = path.slice(0, index);
      }
    }
    return path;
  }
  _neighbourHeuristic(neighbourKey, avoidObstacles) {
    if (!avoidObstacles) {
      return 1;
    } else {
      return this._points[neighbourKey];
    }
  }
  _endHeuristic(pt1, pt2) {
    let x = pt2.x - pt1.x;
    let y = pt2.y - pt1.y;
    return Math.sqrt(x*x + y*y);
  }
  _getNeighbours(pt, avoidObstacles) {
    let neighbours = [];
    for (let i = pt.x - 1; i < pt.x + 2; i++) {
      for (let j = pt.y - 1; j < pt.y + 2; j++) {
        if (!avoidObstacles) {
          neighbours.push({x: i, y: j});
        } else {
          if (i >= 0 && i < this._width) {
            if (j >= 0 && j < this._height) {
              neighbours.push({x: i, y: j});
            }
          }
        }
      }
    }
    return neighbours;
  }
}

export default FloorData;

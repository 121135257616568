import LayoutManager from 'managers/LayoutManager';

let KeyManager = {
  init: function() {
    this._setupListeners();
    this._lastKeydown = 0;
    this._rateLimit = 20;
    setInterval(() => {
      this._shiftQueue();
    }, this._rateLimit);
  },
  setReceiver: function(receiver) {
    this._receiver = receiver;
  },
  _queue: [],
  _setupListeners: function() {
    LayoutManager.getScreen().addEventListener('keydown', (e) => {
      this._queue.push(e.key);
    });
  },
  _shiftQueue: function() {
    if (this._queue.length == 0) {
      return;
    }
    let key = this._queue.shift();
    this._sendKey(key);
  },
  _sendKey: function(key) {
    if (this._receiver) {
      this._receiver.keyPressed(key);
    }
  },
}

export default KeyManager;

import EntryScreen from 'screens/EntryScreen';
import Lobby from 'screens/Lobby';
import Office from 'screens/Office';
import Rockroom from 'screens/Rockroom';
import OuterRockroom from 'screens/OuterRockroom';
import TestScreen from 'screens/TestScreen';
import TimeManager from 'managers/TimeManager';
import SaveManager from 'managers/SaveManager';
import config from 'config';

let ScreenClasses = {
  [TestScreen.id]: TestScreen,
  [Rockroom.id]: Rockroom,
  [OuterRockroom.id]: OuterRockroom,
  [Office.id]: Office,
  [Lobby.id]: Lobby,
};

class GameController {
  constructor() {
    SaveManager.setGameController(this);

    if (window.location.pathname == '/test') {
      this.changeScreen(TestScreen.id)
    } else {
      this.startGame();
    }
    this._loop = this._loop.bind(this);
    this._loop();
  }
  _loop() {
    setTimeout(this._loop, TimeManager.getLoopTime());
    if (this._currentScreenController) {
      this._currentScreenController.loop();
    }
  }
  _setScreenController(screenCont) {
    this._currentScreenController = screenCont;
    this._currentScreenController.setParentControllerGetter(() => this);
  }
  changeScreen(screenClassName, entryName, loadData) {
    if (this._currentScreenController) {
      this._currentScreenController.destroy();
    }
    let ScreenClass = ScreenClasses[screenClassName];
    this._setScreenController(new ScreenClass(entryName, loadData));
  }
  restoreScreen(screenName, screenData) {
    this.changeScreen(screenName, null, screenData);
  }
  startGame() {
    this.changeScreen(Lobby.id);
  }
}

export default GameController;

export default {
  src: '/images/general/general.png',
  spriteInfo: {
    blank: {
      width: 20,
      height: 20,
      x: -1000,
      y: 0,
    },
    selectionArrow: {
      width: 11,
      height: 7,
      x: 0,
      y: 0
    },
    handle: {
      width: 20,
      height: 10,
      x: 11,
      y: 0,
    },
  }
}

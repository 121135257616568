export default {
  src: '/images/screens/office/sittingMan.png',
  spriteInfo: {
    sitting: {
      width: 28,
      height: 35,
      x: 0,
      y: 0,
    },
  }
}

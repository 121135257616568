import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import Row from 'views/compositeBoxes/Row';
import CompositeBox from 'views/compositeBoxes/CompositeBox';
import focusableMixin from 'mixins/focusableMixin';

class Input extends CompositeBox {
  constructor(onClick, text, maxLength, textOptions, highlightOptions, boxOptions) {
    super();
    focusableMixin(this);

    this._maxLength = maxLength;

    this._isHighlighted = false;
    this._highlightedIndex = text.length;
    this._highlightTimeLength = 500;

    this._text = text;
    this._cursorIndex = text.length;
    this._onClick = onClick;

    this._textOptions = textOptions;
    this._highlightOptions = highlightOptions;
    this._boxOptions = boxOptions;

    this._characterBoxes = [];

    this._row = new Row(this._characterBoxes, { alignment: 'start' });
    this._base = new Box(this._row);
    this._base.draw(boxOptions);
    this._base.addListener('mousedown', () => {
      // if the base receives a click, we are probably clicking after the letters, which each
      // have a mousedown listener that stops propagation.
      this._highlightedIndex = this._text.length;
      this._onClick();
    });
    this._base.setClickable(true);

    this._timer = setTimeout(this._cycleHighlights.bind(this), this._highlightTimeLength);
  }
  destroy() {
    this._row.destroy();
    this._base.destroy();
    this._characterBoxes.forEach((charBox) => {
      charBox.destroy();
    })
  }
  setText(newText, resetIndex) {
    this._text = newText;
    if (resetIndex) {
      this._highlightedIndex = this._text.length;
    }
  }
  handleInput(key) {
    if (key == 'Backspace') {
      if (this._highlightedIndex == 0 || this._text.length == 0) {
        return;
      }
      this.setText(this._text.substring(0, this._highlightedIndex - 1) + this._text.substring(this._highlightedIndex));
      this._highlightedIndex--;
      this._becameFocused();
      return;
    }
    if (key == 'Delete') {
      if (this._highlightedIndex == this._text.length || this._text.length == 0) {
        return;
      }
      let arr = this._text.split('');
      this.setText(this._text.substring(0, this._highlightedIndex) + this._text.substring(this._highlightedIndex + 1));
      this._becameFocused();
      return;
    }
    if (key.length > 1) {
      // if the key.length can't be represented in a single character then it probably isn't a written character
      return;
    }
    // user typed an alpha numeric key
    if (this._text.length == 0) {
      this._text = key;
    } else if (this._text.length >= this._maxLength) {
      console.log('here');
      return;
    } else {
      let arr = this._text.split('');
      this.setText(this._text.substring(0, this._highlightedIndex) + key + this._text.substring(this._highlightedIndex));

    }
    this._highlightedIndex++;
    this._becameFocused();
  }
  getValue() {
    return this._text;
  }
  setHighlightedIndexToBeginning() {
    this._highlightedIndex = 0;
  }
  setHighlightedIndexToEnd() {
    this._highlightedIndex = this._text.length;
  }
  setHighlightForwardOne() {
    if (this._highlightedIndex == this._text.length) {
      return false;
    }
    this._highlightedIndex++;
    this._isHighlighted = true;
    this.draw();
    clearTimeout(this._timer);
    this._timer = setTimeout(this._cycleHighlights.bind(this), this._highlightTimeLength);
    return true;
  }
  setHighlightBackwardOne() {
    if (this._highlightedIndex == 0) {
      return false;
    }
    this._highlightedIndex--;
    this._isHighlighted = true;
    this.draw();
    clearTimeout(this._timer);
    this._timer = setTimeout(this._cycleHighlights.bind(this), this._highlightTimeLength);
    return true;
  }
  _cycleHighlights() {
    if (this.isFocused()) {
      this._isHighlighted = !this._isHighlighted;
    }
    this.draw();
    this._timer = setTimeout(this._cycleHighlights.bind(this), this._highlightTimeLength);
  }
  // gets called within the focusableMixin
  _becameFocused() {
    clearTimeout(this._timer);
    this._isHighlighted = true;
    this.draw();
    this._timer = setTimeout(this._cycleHighlights.bind(this), this._highlightTimeLength);
  }
  _lostFocus() {
    clearTimeout(this._timer);
    this._isHighlighted = false;
    this.draw();
  }
  _makeCharacterBoxes() {
    this._characterBoxes.forEach((charBox) => charBox.destroy());
    this._characterBoxes = [];

    for (let i = 0; i < this._text.length; i++) {
      let ch = new TextBox(this._text[i]);
      ch.setClickable(true);
      ch.addListener('mousedown', (e) => {
        e.stopPropagation();
        this._highlightedIndex = i;
        this._onClick();

      });
      if (this._isHighlighted && this._highlightedIndex == i) {
        ch.draw(this._highlightOptions);
      } else {
        ch.draw(this._textOptions);
      }
      this._characterBoxes.push(ch);
    }
    if (this._isHighlighted && this._highlightedIndex == this._text.length) {
      let cursor = new TextBox('|');
      cursor.draw(this._textOptions);
      this._characterBoxes.push(cursor);
    }
  }
  setDrawCallback(callback) {
    this._drawCallback = callback;
  }
  draw() {
    let dims = this.getDimensions();
    this._makeCharacterBoxes();
    this._row.setContent(this._characterBoxes);
    this._base.draw(this._boxOptions);
    let newDims = this.getDimensions();
    if (this._drawCallback && (dims.w != newDims.w || dims.h != newDims.h)) {
      this._drawCallback();
    }
  }
}

export default Input;

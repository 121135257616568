import TimeManager from 'managers/TimeManager';

let Actions = {
  blocker: function(gateMethodName, ownerName = 'screen', args = []) {
    if (typeof gateMethodName != 'string') {
      throw Error("You must supply the name of the method that checks if blocker should stop blocking in Action.blocker");
    }
    if (typeof ownerName != 'string') {
      throw Error("You must supply the name of the owner of the method that checks if blocker should stop blocking in Action.blocker");
    }
    if (!Array.isArray(args)) {
      throw Error("The args passed to Actions.blocker should be in an array");
    }
    return { type: 'blocker', gate: gateMethodName, owner: ownerName, arguments: args };
  },
  // each action inside of this will run simultaneously
  multi: function(actions) {
    if (!Array.isArray(actions)) {
      throw Error("An array of actions must be supplied to Action.multiAction.")
    }
    return { type: 'multi', actions };
  },
  sleep: function(time) {
    if (typeof time != 'number') {
      throw Error("Time must be supplid to Action.sleep");
    }
    return { type: 'sleep', time: TimeManager.getTime(time) };
  },
  speak: function(character, faceName, lines = []) {
    if (lines.length == 0) {
      throw Error("You need  to supply ConvoActions.speak with an array of lines");
    }
    return {
      type: 'speak',
      character,
      faceName,
      lines,
    };
  },
  filter: function(filter) {
    if (!Array.isArray(filter)) {
      throw Error("A filter, in the form of an array of 4 integers, must be supplied to Action.filter.")
    }
    return { type: 'filter', filter };
  },
  text: function(lines) {
    if (!Array.isArray(lines)) {
      throw Error("An array of lines must be supplied to Action.text.")
    }
    return { type: 'text', lines };
  },
  method: function(name, owner = 'screen', args = []) {
    if (typeof name != 'string') {
      throw Error("You must give a method name to Action.method");
    }
    if (typeof owner != 'string') {
      throw Error("You must give the owner string to Action.method");
    }
    if (!Array.isArray(args)) {
      throw Error("You must pass an array of arguments to Action.method");
    }
    return { type: 'method', owner, method: name, arguments: args };
  },
  converse: function(conversationStack) {
    if (!Array.isArray(conversationStack)) {
      throw Error("You must give an array of conversations to Action.converse");
    }
    return { type: 'converse', conversationStack };
  },
  // this is used to push to the conversation stack, not to start a conversation
  pushConversation: function(conversation) {
    if (typeof conversation != 'string') {
      throw Error("You must give a conversation code sting in the form character.conversationName to Action.pushConversation");
    }
    return { type: 'pushConversation', conversation };
  },
  // this is used to push to the conversation stack, not to start a conversation
  popConversation: function() {
    return { type: 'popConversation' };
  },
  pushToObjectQueue: function(objectName, action) {
    if (typeof objectName != 'string') {
      throw Error("You must supply a game object name to Action.pushToObjectQueue");
    }
    if (!action) {
      throw Error("You must supply an action name to Action.pushToActionQueue");
    }
    return { type: 'pushToObjectQueue', objectName, action };
  },
  wait: function() {
    return { type: 'wait' };
  },
  endWait: function() {
    return { type: 'end wait' };
  },
  walk: function(point, thenFace, callback) {
    if (typeof point.x != 'number' || typeof point.y != 'number') {
      throw Error("You must supply a point in form {x, y} to Action.walk");
    }
    if (thenFace && typeof thenFace != 'string') {
      throw Error("You must supply a string as a thenFace argument to Action.walk");
    }
    return { type: 'walk', thenFace, callback, point };
  },
  walkFree: function(point, thenFace, callback) {
    if (typeof point.x != 'number' || typeof point.y != 'number') {
      throw Error("You must supply a point in form {x, y} to Action.walkFree");
    }
    if (thenFace && typeof thenFace != 'string') {
      throw Error("You must supply a string as a thenFace argument to Action.walkFree");
    }
    return { type: 'walkFree', thenFace, callback, point };
  },
  glide: function(point, thenFace, callback) {
    if (typeof point.x != 'number' || typeof point.y != 'number') {
      throw Error("You must supply a point in form {x, y} to Action.glide");
    }
    if (thenFace && typeof thenFace != 'string') {
      throw Error("You must supply a string as a thenFace argument to Action.glide");
    }
    return { type: 'glide', thenFace, callback, point };
  },
  glideFree: function(point, thenFace, callback) {
    if (typeof point.x != 'number' || typeof point.y != 'number') {
      throw Error("You must supply a point in form {x, y} to Action.glideFree");
    }
    if (thenFace && typeof thenFace != 'string') {
      throw Error("You must supply a string as a thenFace argument to Action.glideFree");
    }
    return { type: 'glideFree', thenFace, callback, point, };
  },
  move: function(point) {
    if (typeof point.x != 'number' || typeof point.y != 'number') {
      throw Error("You must supply a point in form {x, y} to Action.move");
    }
    return { type: 'move', point };
  },
  face: function(direction) {
    if (typeof direction != 'string') {
      throw Error("You must supply a string direction to Action.face");
    }
    return { type: 'face', direction };
  },
  callback: function(methodOwner, method, args =[]) {
    if (typeof methodOwner != 'string') {
      throw Error("You must supply a method owner string to Action.callback");
    }
    if (typeof method != 'string') {
      throw Error("You must supply a method name to Action.object");
    }
    if (!Array.isArray(args)) {
      throw Error("The 'args' passed to Action.callback should be an array.");
    }
    return { owner: methodOwner, method, arguments: args };
  }
}

export default Actions;

import Actions from 'classes/Actions';
import Speech from 'classes/Speech';
import State from 'state';
import ConversationLine from 'classes/ConversationLine';

export default {
  getFace: function() {
    return ['Guard', 'guardFace'];
  },
  getEmotion: function() {
    return '';
  },
  questions: {
    "Why are they green?.": new ConversationLine(
               // getActions function
               () => {
                 return [
                   Actions.speak('Guard', 'guardFace', [new Speech('They\'re uranium.')]),
                 ];
               },
               // shouldShow function
               () => true,
               // changeState function
               () => {},
             ),
    "How much do they weigh?.": new ConversationLine(
              () => {
                return [
                  Actions.speak('Guard', 'guardFace', [new Speech("More than 200 pounds.")])
                ]
              },
              () => true,
              () => {},
            ),
    "Ask something else.": new ConversationLine(
              () => {
                return [
                  Actions.popConversation()
                ]
              },
              () => true,
              () => {},
            ),
  }
}

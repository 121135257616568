import Style from 'style/Style';
import ImageManager from 'managers/ImageManager';
import colors from 'style/colors';

const SELECTED_BORDER = colors.GOLD;

export default {
  mainColumn: Style.makeColumnRowStyle('start', 5),
  base: {
    backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 3, colors.MAIN_COLOR, true, 5, true, 1, false),
    ],
    shadow: Style.makeBoxShadow(colors.SHADOW, 1, 1),
    padding: 5,
  },
  buttons: {
    buttonRow: Style.makeColumnRowStyle('center', 1),
    image: {
      filter: Style.makeImageFilter(colors.MAIN_COLOR)
    },
    imageActive: {
      filter: Style.makeImageFilter(Style.darken(colors.MAIN_COLOR, 60))
    },
    imageDisabled: {
      filter: Style.makeImageFilter([-175, -175, -175, 255], [-150, -150, -150, 255])
    },
    box: {
      backgroundColor: Style.makeBackgroundColor(colors.MAIN_COLOR, true, 5),
      //backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
      padding: 2,
      borders: [
        //Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 2, colors.MAIN_COLOR, true, 0, true, 1,),
      ],
    },
    boxActive: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 60), true, 5),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, SELECTED_BORDER, true, 0, true, 1,),
      ],
    },
    boxDisabled: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 150), true, 5),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, false, 1, true),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 0, true, 1, false),
      ],
    },
    boxFocused: {
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
        Style.makeBoxBorder(Style.ALL_SIDES, 2, colors.CLEAR),
      ],
    },
  },
  scrollBar: {
    arrowImage: ImageManager.getImage('misc', 'selectionArrow'),
    column: Style.makeColumnRowStyle('center', 0),
    topArrow: {
      padding: 1,
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 5, true, 1, false),
      ],
    },
    bottomArrow: {
      padding: 1,
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, true, 5, true, 1, false),
      ],
    },
    stemBox: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 100), true, 5),
    },
    scrollBox: {

    }
  },
  items: {
    image: {
    },
    imageActive: {
    },
    imageDisabled: {
      filter: Style.makeImageFilter([-175, -175, -175, 255], [-150, -150, -150, 255])
    },
    box: {
      //backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 100), true, 5),
      padding: 3,
    },
    boxActive: {
      //backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), false, 10),
      padding: 3,
    },
    boxDisabled: {
    },
    boxFocused: {
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
      ],
    }
  },
  itemSet: {
    itemsInRow: 5,
    itemsInColumn: 4,
    blankItem: ImageManager.getImage('menuButtons', 'blank'),
    row: Style.makeColumnRowStyle('center', 0),
    column: Style.makeColumnRowStyle('center', 0),
    box: {
      backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 80), true, 5),
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 3, colors.MAIN_COLOR, true, 5, true, 1, true),
      ],    },
  },
  setAndScrollRow: Style.makeColumnRowStyle('center', 5),
  setAndScrollBox: {
  },
}

import LayerController from 'controllers/layerControllers/LayerController';
import MouseManager from 'managers/MouseManager';
import StateManager from 'managers/StateManager';
import FaceTextLayer from 'views/layers/FaceTextLayer';
import faceData from 'faceData';
import ImageManager from 'managers/ImageManager';
import TimeManager from 'managers/TimeManager';

class FaceTextController extends LayerController {
  constructor(characterName, faceName, dialogueArray) {
    super();
    this._characterName = characterName;
    this._faceName = faceName;
    this._dialogueIndex = 0;
    this.dialogueArray = dialogueArray;
    this._faceData = faceData[this._faceName];
    this._faceImages = ImageManager.getImageSet(this._faceName);

    this._delayAmount = .5;

    this._eyeFrame = 0;
    this._mouthFrame = 0;
    this._mouthRepetitions = null;
    this._hasChanged = false;
    this._talkDelay = null;
  }
  _makeLayer() {
    this._layer = new FaceTextLayer(
      this.next.bind(this),
    );
    this._resetAnimationState();
    this._setNewTextData();
  }
  _loop() {
    this._advanceAnimation();
    if (this._hasChanged) {
      this._updateAnimation();
    }
  }
  _advanceAnimation() {

    let {
      eyeData,
      mouthData,
    } = this._getData();

    if (this._eyeFrame < eyeData.store.getTotalFrames() - 1) {
      this._eyeFrame++;
      this._hasChanged = true;
    }
    if (this._mouthRepetitions > 0) {
      if (this._talkDelay > 0) {
        this._talkDelay -= TimeManager.getLoopTime();
        return;;
      }
      if (this._mouthFrame < mouthData.store.getTotalFrames() - 1) {
        this._mouthFrame++;
      } else {
        this._mouthRepetitions--;
        this._mouthFrame = 0;
      }
      this._hasChanged = true;
    }
  }
  _resetAnimationState() {
    this._eyeFrame = 0;
    this._mouthFrame = 0;
    this._talkDelay = TimeManager.getTime(this._delayAmount);
    let dialogueData = this.dialogueArray[this._dialogueIndex];
    if (!dialogueData.shouldTalk) {
      this._mouthRepetitions = 0;
    } else {
      this._mouthRepetitions = Math.round(dialogueData.line.split(' ').length / 2);
      if (this._mouthRepetitions == 0) {
        this._mouthRepetitions = 1;
      }
    }
  }
  keyPressed(key) {
    this.next();
  }
  _getData() {
    let dialogueData = this.dialogueArray[this._dialogueIndex];
    let text = dialogueData.line;
    let emotion = dialogueData.emotion;

    let eyeSprite = 'eyes' + (emotion ? `_${emotion}` : '');
    let eyeStore = this._faceImages[eyeSprite];
    let eyeSpritePosition = eyeStore.getFramePosition(this._eyeFrame);
    let eyePosition = this._faceData.eyes;
    let eyeOptions = {
      xOffset: eyeSpritePosition.x,
      yOffset: eyeSpritePosition.y,
    }

    let mouthStore = this._faceImages['mouth'];
    let mouthSpritePosition = mouthStore.getFramePosition(this._mouthFrame);
    let mouthPosition = this._faceData.mouth;
    let mouthOptions = {
      xOffset: mouthSpritePosition.x,
      yOffset: mouthSpritePosition.y,
    }

    let bgStore = this._faceImages.bg;

    return {
      text,
      eyeData: {
        store: eyeStore,
        position: eyePosition,
        options: eyeOptions
      },
      mouthData: {
        store: mouthStore,
        position: mouthPosition,
        options: mouthOptions
      },
      bgData: {
        store: bgStore,
      },
      characterName: this._characterName
    };
  }
  next() {
    this._dialogueIndex++;
    if (this._dialogueIndex > this.dialogueArray.length - 1) {
      this.popThisController();
      return;
    } else {
      this._resetAnimationState();
      this._setNewTextData();
    }
  }
  _setNewTextData() {
    let {
      text,
      eyeData,
      mouthData,
      bgData,
      characterName
    } = this._getData();

    this._layer.setNewTextData(
      characterName,
      text,
      eyeData,
      mouthData,
      bgData,
    );
    this._hasChanged = false;
  }
  _updateAnimation() {
    let {
      eyeData,
      mouthData,
      bgData
    } = this._getData();

    this._layer.updateAnimation(
      eyeData,
      mouthData,
      bgData,
    );
    this._hasChanged = false;
  }
  becameActive() {
    MouseManager.setCursor('pointer');
  }
}

export default FaceTextController;

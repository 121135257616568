import Style from 'style/Style';
import colors from 'style/colors';
import ImageManager from 'managers/ImageManager';

const BUTTON_WIDTH = 60;
const MINI_BUTTON_WIDTH = 24;
const BUTTON_FONT = '999';

export default {
  mainRow: Style.makeColumnRowStyle('start', 2),
  base: {
    backgroundColor: Style.makeBackgroundColor(Style.darken(colors.MAIN_COLOR, 20), true, 5),
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 3, colors.MAIN_COLOR, true, 5, true, 1, false),
    ],
    shadow: Style.makeBoxShadow(colors.SHADOW, 1, 1),
    padding: 2,
  },
  leftColumnBox: {
    padding: 1,
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, .75),
    ],
  },
  rightColumnBox: {
    padding: 0
  },
  leftColumn: Style.makeColumnRowStyle('center', 1),
  rightColumn: Style.makeColumnRowStyle('center', 10),
  title: {
    fontName: BUTTON_FONT,
    maxWidth: BUTTON_WIDTH,
    minWidth: BUTTON_WIDTH,
    padding: 0,
    centered: true,
    color: Style.darken(colors.MAIN_COLOR, 110),
    shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 20)),
  },
  sliders: {
    sliderColumn: Style.makeColumnRowStyle('center', 5),
    sliderLabel: {
      fontName: BUTTON_FONT,
      maxWidth: 40,
      minWidth: 40,
      padding: 0,
      centered: true,
      color: Style.darken(colors.MAIN_COLOR, 110),
      shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 20)),
    },
    sliderLabelRow: Style.makeColumnRowStyle('center', 0),
    stem: {
      h: 67,
      w: 3,
    },
    stemBox: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 2, colors.MAIN_COLOR, false, 15, true, 1.2, true),
      ],
    },
    handleImage: ImageManager.getImage('misc', 'handle'),
    handleBox: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      shadow: Style.makeBoxShadow([0, 0, 0, 100], 1, 2),
    }
  },
  scoreText: {
    fontName: BUTTON_FONT,
    padding: 0,
    centered: false,
    color: colors.WHITE,
  },
  scoreBox: {
    backgroundColor: Style.makeBackgroundColor(colors.BLACK),
    borders: [
      Style.makeBoxBorder(Style.ALL_SIDES, 2, colors.MAIN_COLOR, false, 15, true, 1, true),
    ],
    padding: 2,
    minWidth: 100,
    centered: true,
  },
  buttons: {
    miniRow: Style.makeColumnRowStyle('center', 0),
    text: {
      fontName: BUTTON_FONT,
      maxWidth: BUTTON_WIDTH,
      minWidth: BUTTON_WIDTH,
      padding: 0,
      centered: true,
      color: colors.BLACK,
      shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 20)),
    },
    textHover: {
      fontName: BUTTON_FONT,
      maxWidth: BUTTON_WIDTH,
      minWidth: BUTTON_WIDTH,
      padding: 0,
      centered: true,
      color: colors.BLACK,
      shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 20)),
    },
    textActive: {
      fontName: BUTTON_FONT,
      maxWidth: BUTTON_WIDTH,
      minWidth: BUTTON_WIDTH,
      padding: 0,
      centered: true,
      color: colors.BLACK,
    },
    miniText: {
      fontName: BUTTON_FONT,
      maxWidth: MINI_BUTTON_WIDTH,
      minWidth: MINI_BUTTON_WIDTH,
      padding: 0,
      centered: true,
      color: colors.BLACK,
      shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 20)),
    },
    miniTextHover: {
      fontName: BUTTON_FONT,
      maxWidth: MINI_BUTTON_WIDTH,
      minWidth: MINI_BUTTON_WIDTH,
      padding: 0,
      centered: true,
      color: colors.BLACK,
      shadow: Style.makeTextShadow(Style.lighten(colors.MAIN_COLOR, 20)),
    },
    miniTextActive: {
      fontName: BUTTON_FONT,
      maxWidth: MINI_BUTTON_WIDTH,
      minWidth: MINI_BUTTON_WIDTH,
      padding: 0,
      centered: true,
      color: colors.BLACK,
    },
    box: {
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, .65),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, 1),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, 1, true),
      ],
    },
    boxHover: {
      padding: 1,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, .65),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, 1),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, 1, true),
      ],
    },
    boxActive: {
      backgroundColor: Style.makeBackgroundColor(colors.BLACK),
      padding: 2,
      borders: [
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, .65),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, 1),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.BLACK),
        Style.makeBoxBorder(Style.ALL_SIDES, 1, colors.MAIN_COLOR, false, 0, true, 1, true),
      ],
    },
  },

}

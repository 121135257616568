import ImageManager from 'managers/ImageManager';
import Layer from 'views/layers/Layer';
import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import PictureBox from 'views/canvasBoxes/PictureBox';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import LabelBox from 'views/compositeBoxes/LabelBox';
import Column from 'views/compositeBoxes/Column';
import Row from 'views/compositeBoxes/Row';
import Input from 'views/compositeBoxes/Input';
import Button from 'views/compositeBoxes/Button';
import SelectionList from 'views/compositeBoxes/SelectionList';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';

class SaveLayer extends Layer {
  constructor(savedGames = [], cancelHandler, saveHandler, deleteHandler) {
    super();

    this._savedGames = savedGames;
    this._cancelHandler = cancelHandler;
    this._saveHandler = saveHandler;
    this._deleteHandler = deleteHandler;

    let theme = Style.getTheme('saveBox');

    this._text = new TextBox('Type the description of this saved game.');
    this._text.draw(theme.label);

    this._savedGameIndex = 0;
    this._selections = new SelectionList(
      this._setSelection.bind(this),
      this._savedGames,
      this._savedGameIndex,
      theme.selection.width,
      theme.selection.arrowImage,
      theme.selection.topArrow,
      theme.selection.bottomArrow,
      theme.selection.listItem.text,
      theme.selection.listItem.textBox,
      theme.selection.listItem.highlightText,
      theme.selection.listItem.highlightTextBox,
      theme.selection.listItem.height,
      theme.selection.outerColumn,
      theme.selection.outerBox,
    );

    this._buttons = this._makeButtons();

    this._buttonColumn = new Column(this._buttons, theme.buttons.column);

    this._bottomRow= new Row(
      [
        this._selections,
        this._buttonColumn
      ],
      theme.bottomRow,
    );

    this._input = new Input(
      this._focusInput.bind(this),
      (this._savedGames.length > 0 ? this._savedGames[0] : ''), // text
      30, // max length
      theme.input.text,
      theme.input.highlightText,
      { ...theme.input.box, minWidth: this._bottomRow.getDimensions().w }
    );
    this._input.draw();
    this._input.setDrawCallback(this._inputChanged.bind(this));


    this._mainColumn = new Column([this._text, this._input, this._bottomRow], theme.mainColumn);

    this._base = new Box(this._mainColumn);
    this._base.draw(theme.base);

    this._focusIndex = 0;
    this._focusables = [this._input, ...this._buttons];
    this._drawFocus();

    this.addChild(this._base);
    this.centerBox(this._base);
  }
  _inputChanged() {
    let theme = Style.getTheme('saveBox');

    this._mainColumn.draw();
    this._base.draw(theme.base);
  }
  destroy() {
    this.removeEventListener();
    this._text.destroy();
    this._buttons.forEach((button) => button.destroy());
    this._selections.destroy();
    this._input.destroy();
    this._buttonColumn.destroy();
    this._bottomRow.destroy();
    this._mainColumn.destroy();
    this._base.destroy();
    this._removeLayerDiv();
  }
  _makeButtons() {
    let theme = Style.getTheme('saveBox');

    this._save = new Button(
      () => { this._saveHandler(this._input.getValue()) },
      'Save',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    );
    this._delete = new Button(
      () => { this._deleteHandler(this._selections.getSelectionValue()) },
      'Delete',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )
    this._changeDirectory = new Button(
      () => { console.log('pressed') },
      'Change Directory',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )
    this._cancel = new Button(
      () => {
        this._cancelHandler();
      },
      'Cancel',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )

    return [this._save, this._delete, this._changeDirectory, this._cancel];
  }
  _drawFocus() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex) {
        focusable.setFocused(true);
      } else {
        focusable.setFocused(false);
      }
      // input starts it's own timed drawing process
      if (focusable != this._input) {
        focusable.draw();
      }
    });
  }
  _focusInput() {
    this._focusIndex = 0;
    this._drawFocus();
  }
  _clickFocusedButton() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex && focusable instanceof Button) {
        focusable.click();
      }
    });
  }
  _setSelection(index) {
    this._savedGameIndex = index;
    this._selections.setSelectedIndex(this._savedGameIndex);
    this._focusInput();
    this._drawFocus();
    this._input.setText(this._savedGames[this._savedGameIndex], true);
    this._input.draw();
  }
  keyPressed(key) {
    let directions = ['ArrowUp', 'ArrowRight', 'ArrowLeft', 'ArrowDown'];
    if (directions.indexOf(key) == -1 && this._input.isFocused()) {
      this._input.handleInput(key);
      return;
    }
    if (key == 'Enter') {
      this._clickFocusedButton();
    }
    if (key == 'ArrowUp') {
      if (this._savedGameIndex > 0) {
        this._selections.scrollUp();
        return;
      }
    }
    if (key == 'ArrowDown') {
      if (this._savedGameIndex < this._savedGames.length - 1) {
        this._selections.scrollDown();
        return;
      }
    }
    if (key == 'ArrowDown' || key == 'ArrowRight') {
      if (this._input.isFocused()) {
        if (this._input.setHighlightForwardOne()) {
          return;
        }
      }
      this._focusIndex++;
      if (this._focusIndex >= this._focusables.length) {
        // 0 is the input in this layer
        this._focusIndex = 0;
        this._input.setHighlightedIndexToBeginning();
      }
      this._drawFocus();
    }
    if (key == 'ArrowUp' || key == 'ArrowLeft') {
      if (this._input.isFocused()) {
        if (this._input.setHighlightBackwardOne()) {
          return;
        }
      }
      this._focusIndex--;
      if (this._focusIndex < 0) {
        this._focusIndex = this._focusables.length - 1;
      }
      if (this._focusIndex == 0) {
        this._input.setHighlightedIndexToEnd();
      }
      this._drawFocus();
    }
  }
}

export default SaveLayer;

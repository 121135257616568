import saveBox from './saveBox.js';
import deathBox from './deathBox.js';
import labelBox from './labelBox.js';
import settingsBox from './settingsBox.js';
import menuBox from './menuBox.js';
import inventoryBox from './inventoryBox.js';
import narrationBox from './narrationBox.js';
import faceTextBox from './faceTextBox.js';
import conversationBox from './conversationBox.js';

export {
  saveBox,
  deathBox,
  labelBox,
  settingsBox,
  menuBox,
  inventoryBox,
  narrationBox,
  faceTextBox,
  conversationBox
}

import start from './start';
import interviewTop from './interviewTop';
import aboutPaul from './aboutPaul';
import programming from './programming';

export default {
  start,
  interviewTop,
  aboutPaul,
  programming
}

export default {
  // each key of fonts object is the name of a font
  "main": {
    src: '/fonts/manjari.wfn',
    lineHeight: 14,
    offsetX: 0,
    offsetY: -2,
    type: 'wfn',
  },
  // "press_start": { // not sure if i have rights to this but looks good
  //   src: '/fonts/press_start.wfn',
  //   lineHeight: 12,
  //   offsetX: 1,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "dos": { // looks good but not sure if i have right to use
  //   src: '/fonts/dos.wfn',
  //   lineHeight: 12,
  //   offsetX: 1,
  //   offsetY: -2,
  //   type: 'wfn',
  // },
  // "monospace": { // good for main. Looks silly in settings so maybe for comedy
  //   src: '/fonts/monospace.wfn',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: -2,
  //   type: 'wfn',
  // },
  // "manjari": { // MAIN DEFINITELY
  //   src: '/fonts/manjari.wfn',
  //   lineHeight: 14,
  //   offsetX: 0,
  //   offsetY: -2,
  //   type: 'wfn',
  // },
  // "oldenglish": {
  //   src: '/fonts/oldenglish.wfn',
  //   lineHeight: 14,
  //   offsetX: 0,
  //   offsetY: -2,
  //   type: 'wfn',
  // },
  // "rhodium": {
  //   src: '/fonts/rhodium.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "yatra": {
  //   src: '/fonts/yatra.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "donegal": {
  //   src: '/fonts/donegal.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "medula": {
  //   src: '/fonts/medula.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "ruluko": {
  //   src: '/fonts/ruluko.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "prosto": { // with a little editing could be good
  //   src: '/fonts/prosto.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "headland": { // ***
  //   src: '/fonts/headland.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "rakkas": { // would be good for credits
  //   src: '/fonts/rakkas.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "geo": { // *** needs a bit of editing
  //   src: '/fonts/geo.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "salsa": { // scary font
  //   src: '/fonts/salsa.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "ntr": { // **** with a bit of cleaning would be good main font
  //   src: '/fonts/ntr.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "iceland": { // would be good sci fi font with cleanup
  //   src: '/fonts/iceland.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "krona": { // not very good
  //   src: '/fonts/krona.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "nova": { // too uneven
  //   src: '/fonts/nova.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "podkova": { // quite good serif with cleanup.
  //   src: '/fonts/podkova.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "quando": { // quite large and a bit goofy but could be useful
  //   src: '/fonts/quando.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "suez": { // has drippy look that makes it scary. Should be fixed
  //   src: '/fonts/suez.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "kelly": { // has sharp clean look
  //   src: '/fonts/kelly.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "dm_serif": { // has an army look
  //   src: '/fonts/dm_serif.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "encode_condensed": { // has long clean look. could be good for credits
  //   src: '/fonts/encode_condensed.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "berkshire": { // needs cleaning but could be good fantasy font
  //   src: '/fonts/berkshire.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "reem": { // **** very nice and clean
  //   src: '/fonts/reem.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "aclonica_thin": { // too thin
  //   src: '/fonts/aclonica_thin.wfn',
  //   lineHeight: 20,
  //   offsetX: 1,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "aclonica": { // *** really good. Possible main font
  //   src: '/fonts/aclonica.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "jura": { // large and clean. Too large for main
  //   src: '/fonts/jura.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  "sarala": { // very good and clean. Too sleek for comedy game
    src: '/fonts/sarala.wfn',
    lineHeight: 12,
    offsetX: 0,
    offsetY: -2,
    type: 'wfn',
  },
  // "squada": { // letters are a bit fat
  //   src: '/fonts/squada.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "hammersmith": { // really good for comedy game
  //   src: '/fonts/hammersmith.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "russo": { // really good already. Might be good for settings font
  //   src: '/fonts/russo.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "neuton": { // would be okay main font with some cleaning up
  //   src: '/fonts/neuton.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "orbitron": { // good sci fi font. clean
  //   src: '/fonts/orbitron.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "archivo": { // would be good credits font with a cleanup
  //   src: '/fonts/archivo.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "francois": { // good but too fat for main. maybe credits
  //   src: '/fonts/francois.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "righteous": { // too fat for main. might be good for credits in motel game
  //   src: '/fonts/righteous.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "crete": { // really messy
  //   src: '/fonts/crete.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "acmeSmall": { // almost good
  //   src: '/fonts/acmeSmall.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  // "acme": {
  //   src: '/fonts/acme.wfn',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'wfn',
  // },
  "999": {
    src: '/fonts/font999.sci',
    lineHeight: 7,
    offsetX: 0,
    offsetY: 0,
    type: 'sci',
  },
  // "603": {
  //   src: '/fonts/font603.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "601": {
  //   src: '/fonts/font601.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "600": {
  //   src: '/fonts/font600.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "311": {
  //   src: '/fonts/font311.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "310": {
  //   src: '/fonts/font310.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "303": {
  //   src: '/fonts/font303.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  "300": {
    src: '/fonts/font300.sci',
    lineHeight: 10,
    offsetX: 0,
    offsetY: 0,
    type: 'sci',
  },
  // "200": {
  //   src: '/fonts/font200.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "104": {
  //   src: '/fonts/font104.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "100": {
  //   src: '/fonts/font100.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "069": {
  //   src: '/fonts/font069.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "068": {
  //   src: '/fonts/font068.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "061": {
  //   src: '/fonts/font061.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "060": {
  //   src: '/fonts/font060.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "030": {
  //   src: '/fonts/font030.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "012": {
  //   src: '/fonts/font012.sci',
  //   lineHeight: 10,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "010": {
  //   src: '/fonts/font010.sci',
  //   lineHeight: 6,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "009": {
  //   src: '/fonts/font009.sci',
  //   lineHeight: 9,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "008": {
  //   src: '/fonts/font008.sci',
  //   lineHeight: 9,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "007": {
  //   src: '/fonts/font007.sci',
  //   lineHeight: 20,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "004": {
  //   src: '/fonts/font004.sci',
  //   lineHeight: 9,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "003": {
  //   src: '/fonts/font003.sci',
  //   lineHeight: 8,
  //   offsetX: 0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "000": {
  //   src: '/fonts/font000.sci',
  //   lineHeight: 9,
  //   offsetX: -0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "001": {
  //   src: '/fonts/font001.sci',
  //   lineHeight: 9,
  //   offsetX: -0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // "002": {
  //   src: '/fonts/font002.sci',
  //   lineHeight: 11,
  //   offsetX: -0,
  //   offsetY: 0,
  //   type: 'sci',
  // },
  // main3: {
  //   src: '/fonts/agsfnt1.wfn',
  //   lineHeight: 8,
  //   offsetX: -1,
  //   offsetY: -4,
  //   type: 'wfn',
  // }
}

export default {
  "name": "office",
  "points": {
    "insideDoor": {
      "x": 95,
      "y": 134
    },
    "outsideDoor": {
      "x": 95,
      "y": 101
    },
    "furtherOutsideDoor": {
      "x": 94,
      "y": 86
    },
    "welcomeSpot": {
      "x": 173,
      "y": 172
    },
    "paulSit": {
      "x": 165,
      "y": 130
    },
    "chair1Sit": {
      "x": 194,
      "y": 148
    }
  },
  "fixtures": {
    "office_fixture_background": {
      "aliasFor": "background",
      "alphaKey": 255,
      "width": 320,
      "height": 200,
      "name": "office_fixture_background",
      "position": {
        "x": 0,
        "y": 0
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "basePoint": 0,
      "imageName": "office",
      "subImageName": "office_fixture_background"
    },
    "office_fixture_0": {
      "aliasFor": "background",
      "basePoint": 0.9586776859504132,
      "width": 31,
      "height": 121,
      "x": 80,
      "y": 7,
      "alphaKey": 254,
      "name": "office_fixture_0",
      "position": {
        "x": 80,
        "y": 7
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "office",
      "subImageName": "office_fixture_0"
    },
    "office_fixture_1": {
      "aliasFor": "chair1",
      "basePoint": 0.96,
      "width": 8,
      "height": 25,
      "x": 151,
      "y": 104,
      "alphaKey": 253,
      "name": "office_fixture_1",
      "position": {
        "x": 151,
        "y": 104
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "office",
      "subImageName": "office_fixture_1"
    },
    "office_fixture_2": {
      "aliasFor": "desk",
      "basePoint": 0.5789473684210527,
      "width": 19,
      "height": 38,
      "x": 185,
      "y": 115,
      "alphaKey": 252,
      "name": "office_fixture_2",
      "position": {
        "x": 185,
        "y": 115
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "office",
      "subImageName": "office_fixture_2"
    },
    "office_fixture_3": {
      "aliasFor": "chair1",
      "basePoint": 0.6,
      "width": 6,
      "height": 15,
      "x": 159,
      "y": 117,
      "alphaKey": 251,
      "name": "office_fixture_3",
      "position": {
        "x": 159,
        "y": 117
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "office",
      "subImageName": "office_fixture_3"
    },
    "office_fixture_4": {
      "aliasFor": "desk",
      "basePoint": 0.8571428571428571,
      "width": 14,
      "height": 35,
      "x": 171,
      "y": 119,
      "alphaKey": 250,
      "name": "office_fixture_4",
      "position": {
        "x": 171,
        "y": 119
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "office",
      "subImageName": "office_fixture_4"
    },
    "office_fixture_5": {
      "aliasFor": "chair2",
      "basePoint": 0.875,
      "width": 14,
      "height": 24,
      "x": 195,
      "y": 132,
      "alphaKey": 249,
      "name": "office_fixture_5",
      "position": {
        "x": 195,
        "y": 132
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "office",
      "subImageName": "office_fixture_5"
    }
  },
  "zones": {
    "walkAreas": [
      {
        "name": "walk",
        "points": [
          {
            "x": 79,
            "y": 116
          },
          {
            "x": 62,
            "y": 132
          },
          {
            "x": 169,
            "y": 186
          },
          {
            "x": 200,
            "y": 159
          },
          {
            "x": 190,
            "y": 153
          },
          {
            "x": 203,
            "y": 144
          },
          {
            "x": 210,
            "y": 147
          },
          {
            "x": 242,
            "y": 114
          },
          {
            "x": 199,
            "y": 93
          },
          {
            "x": 190,
            "y": 101
          },
          {
            "x": 156,
            "y": 85
          },
          {
            "x": 112,
            "y": 131
          }
        ],
        "type": "walk",
        "extraData": ""
      },
      {
        "name": "neg1",
        "points": [
          {
            "x": 157,
            "y": 137
          },
          {
            "x": 169,
            "y": 123
          },
          {
            "x": 156,
            "y": 117
          },
          {
            "x": 146,
            "y": 126
          }
        ],
        "type": "negativeWalk",
        "extraData": ""
      },
      {
        "name": "neg2",
        "points": [
          {
            "x": 165,
            "y": 146
          },
          {
            "x": 186,
            "y": 122
          },
          {
            "x": 208,
            "y": 130
          },
          {
            "x": 205,
            "y": 138
          },
          {
            "x": 181,
            "y": 157
          }
        ],
        "type": "negativeWalk",
        "extraData": ""
      }
    ],
    "exits": {},
    "general": {},
    "depth": [
      {
        "name": ".77",
        "points": [
          {
            "x": 42,
            "y": 38
          },
          {
            "x": 281,
            "y": 41
          },
          {
            "x": 268,
            "y": 194
          },
          {
            "x": 22,
            "y": 186
          }
        ],
        "type": "depth",
        "extraData": ""
      }
    ]
  }
}
export default {
  _data: {
    steps: 10,
    // lower step means higher value
    volume: 9,
    detail: 5,
    speed: 0,
  },
  getSteps: function() {
    return this._data.steps;
  },
  getVolume: function() {
    return this._data.volume;
  },
  getDetail: function() {
    return this._data.detail;
  },
  getSpeed: function() {
    return this._data.speed;
  },
  setVolume: function(value) {
    this._data.volume = value;
  },
  setDetail: function(value) {
    this._data.detail = value;
  },
  setSpeed: function(value) {
    this._data.speed = value;
  },
}

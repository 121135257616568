import ImageManager from 'managers/ImageManager';
import GameObject from './GameObject';

class AliasedObject extends GameObject {
  constructor(parentGetter) {
    super(parentGetter, {isDrawable: false});
  }
  getRequiredImages() {
    return []; // fixture images are loaded from the screen data
  }
}

export default AliasedObject;

import ImageManager from 'managers/ImageManager';
import Layer from 'views/layers/Layer';
import TextBox from 'views/canvasBoxes/TextBox';
import Box from 'views/canvasBoxes/Box';
import PictureBox from 'views/canvasBoxes/PictureBox';
import EmptyBox from 'views/canvasBoxes/EmptyBox';
import LabelBox from 'views/compositeBoxes/LabelBox';
import Column from 'views/compositeBoxes/Column';
import Row from 'views/compositeBoxes/Row';
import Input from 'views/compositeBoxes/Input';
import Button from 'views/compositeBoxes/Button';
import SelectionList from 'views/compositeBoxes/SelectionList';
import Style from 'style/Style';
import config from 'config';
import MouseManager from 'managers/MouseManager';

class RestoreLayer extends Layer {
  constructor(savedGames, cancelHandler, restoreHandler, deleteHandler) {
    super();

    this._savedGames = savedGames;
    this._savedGameIndex = 0;

    this._cancelHandler = cancelHandler;
    this._restoreHandler = restoreHandler;
    this._deleteHandler = deleteHandler;

    let theme = Style.getTheme('saveBox');

    this._text = new TextBox('Select the game that you would like to restore.');
    this._text.draw(theme.label);

    this._selections = new SelectionList(
      this._setSelection.bind(this),
      this._savedGames,
      this._savedGameIndex,
      theme.selection.width,
      theme.selection.arrowImage,
      theme.selection.topArrow,
      theme.selection.bottomArrow,
      theme.selection.listItem.text,
      theme.selection.listItem.textBox,
      theme.selection.listItem.highlightText,
      theme.selection.listItem.highlightTextBox,
      theme.selection.listItem.height,
      theme.selection.outerColumn,
      theme.selection.outerBox,
    );

    this._buttons = this._makeButtons();

    this._buttonColumn = new Column(this._buttons, theme.buttons.column);

    this._bottomRow= new Row(
      [
        this._selections,
        this._buttonColumn
      ],
      theme.bottomRow,
    );

    this._mainColumn = new Column([this._text, this._bottomRow], theme.mainColumn);

    this._base = new Box(this._mainColumn);
    this._base.draw(theme.base);

    this._focusIndex = 0;
    this._focusables = [...this._buttons];
    this._drawFocus();

    this.addChild(this._base);
    this.centerBox(this._base);
  }
  destroy() {
    this.removeEventListener();
    this._text.destroy();
    this._buttons.forEach((button) => button.destroy());
    this._selections.destroy();
    this._buttonColumn.destroy();
    this._bottomRow.destroy();
    this._mainColumn.destroy();
    this._base.destroy();
    this._removeLayerDiv();
  }
  _makeButtons() {
    let theme = Style.getTheme('saveBox');

    this._restore = new Button(
      () => { this._restoreHandler(this._selections.getSelectionValue()) },
      'Restore',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    );
    this._delete = new Button(
      () => { this._deleteHandler(this._selections.getSelectionValue()) },
      'Delete',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )
    this._changeDirectory = new Button(
      () => { console.log('pressed') },
      'Change Directory',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )
    this._cancel = new Button(
      () => {
        this._cancelHandler();
      },
      'Cancel',
      theme.buttons.text,
      theme.buttons.box,
      theme.buttons.textHover,
      theme.buttons.boxHover,
      theme.buttons.textActive,
      theme.buttons.boxActive
    )

    return [this._restore, this._delete, this._changeDirectory, this._cancel];
  }
  _drawFocus() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex) {
        focusable.setFocused(true);
      } else {
        focusable.setFocused(false);
      }
    });
  }
  _clickFocusedButton() {
    this._focusables.forEach((focusable, index) => {
      if (index == this._focusIndex && focusable instanceof Button) {
        focusable.click();
      }
    });
  }
  _setSelection(index) {
    this._savedGameIndex = index;
    this._selections.setSelectedIndex(this._savedGameIndex);
    this._drawFocus();
  }
  keyPressed(key) {
    if (key == 'Enter') {
      this._clickFocusedButton();
    }
    if (key == 'ArrowUp') {
      if (this._savedGameIndex > 0) {
        this._selections.scrollUp();
        return;
      }
    }
    if (key == 'ArrowDown') {
      if (this._savedGameIndex < this._savedGames.length - 1) {
        this._selections.scrollDown();
        return;
      }
    }
    if (key == 'ArrowDown' || key == 'ArrowRight') {
      this._focusIndex++;
      if (this._focusIndex >= this._focusables.length) {
        // 0 is the input in this layer
        this._focusIndex = 0;
      }
      this._drawFocus();
    }
    if (key == 'ArrowUp' || key == 'ArrowLeft') {
      this._focusIndex--;
      if (this._focusIndex < 0) {
        this._focusIndex = this._focusables.length - 1;
      }
      this._drawFocus();
    }
  }
  becameTopLayer() {
    MouseManager.setCursor('pointer');
  }
}

export default RestoreLayer;

import misc from './misc';
import inventory from './inventory';
import menuButtons from './menuButtons';
import cursors from './cursors';

export default {
  misc,
  inventory,
  menuButtons,
  cursors
}

import config from 'config';
import eventMixin from 'mixins/eventMixin';
import state from 'state';

let StateManager = {
  STATE_CHANGED: 'state_changed',
  SETTINGS: 'state_settings',
  MENU: 'state_menu',

  _defaultState: JSON.parse(JSON.stringify(state)),
  init: function() {
    this._state = state;
    // we add this so the state modules can access each other
    for (let module in this._state) {
      this._state[module].getState = (name) => this._state[name];
    }
  },
  getState: function(name) {
    return this._state[name];
  },
  getWholeState: function() {
    return this._state;
  },
  restoreState: function(storedState) {
    for (let module in storedState) {
      this._state[module]._data = storedState[module]._data;
    }
  },
  restoreToDefaultState: function() {
    for (let module in this._defaultState) {
      this._state[module]._data = this._defaultState[module]._data;
    }
  }
}

export default StateManager;

import config from 'config';
import Canvas from 'views/Canvas';

let FetchManager = {
  postReq: function(path, data) {
    return fetch(path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }).
      then((results) => {
        return results.json()
          .then((json) => {
            if (results.ok) {
              return json;
            } else {
              return Promise.reject(json);
            }
          });
      });
  },
  getReq: function(path, params) {
    let url = path + '?' + new URLSearchParams(params).toString();
    return fetch(url)
           .then((results) => {
             return results.json()
               .then((json) => {
                 if (results.ok) {
                   return json;
                 } else {
                   return Promise.reject(json);
                 }
               });
           });
  },
  fetchJSON: function(pathToJSON) {
    return fetch(pathToJSON)
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP error " + response.status); // Rejects the promise
      } else {
        return response.json();
      }
    });
  },
  sendRoomJSON: function(screenName, jsonString, imageURL) {
    let path = `/screenData/save/${screenName}`;
    return this.postReq(path, {json: jsonString, imageURL})
  },
  sendWorkingRoomJSON: function(screenName, jsonString) {
    let path = `/screenData/saveWorkingData/${screenName}`;
    return this.postReq(path, {json: jsonString })
  },
  getWorkingRoomJSON: function(screenName) {
    let path = `/screenData/getWorkingData/${screenName}`;
    return this.getReq(path, {})
  }
};

export default FetchManager;

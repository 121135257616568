import Actions from 'classes/Actions';
import Speech from 'classes/Speech';
import StateManager from 'managers/StateManager';
import ConversationLine from 'classes/ConversationLine';

export default {
  getFace: function() {
    return ['Guard', 'guardFace'];
  },
  getEmotion: function() {
    return '';
  },
  questions: {
    "Hello. I would like to see the rocks please.": new ConversationLine(
               // getActions function
               () => {
                 return [
                   Actions.speak('Guard', 'guardFace', [new Speech(`Sorry, I just can't.`), new Speech(`Actually okay you can go.`)]),
                 ];
               },
               // shouldShow function
               () => {
                 console.log('isAllowedInRocks', StateManager.getState('rockroom').isAllowedInRocks())
                 return !StateManager.getState('rockroom').isAllowedInRocks();
               },
               // changeState function
               () => {
                 StateManager.getState('rockroom').setAllowedInRocks(true);
               },
             ),
    "Can I ask about the rocks.": new ConversationLine(
              () => {
                return [
                  Actions.speak('Guard', 'guardFace', [new Speech("Sure.")]),
                  Actions.pushConversation('test.rocks')
                ]
              },
              () => true,
              () => {},
            ),
    "Thanks.": new ConversationLine(
              () => {
                return [
                  Actions.speak('Guard', 'guardFace', [new Speech("No problem.")]),
                  Actions.popConversation(),
                ]
              },
              () => true,
              () => {},
            ),
  }
}

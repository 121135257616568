export default{
  "src": "/images/faces/guardFace.png",
  "spriteInfo": {
    "bg": {
      "width": 96,
      "height": 96,
      "x": 0,
      "y": 0,
      "animation": false,
      "repeat": false,
      "frames": 1
    },
    "bg_naked": {
      "width": 96,
      "height": 96,
      "x": 96,
      "y": 0,
      "animation": false,
      "repeat": false,
      "frames": 1
    },
    "mouth": {
      "width": 47,
      "height": 33,
      "x": 192,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 10
    },
    "eyes": {
      "width": 38,
      "height": 26,
      "x": 662,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 2
    },
    "eyes_angry": {
      "width": 38,
      "height": 26,
      "x": 738,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 2
    },
    "eyes_scared": {
      "width": 38,
      "height": 26,
      "x": 814,
      "y": 0,
      "animation": true,
      "repeat": false,
      "frames": 2
    }
  }
}
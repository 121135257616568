import Actions from 'classes/Actions';
import Speech from 'classes/Speech';
import State from 'state';
import ConversationLine from 'classes/ConversationLine';

export default {
  getFace: function() {
    return ['Paul', 'paulFace'];
  },
  getEmotion: function() {
    return '';
  },
  questions: {
    "Do you have any other projects?": new ConversationLine(
               // getActions function
               () => {
                 return [
                   Actions.speak(
                     'Paul', 'paulFace',
                     [
                       new Speech('Yes. I am making a JavaScript framework for making online point and click adventure games.'),
                       new Speech('I am also developing a tool to help students learn better.'),
                       new Speech('I develop Chrome Extensions sometimes, but only for personal use.'),
                       new Speech('I develop paint programs and am currently working on another one using Electron.'),
                     ]
                   ),
                 ];
               },
               // shouldShow function
               () => true,
               // changeState function
               () => {},
             ),
    "What are your salary expectations??": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("I would like a nice salary, whatever that may be."),
                      new Speech("If remote work is offered I accept a lower salary."),
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
    "Can you give an example of a time a time when you had to deal with a conflict at work?": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("Yes, I used to work at a gas station where customers would occasionally get irate and sometimes threaten violence."),
                      new Speech("I would deal with it by being patient and taking the abuse."),
                    ]
                  )                ]
              },
              () => true,
              () => {},
            ),
    "What is your greatest weakness?": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("I am too hard on myself about doing a good job."),
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
    "Do you look in real life the way you do in this interview simulator?": new ConversationLine(
              () => {
                return [
                  Actions.speak(
                    'Paul', 'paulFace',
                    [
                      new Speech("Not really. The drawing came out wrong. I have similar hair color though."),
                    ]
                  )
                ]
              },
              () => true,
              () => {},
            ),
    "Ask something else": new ConversationLine(
              () => {
                return [
                  Actions.popConversation()
                ]
              },
              () => true,
              () => {},
            ),
  }
}

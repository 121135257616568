export default {
  "name": "lobby",
  "points": {
    "insideFront": {
      "x": 65,
      "y": 169
    },
    "outsideOffice": {
      "x": 195,
      "y": 101
    },
    "convoPoint": {
      "x": 200,
      "y": 126
    }
  },
  "fixtures": {
    "lobby_fixture_background": {
      "aliasFor": "background",
      "alphaKey": 255,
      "width": 320,
      "height": 200,
      "name": "lobby_fixture_background",
      "position": {
        "x": 0,
        "y": 0
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "basePoint": 0,
      "imageName": "lobby",
      "subImageName": "lobby_fixture_background"
    },
    "lobby_fixture_0": {
      "aliasFor": "officeDoor",
      "basePoint": 0.7924528301886793,
      "width": 25,
      "height": 53,
      "x": 195,
      "y": 51,
      "alphaKey": 254,
      "name": "lobby_fixture_0",
      "position": {
        "x": 195,
        "y": 51
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "lobby",
      "subImageName": "lobby_fixture_0"
    },
    "lobby_fixture_1": {
      "aliasFor": "staffroomDoor",
      "basePoint": 0.625,
      "width": 25,
      "height": 56,
      "x": 57,
      "y": 104,
      "alphaKey": 253,
      "name": "lobby_fixture_1",
      "position": {
        "x": 57,
        "y": 104
      },
      "imageOffset": {
        "x": 0,
        "y": 0
      },
      "imageName": "lobby",
      "subImageName": "lobby_fixture_1"
    }
  },
  "zones": {
    "walkAreas": [
      {
        "name": "walk",
        "points": [
          {
            "x": 46,
            "y": 168
          },
          {
            "x": 193,
            "y": 95
          },
          {
            "x": 213,
            "y": 105
          },
          {
            "x": 210,
            "y": 114
          },
          {
            "x": 249,
            "y": 132
          },
          {
            "x": 257,
            "y": 128
          },
          {
            "x": 263,
            "y": 133
          },
          {
            "x": 178,
            "y": 174
          },
          {
            "x": 127,
            "y": 150
          },
          {
            "x": 69,
            "y": 179
          }
        ],
        "type": "walk",
        "extraData": ""
      }
    ],
    "exits": {},
    "general": {},
    "depth": [
      {
        "name": ".77",
        "points": [
          {
            "x": 20,
            "y": 14
          },
          {
            "x": 280,
            "y": 19
          },
          {
            "x": 286,
            "y": 181
          },
          {
            "x": 27,
            "y": 193
          }
        ],
        "type": "depth",
        "extraData": ""
      }
    ]
  }
}
import config from 'config';
import Canvas from 'views/Canvas';
import sprites from 'sprites'
import ImageStore from 'classes/ImageStore';

let ImageManager = {
  _screenImages: {},
  _images: {},
  _loadedSources: {},
  _startUpImages: [
    'menuButtons',
    'cursors',
    'misc',
    'inventory',
  ],
  loadStartupImages: function() {
    return this.loadImages(this._startUpImages);
  },
  loadImages: function(imageNames) {
    let promises = [];
    imageNames.forEach((imageName) => {
      promises.push(this.loadImage(sprites[imageName].src)
                        .then((canvas) => {
                          canvas.removeEventListener();
                          this._images[imageName] = {
                            canvas: canvas,
                            spriteInfo: sprites[imageName].spriteInfo
                          };
                          return Promise.resolve();
                        }));
    });
    return Promise.all(promises);
  },
  // returns Canvas object (not the html canvas)
  loadImage: function(path) {
    console.log(path);
    return new Promise((resolve, reject) => {
      // check if this image has already been loaded and processed as canvas
      if (this._loadedSources[path]) {
        resolve(this._loadedSources[path]);
        return;
      }
      let img = new Image();
      img.src = path
      img.onload = () => {
        let can = new Canvas(img.width, img.height, img);
        this._loadedSources[path] = can;
        resolve(can);
      }
      img.onerror = (e) => {
        reject(e);
      }
    })
  },
  getImage: function(imageName, spriteName) {
    let image = this._images[imageName];
    if (!image.spriteInfo[spriteName]) {
      throw Error(`${imageName}, ${spriteName}' did not return an image.`);
    }
    return new ImageStore(image.canvas, image.spriteInfo[spriteName]);
  },
  getImageSet: function(imageName) {
    let imageSet = {};
    let image = this._images[imageName];
    for (let spriteName in image.spriteInfo) {
      imageSet[spriteName] = new ImageStore(image.canvas, image.spriteInfo[spriteName]);
    }
    return imageSet;
  },
  // both loads the screen image and extracts the game objects from the image
  loadScreen: function(screenName, fixturesInfo) {
    let path = `${config.paths.screenImagesPath}${screenName}/${screenName}.png`;
    if (this._screenImages[screenName]) {
      return Promise.resolve();
    }
    return this.loadImage(path)
           .then((backgroundCanvas) => {
             let screenImages = {};
             for (let fixtureName in fixturesInfo) {
               let fixInfo = fixturesInfo[fixtureName];
               let canvas = new Canvas(fixInfo.width, fixInfo.height);
               for (let i = 0; i < fixInfo.width; i++) {
                 for (let j = 0; j < fixInfo.height; j++) {
                   let color = backgroundCanvas.getCommittedColorAtPoint(i, j, fixInfo.position.x, fixInfo.position.y);
                   if (color[3] == fixInfo.alphaKey) {
                     canvas.drawPoint(i, j, [color[0], color[1], color[2], 255]);
                   }
                 }
               }
               canvas.commitData();
               screenImages[fixInfo.name] = new ImageStore(canvas);
             }
             this._screenImages[screenName] = screenImages;
           });
  },
  getScreenImage: function(screenName, screenImage) {
    return this._screenImages[screenName][screenImage];
  },
  getScreenImageSet: function(screenName) {
    return this._screenImages[screenName];
  }
}

export default ImageManager;
